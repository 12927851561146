import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';
import { DateTime } from './customTypes/dateTime';

// should match TutorialAttendanceCaseDto.cs
export enum TutorialAttendanceCaseType {
  Unknown = 'Unknown',
  BookedNotAttended = 'BookedNotAttended',
  NotBooked = 'NotBooked',
  AttendedNotYetPass = 'AttendedNotYetPass',
  BookedThenCancelled = 'BookedThenCancelled',
  AttendanceNotMarkedYet = 'AttendanceNotMarkedYet',
}

// should match TutorialAttendanceCaseDto.cs
export enum TutorialAttendanceCaseStatusType {
  Unknown = 'Unknown',
  New = 'New',
  ReasonSetByStaff = 'ReasonSetByStaff',
  ReasonEmailSent = 'ReasonEmailSent',
  ReasonSetByStudent = 'ReasonSetByStudent',
  ReasonEmailLapsedRequiresFollowUp = 'ReasonEmailLapsedRequiresFollowUp',
  FeeRequiredDecisionMade = 'FeeRequiredDecisionMade',
  FeeNotRequiredDecisionMade = 'FeeNotRequiredDecisionMade',
  FeeManuallyProcessed = 'FeeManuallyProcessed',
  FeeAutoPaymentFailed = 'FeeAutoPaymentFailed',
  FeeAutoPaymentTaken = 'FeeAutoPaymentTaken',
  RebookingLinkSent = 'RebookingLinkSent',
  RebookingLinkLapsedAndResent = 'RebookingLinkLapsedAndResent',
  RebookingMade = 'RebookingMade',
  RebookingMissedByStudent = 'RebookingMissedByStudent',
  RebookingAttendedButDidNotComplete = 'RebookingAttendedButDidNotComplete',
  TutorialMakeUpComplete = 'TutorialMakeUpComplete',
  ManuallyClosed = 'ManuallyClosed',
  NewStudentNotFoundInTutorialElearningAudience = 'NewStudentNotFoundInTutorialElearningAudience',
}

export const TutorialAttendanceCaseStatusModel = types.model('TutorialAttendanceCaseStatusModel', {
  id: types.number,
  tutorialAttendanceCaseId: types.number,
  createdDate: DateTime,
  createdBy: types.string,
  comments: types.maybeNull(types.string),
  status: types.enumeration<TutorialAttendanceCaseStatusType>(
    Object.values(TutorialAttendanceCaseStatusType)
  ),
});

export const TutorialAttendanceCaseModel = types.model('TutorialAttendanceCaseModel', {
  id: types.number,
  publicId: types.string,
  firstName: types.string,
  lastName: types.string,
  email: types.string,
  intakeId: types.number,
  intakeTitle: types.string,
  moduleId: types.maybeNull(types.number),
  moduleName: types.maybeNull(types.string),
  tutorialWeek: types.number,
  trainer: types.maybeNull(types.string),
  tutorialAttendanceCaseType: types.enumeration<TutorialAttendanceCaseType>(
    Object.values(TutorialAttendanceCaseType)
  ),
  nonAttendanceReason: types.maybeNull(types.string),
  lastModifiedDate: DateTime,
  lastModifiedBy: types.string,
  currentStatus: TutorialAttendanceCaseStatusModel,
});

export const StudentNotBookedForTutorialWeek = types.model('StudentNotBookedForTutorialWeekModel', {
  email: types.string,
  intake: types.string,
  week: types.string,
  firstName: types.string,
  lastName: types.string,
});

export interface IStudentNotBookedForTutorialWeek
  extends Instance<typeof StudentNotBookedForTutorialWeek> {}

export interface ITutorialAttendanceCaseModel
  extends Instance<typeof TutorialAttendanceCaseModel> {}

export interface ITutorialAttendanceCaseStatusModel
  extends Instance<typeof TutorialAttendanceCaseStatusModel> {}

export interface IPagedTutorialAttendanceCasesModel {
  tutorialAttendanceCases: Array<ITutorialAttendanceCaseModel>;
  totalCount: number;
}

export const TutorialAttendanceModel = types
  .model('TutorialAttendanceModel', {
    cases: types.array(TutorialAttendanceCaseModel),
    casesTotalCount: types.optional(types.number, 0),
    studentsNotBookedForCurrentTutorialWeek: types.array(StudentNotBookedForTutorialWeek),
  })
  .actions((self) => {
    const getAttendanceCasesList = flow(function* (
      returnClosedCasesOnly: boolean,
      pageSize: number,
      pageNumber: number,
      searchTerms?: string
    ) {
      const { ajax } = getEnv(self);

      try {
        const result = yield ajax
          .get(
            `ProtectedTutorialAttendance?returnclosedcasesonly=${returnClosedCasesOnly}&pagesize=${pageSize}&pagenumber=${pageNumber}&searchTerms=${
              searchTerms ?? ''
            }`
          )
          .json<IPagedTutorialAttendanceCasesModel>()
          .then((value) => value);

        self.cases.clear();
        result.tutorialAttendanceCases.forEach((i: ITutorialAttendanceCaseModel) =>
          self.cases.push(TutorialAttendanceCaseModel.create(i))
        );
        self.casesTotalCount = result.totalCount;
      } catch (error) {
        console.log(
          `error GET /ProtectedTutorialAttendance?returnclosedcasesonly=${returnClosedCasesOnly}&pagesize=${pageSize}&pagenumber=${pageNumber}&searchTerms=${searchTerms}: ` +
            error
        );
      }
    });

    const closeCases = flow(function* (casesIds: Array<number>, comments: string) {
      const { ajax } = getEnv(self);

      try {
        yield ajax.put('ProtectedTutorialAttendance/close', {
          json: { ids: casesIds, comments: comments },
        });
      } catch (error) {
        console.log('error PUT /ProtectedTutorialAttendance/close: ' + error);
      }
    });

    const setNonAttendanceReasonAndFeePayment = flow(function* (
      id: number,
      reason: string,
      isFeePaymentRequired: boolean
    ) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .put('ProtectedTutorialAttendance/set-nonattendance-reason-fee-payment', {
            json: { id: id, reason: reason, isFeePaymentRequired: isFeePaymentRequired },
          })
          .json<ITutorialAttendanceCaseModel>()
          .then((value) => TutorialAttendanceCaseModel.create(value));
      } catch (error) {
        console.log(
          'error PUT /ProtectedTutorialAttendance/set-nonattendance-reason-fee-payment: ' + error
        );
      }
    });

    const setStatusType = flow(function* (id: number, status: TutorialAttendanceCaseStatusType) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .put('ProtectedTutorialAttendance/set-status-type', {
            json: { id, status },
          })
          .json<ITutorialAttendanceCaseModel>()
          .then((value) => TutorialAttendanceCaseModel.create(value));
      } catch (error) {
        console.log('error PUT /ProtectedTutorialAttendance/set-status-type: ' + error);
      }
    });

    const getAttendanceCase = flow(function* (id: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`ProtectedTutorialAttendance/case/${id}`)
          .json<ITutorialAttendanceCaseModel>()
          .then((value) => TutorialAttendanceCaseModel.create(value));
      } catch (error) {
        console.log(`error GET ProtectedTutorialAttendance/case/${id}: ` + error);
      }
    });

    const getAttendanceCaseHistory = flow(function* (id: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`ProtectedTutorialAttendance/case/${id}/history`)
          .json<ITutorialAttendanceCaseStatusModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error GET ProtectedTutorialAttendance/case/${id}/history: ` + error);
      }
    });

    const sendTutorialMakeUpBookingEmail = flow(function* (attendanceCaseId: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`ProtectedTutorialAttendance/send-booking-email/${attendanceCaseId}`)
          .then((value) => value);
      } catch (error) {
        console.log(
          `error ProtectedTutorialAttendance/send-booking-email/${attendanceCaseId} error: ${error}`
        );
        throw error;
      }
    });

    const getStudentsNotBookedForTutorialWeek = flow(function* () {
      const { ajax } = getEnv(self);

      try {
        var result = yield ajax
          .get(`ProtectedTutorialAttendance/students-not-booked-for-current-tutorial-week`, {
            timeout: 60000,
          })
          .json<IStudentNotBookedForTutorialWeek[]>()
          .then((value) => value);
        self.studentsNotBookedForCurrentTutorialWeek.clear();
        result.forEach((i: IStudentNotBookedForTutorialWeek) =>
          self.studentsNotBookedForCurrentTutorialWeek.push(i)
        );
      } catch (error) {
        console.log(
          `error GET ProtectedTutorialAttendance/students-not-booked-for-current-tutorial-week: ` +
            error
        );
      }
    });

    return {
      getAttendanceCasesList: getAttendanceCasesList,
      closeCases: closeCases,
      setNonAttendanceReasonAndFeePayment: setNonAttendanceReasonAndFeePayment,
      getAttendanceCase: getAttendanceCase,
      setStatusType: setStatusType,
      sendTutorialMakeUpBookingEmail: sendTutorialMakeUpBookingEmail,
      getAttendanceCaseHistory: getAttendanceCaseHistory,
      getStudentsNotBookedForTutorialWeek: getStudentsNotBookedForTutorialWeek,
    };
  });
