import React, { useEffect, useState } from 'react';
import style from './StoryTimeUpload.module.scss';
// @ts-ignore
import VideoRecorder from 'react-video-recorder';
import { Buffer } from 'buffer';
import { Button, ButtonGroup, CircularProgress, Grid, Paper } from '@material-ui/core';
import { useStore } from '../../hooks';
import { observer } from 'mobx-react-lite';
import { CustomVideoRecorderActions } from './CustomVideoRecorderActions';
import { Alert } from '@material-ui/lab';
import qs from 'qs';
import { useParams } from 'react-router-dom';
import LogService from '../../../services/LogService';

// @ts-ignore
window.Buffer = Buffer; //https://github.com/legokichi/ts-ebml/issues/25 issue with dependency of ts-ebml which the next version might remove.

export const StoryTimeUpload = observer(() => {
  const videoRecorderRef = React.createRef();
  const [videoBlob, setVideoBlob] = useState<Blob | undefined>(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [manualMode, setManualMode] = useState<boolean>(false);
  const [uploadLimitExceeded, setUploadLimitExceeded] = useState<boolean>(false);
  const { storyTimeModel } = useStore();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [moduleId, setModuleId] = useState<number | undefined>(undefined);
  const [weekNumber, setWeekNumber] = useState<number | undefined>(undefined);
  const [hasAlreadySubmitted, setHasAlreadySubmitted] = useState<boolean | undefined>(undefined);
  const [isAlreadyReviewed, setIsAlreadyReviewed] = useState<boolean | undefined>(undefined);
  const { moduleParam, weekParam } = useParams<{ moduleParam: string; weekParam: string }>();
  const UploadLimitInMegabytes = 1024;
  const uploadLimitInBytes = UploadLimitInMegabytes * 1024 * 1024;
  const [uploadError, setUploadError] = useState<string | undefined>(undefined);
  const [uploadConfirmed, setUploadConfirmed] = useState<boolean>(false);

  useEffect(() => {
    const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    LogService.info('StoryTimeUpload - Component Loaded - email: ' + qsParams.email);
    setEmail(qsParams.email);
    const moduleId = moduleParam === 'cert' ? -1 : parseInt(moduleParam);
    setModuleId(moduleId);
    const week = parseInt(weekParam);
    setWeekNumber(week);
    storyTimeModel.checkAlreadySubmitted(qsParams.email, moduleId, week).then(
      (exists: { isAlreadySubmitted: boolean; isAlreadyReviewed: boolean }) => {
        setHasAlreadySubmitted(exists.isAlreadySubmitted);
        setIsAlreadyReviewed(exists.isAlreadyReviewed);
      },
      (error) => {
        LogService.info('StoryTimeUpload - checkAlreadySubmitted error - ' + error);
      }
    );
  }, [moduleParam, weekParam, storyTimeModel]);

  useEffect(() => {
    if (!videoBlob) {
      setUploadLimitExceeded(false);
    } else {
      setUploadLimitExceeded(videoBlob.size > uploadLimitInBytes);
    }
  }, [setUploadLimitExceeded, videoBlob, uploadLimitInBytes]);

  const handleRecordingComplete = (videoBlob: Blob) => {
    LogService.info('StoryTimeUpload - handleRecordingComplete - size: ' + videoBlob?.size);
    setVideoBlob(videoBlob);
  };

  const uploadFile = (fileBlob: Blob) => {
    LogService.info('StoryTimeUpload - uploadFile - Upload Started');

    setVideoBlob(fileBlob);
    setIsUploading(true);
    setUploadError(undefined);

    if (fileBlob.size > uploadLimitInBytes) {
      return;
    }

    storyTimeModel.initiliaseUpload(fileBlob.size, email!, moduleId!, weekNumber!).then(
      (initialiseUploadResponse) => {
        LogService.info('StoryTimeUpload - uploadFile - initialise upload completed');
        return storyTimeModel.uploadVideo(fileBlob, initialiseUploadResponse.uploadLink!).then(
          (uploadResponse) => {
            LogService.info(
              'StoryTimeUpload - uploadFile - uploadVideo complete - response: ' +
                JSON.stringify(uploadResponse)
            );
            return storyTimeModel
              .reportUploadCompleted(initialiseUploadResponse.breatheVideoID)
              .then(
                (reportUploadCompletedResponse) => {
                  setUploadConfirmed(true);
                  LogService.info(
                    'StoryTimeUpload - uploadFile - reportUploadCompleted complete - response: ' +
                      reportUploadCompletedResponse
                  );
                },
                (reportCompletedError) => {
                  setUploadError(`Failed to finalize upload, please try again.`);
                  LogService.error(
                    'StoryTimeUpload - uploadFile - reportUploadCompleted error - ' +
                      JSON.stringify(reportCompletedError)
                  );
                }
              );
          },
          (uploadError) => {
            setUploadError(`Upload failed at ${storyTimeModel.uploadProgress}, please try again.`);
            LogService.error(
              'StoryTimeUpload - uploadFile - uploadVideo error - ' + JSON.stringify(uploadError)
            );
          }
        );
      },
      (initialiseUploadError) => {
        LogService.error(
          'StoryTimeUpload - uploadFile - InitialiseUpload error - ' +
            JSON.stringify(initialiseUploadError)
        );
        setUploadError('Upload failed to initialize, please try again later.');
      }
    );
  };

  const handleError = (err: any) => {
    LogService.info('Video Upload Component OnError triggered. Error: ' + JSON.stringify(err));
  };

  const renderErrorView = () => {
    LogService.info('StoryTimeUpload - renderErrorView');
    setManualMode(true);
    return <></>;
  };

  const renderLoadingView = () => {
    return (
      <>
        <p>
          Loading... <br />
          To record and upload your{' '}
          <span className={style.highlight}>
            <u>Story Time</u>
          </span>
          , please <span className={style.highlight}>allow</span> microphone and camera access to
          eLearning, if prompted.
        </p>
        <StoryTimeAlternativeUpload onFileSelect={uploadFile} />
      </>
    );
  };

  const renderUnsupportedView = () => {
    LogService.info('StoryTimeUpload - renderUnsupportedView');
    return (
      <>
        <h2>
          Your browser does not support video capabilities. Please upload a pre-recorded file.
        </h2>
        <StoryTimeAlternativeUpload onFileSelect={uploadFile} />
      </>
    );
  };

  if (!email) {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify={'center'}
          style={{ minHeight: '100vh' }}>
          <Grid item xs={12}>
            <Alert severity="error">Configuration incomplete. Please contact support.</Alert>
            <br />
            <Button
              variant="contained"
              onClick={() => {
                window.history.back();
                window.close();
              }}>
              Back
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  if (isAlreadyReviewed) {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify={'center'}
          style={{ minHeight: '100vh' }}>
          <Grid item xs={12}>
            <Alert severity="info">
              Your submission has already been reviewed. Comments by the reviewer were sent to your
              email.
            </Alert>
            <br />
            <Button
              variant="contained"
              onClick={() => {
                window.history.back();
                window.close();
              }}>
              Back
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <div className={style.container}>
      {hasAlreadySubmitted && (
        <Alert severity="warning">
          You have have already submitted your story time for this week. By continuing you will be
          replacing that submission.
        </Alert>
      )}

      {uploadLimitExceeded && (
        <Alert severity="error">
          The video is too large to upload. Please use a video under {UploadLimitInMegabytes}MB.
        </Alert>
      )}

      {uploadError && (
        <Alert
          severity="error"
          action={
            videoBlob && (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  LogService.info('StoryTimeUpload - Upload Retry Started.');
                  uploadFile(videoBlob);
                }}>
                RETRY
              </Button>
            )
          }>
          {uploadError}
        </Alert>
      )}

      <Grid container className={style.fullheight}>
        <Grid className={style.fullheight} item xs={12}>
          {isUploading && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify={'center'}
              style={{ minHeight: '100vh' }}>
              <Grid item xs={12}>
                <>
                  {(storyTimeModel.uploadProgress! < 100 || !uploadConfirmed) &&
                    !uploadLimitExceeded && (
                      <h1>
                        Please wait, uploading...
                        {(storyTimeModel.uploadProgress || 0) > 0 &&
                          ' - ' + storyTimeModel.uploadProgress + '%'}
                        <br />
                        (please stay on this page)
                      </h1>
                    )}
                  {storyTimeModel.uploadProgress === 100 && uploadConfirmed && !uploadError && (
                    <>
                      <h1>Upload Complete</h1>
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        onClick={() => {
                          LogService.info(
                            'StoryTimeUpload - Upload Complete - Back Button Clicked'
                          );
                          window.history.back();
                          window.close();
                        }}>
                        Back
                      </Button>
                    </>
                  )}
                  {uploadLimitExceeded === true && (
                    <StoryTimeAlternativeUpload onFileSelect={uploadFile} />
                  )}
                </>
              </Grid>
              <Grid item xs={12}>
                <>
                  {storyTimeModel.uploadProgress! < 100 && (
                    <CircularProgress
                      size={150}
                      variant="determinate"
                      value={storyTimeModel.uploadProgress || 0}
                    />
                  )}
                </>
              </Grid>
            </Grid>
          )}

          {!isUploading && manualMode && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify={'center'}
              style={{ minHeight: '100vh' }}>
              <Grid item xs={12}>
                <StoryTimeAlternativeUpload
                  onFileSelect={(blob) => {
                    LogService.info('StoryTimeUpload - Manual Mode - File Selected');
                    uploadFile(blob);
                  }}
                  clearSelectedFile={() => setVideoBlob(undefined)}
                />
              </Grid>
            </Grid>
          )}

          {!isUploading && !manualMode && (
            <VideoRecorder
              ref={videoRecorderRef}
              // chunkSize={250}
              constraints={{
                audio: true,
                video: true,
              }}
              countdownTime={3000}
              // dataAvailableTimeout={500}
              // isFlipped={false}
              isOnInitially
              showReplayControls
              replayVideoAutoplayAndLoopOff
              // isReplayVideoInitiallyMuted={false}
              timeLimit={1000 * 60 * 2}
              onError={handleError}
              // onOpenVideoInput={function noRefCheck(){}}
              onRecordingComplete={handleRecordingComplete}
              // onStartRecording={function noRefCheck(){}}
              // onStopRecording={function noRefCheck(){}}
              // onStopReplaying={function noRefCheck(){}}
              // onTurnOffCamera={function noRefCheck(){}}
              onTurnOnCamera={() => setVideoBlob(undefined)}
              renderActions={(props: any) => (
                <CustomVideoRecorderActions
                  {...props}
                  uploadVideo={() => {
                    LogService.info(
                      'StoryTimeUpload - CustomVideoRecorderActions - Upload Button Clicked'
                    );
                    uploadFile(videoBlob!);
                  }}
                  switchToUploadFile={() => {
                    LogService.info(
                      'StoryTimeUpload - CustomVideoRecorderActions - Switch To Upload File Button Clicked'
                    );
                    setManualMode(true);
                  }}
                />
              )}
              // renderDisconnectedView={function noRefCheck(){}}
              renderErrorView={renderErrorView}
              renderLoadingView={renderLoadingView}
              renderUnsupportedView={renderUnsupportedView}
              // renderVideoInputView={function noRefCheck(){}}
              // t={function noRefCheck(){}}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
});

interface StoryTimeAlternativeUploadProps {
  onFileSelect: (blob: Blob) => void;
  clearSelectedFile?: () => void;
}

export const StoryTimeAlternativeUpload = ({
  onFileSelect,
  clearSelectedFile,
}: StoryTimeAlternativeUploadProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  return (
    <div className={style.manualUploadContainer}>
      Select a file from your device to upload. This is may be the best approach for older devices
      and browsers.
      <br />
      <br />
      {!selectedFile && (
        <label className={style.uploadButton} htmlFor="contained-button-file">
          <input
            accept="video/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(e) => setSelectedFile(e.target.files![0] as File)}
          />
          <Button variant="contained" component="span">
            Select File
          </Button>
        </label>
      )}
      {selectedFile && (
        <div>
          <Paper elevation={3}>
            <span>File: {selectedFile.name}</span>
            <br />
            <ButtonGroup variant="contained">
              <Button onClick={() => onFileSelect(selectedFile)}>Upload</Button>
              <Button
                onClick={() => {
                  setSelectedFile(null);
                  if (clearSelectedFile) clearSelectedFile();
                }}>
                Use different file
              </Button>
            </ButtonGroup>
          </Paper>
        </div>
      )}
      <Button
        variant="contained"
        onClick={() => {
          LogService.info('StoryTimeUpload - Alternative Upload - Back Button Clicked');
          window.history.back();
          window.close();
        }}>
        Back
      </Button>
    </div>
  );
};
