import { types, applySnapshot } from 'mobx-state-tree';
import { flow, getEnv } from '../../store/util';

const CategoryModel = types.model('CategoryModel', {
  id: types.identifier,
  name: types.string,
});

export const CategoriesRepo = types
  .model('CategoriesRepo', {
    categories: types.array(CategoryModel),
  })
  .actions((self) => {
    const { ajax } = getEnv(self);

    const load = flow(function* () {
      const content = yield ajax.get('categories').json<any>();
      applySnapshot(self, {
        categories: content.categories,
      });
    });

    return { load };
  })
  .actions((self) => ({
    afterAttach: flow(function* (): Generator<Promise<void>, void, void> {
      yield self.load();
    }),
  }));
