import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../images/breathe-education-logo-red-retina.png';
import styles from './AuthenticatedRoutes.module.scss';
import { AppBar, Button } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { breatheTheme } from '../themes/theme';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import IntakesIcon from '@material-ui/icons/School';
import DipModuleIcon from '@material-ui/icons/MenuBook';
import LinkIcon from '@material-ui/icons/Link';
import LearningScheduleIcon from '@material-ui/icons/Today';
import TutorialAttendanceIcon from '@material-ui/icons/PlaylistAddCheck';
import AudienceListIcon from '@material-ui/icons/Group';
import ReportListIcon from '@material-ui/icons/Assessment';
import StoryTimeIcons from '@material-ui/icons/VoiceChat';
import SettingsIcons from '@material-ui/icons/Settings';
import ChecklistIcon from '@material-ui/icons/AssignmentTurnedIn';

import List from '@material-ui/core/List';
import clsx from 'clsx';
import { useStore } from '../hooks';
import {
  STUDENT_LIST,
  DIPLOMA_MODULES_LIST,
  LEARNINGSCHEDULE,
  TOTARALINKS_LIST,
  REPORTS_LIST,
  STORY_TIME,
  TUTORIALATTENDANCE_LIST,
  SETTINGS,
  COMPETENCIES,
} from '../routes/Routes';
import { ConfirmProvider } from 'material-ui-confirm';

export const AuthenticatedShell: React.FC = function ({ children }) {
  const drawerWidth = 240;

  const [open, setOpen] = React.useState(true);
  const store = useStore();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    store.security.signOut();
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      background: 'white',
      color: 'black',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
      color: 'black',
    },
    menuButtonHidden: {
      display: 'none',
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    linkText: {
      color: 'black',
    },
  }));

  const classes = useStyles();

  return (
    <ThemeProvider theme={breatheTheme}>
      <div className={clsx(classes.root, styles.container)}>
        <AppBar
          position={'absolute'}
          className={clsx(classes.appBar, open && classes.appBarShift, styles.appbar)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Link to="/admin">
            <img src={logo} alt="Logo" className={styles.logo} /> <span>CORE</span>
          </Link>
          <Button onClick={signOut} className={styles.logoutbutton}>
            Hi {store.security.getSignedInName()} - logout
          </Button>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              <ListItem button to="/admin/" component={NavLink}>
                <ListItemIcon>
                  <DashboardIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Dashboard" />
              </ListItem>
              <ListItem button to="/admin/intakes" component={NavLink}>
                <ListItemIcon>
                  <IntakesIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Course Intakes" />
              </ListItem>
              <ListItem button to={DIPLOMA_MODULES_LIST} component={NavLink}>
                <ListItemIcon>
                  <DipModuleIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Diploma Modules" />
              </ListItem>
              <ListItem button to={TOTARALINKS_LIST} component={NavLink}>
                <ListItemIcon>
                  <LinkIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Totara Links" />
              </ListItem>
              <ListItem button to={TUTORIALATTENDANCE_LIST} component={NavLink}>
                <ListItemIcon>
                  <TutorialAttendanceIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Tutorial Attendance" />
              </ListItem>
              <ListItem button to={STUDENT_LIST} component={NavLink}>
                <ListItemIcon>
                  <AudienceListIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Students" />
              </ListItem>
              <ListItem button to={COMPETENCIES} component={NavLink}>
                <ListItemIcon>
                  <ChecklistIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Competencies" />
              </ListItem>
              <ListItem button to={STORY_TIME} component={NavLink}>
                <ListItemIcon>
                  <StoryTimeIcons color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Story Time" />
              </ListItem>
              <ListItem button to={LEARNINGSCHEDULE} component={NavLink}>
                <ListItemIcon>
                  <LearningScheduleIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Learning Schedule" />
              </ListItem>
              <ListItem button to={REPORTS_LIST} component={NavLink}>
                <ListItemIcon>
                  <ReportListIcon color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Reports" />
              </ListItem>
              <ListItem button to={SETTINGS} component={NavLink}>
                <ListItemIcon>
                  <SettingsIcons color={'primary'} />
                </ListItemIcon>
                <ListItemText className={classes.linkText} primary="Settings" />
              </ListItem>
            </div>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <ConfirmProvider>
            <div className={classes.container}>{children}</div>
          </ConfirmProvider>
        </main>
      </div>
    </ThemeProvider>
  );
};
