import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';

export const ProductModel = types.model('ProductModel', {
  id: types.number,
  product_Name: types.string,
});

export interface ICrmModel extends Instance<typeof CrmModel> {}
export interface IProductModel extends Instance<typeof ProductModel> {}

export const CrmModel = types
  .model('CrmModel', {
    products: types.array(ProductModel),
  })
  .actions((self) => ({
    loadAllProducts: flow(function* () {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`ProtectedCrm/products`)
          .json<IProductModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error ProtectedCrm/products error: ${error}`);
      }
    }),
  }))
  .views((self) => ({}));
