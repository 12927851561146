import {
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Audience } from '../../../domain/store/OnlineCoursesModel';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { ILogbookSummaryModel } from '../../../domain/store/ReportModel';
import styles from './LogbookIntakeSummaryReport.module.scss';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const LogbookIntakeSummaryReport: React.FC = () => {
  const [intakeList, setIntakeList] = useState<Audience[]>([{ name: '', id: '' }]);
  const [selectedAudience, setSelectedAudience] = useState<Audience | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [logbookSummaries, setLogbookSummaries] = useState<ILogbookSummaryModel[]>([]);

  const { onlineCoursesModel, reportModel } = useStore();

  useEffect(() => {
    onlineCoursesModel.loadElearningAudiences(true).then((intakes: Audience[]) => {
      setIntakeList(intakes);
    });
  }, [onlineCoursesModel]);

  const sortByName = function (a: string, b: string) {
    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (!selectedAudience) return;
    reportModel.getLogbookReport(selectedAudience!.id).then((result: ILogbookSummaryModel[]) => {
      setLogbookSummaries(
        result.sort((a: ILogbookSummaryModel, b: ILogbookSummaryModel) =>
          sortByName(a.name, b.name)
        )
      );
    });
  }, [selectedAudience, reportModel]);

  return (
    <div className={styles.container}>
      <h1>Logbook Summary</h1>
      <p>Note: only for Certificate intakes.</p>
      <Grid container>
        <Grid item md={4} sm={12}>
          <FormControl fullWidth>
            <Autocomplete
              className={styles.formField}
              value={selectedAudience}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onChange={(event, newValue) => {
                if (!newValue) {
                  setSelectedAudience(null);
                  return;
                }
                setInputValue('');
                setSelectedAudience(newValue as Audience);
              }}
              getOptionLabel={(option) => option.name}
              options={intakeList}
              renderInput={(params) => <TextField {...params} label="Select Intake" fullWidth />}
            />
          </FormControl>
        </Grid>
        <Grid item md={8}></Grid>
      </Grid>

      {selectedAudience && logbookSummaries.length > 0 && (
        <TableContainer className={styles.stickyTable}>
          <Table stickyHeader size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell colSpan={3}>Personal Practice Or Observation (hours)</TableCell>
                <TableCell>Practice Teaching (hours)</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Reformer (20+)</TableCell>
                <TableCell>Other</TableCell>
                <TableCell>Sub Total (40+)</TableCell>
                <TableCell>PT (40+)</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logbookSummaries.map((s, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Link to={`/admin/student/${s.email}`}>{s.name}</Link>&nbsp;
                    <Link
                      className={styles.openInNewIcon}
                      target={'_blank'}
                      to={`/admin/student/${s.email}`}>
                      <OpenInNewIcon />
                    </Link>
                    <br />
                    {s.email}
                  </TableCell>
                  <TableCell>{s.personalPracticeOrObservationReformerMinutes / 60}</TableCell>
                  <TableCell>{s.personalPracticeOrObservationOtherMinutes / 60}</TableCell>
                  <TableCell>
                    {s.personalPracticeOrObservationOtherMinutes / 60 +
                      s.personalPracticeOrObservationReformerMinutes / 60}
                  </TableCell>
                  <TableCell>{s.practiceTeachingMinutes / 60}</TableCell>
                  <TableCell>
                    {s.personalPracticeOrObservationOtherMinutes / 60 +
                      s.personalPracticeOrObservationReformerMinutes / 60 +
                      s.practiceTeachingMinutes / 60}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedAudience && logbookSummaries.length === 0 && <p>Not found for this intake</p>}
    </div>
  );
};
