import React, { useState } from 'react';
import styles from './TotaraLinks.module.scss';
import { Link } from 'react-router-dom';
import { TotaraEmbedCodeCopyToClipBoard } from '../../components/TotaraEmbedCodeCopyToClipBoard';
import { MasterclassList } from '../masterclass/MasterclassList';
import { CourseType } from '../../../domain/store/OnlineCoursesModel';
import { Grid, TextField } from '@material-ui/core';

export const TotaraLinks: React.FC = function () {
  const [classAppointmentTypeCategory, setClassAppointmentTypeCategory] = useState<string>(
    'Masterclasses'
  );
  const [classTypeName, setClassTypeName] = useState<string>('Masterclass');

  const [roomAppointmentTypeCategory, setRoomAppointmentTypeCategory] = useState<string>(
    ' Room Bookings'
  );
  const [roomTypeName, setRoomTypeName] = useState<string>('Study Group');
  const [roomTypeSessionTypes, setRoomTypeSessionTypes] = useState<string>(
    'Topic A,Topic B,Topic C'
  );

  return (
    <div className={styles.container}>
      <h1>Practice Teaching</h1>

      <p>
        <Link
          to={'/public/intake/pt/students/manage/%20Practice%20Teaching%20Room%20Bookings'}
          target={'_blank'}>
          Book my Practice Teaching
        </Link>
        <TotaraEmbedCodeCopyToClipBoard />
      </p>

      <MasterclassList />

      <h1>
        Generic Acuity <u>Class</u> Booking Link Generator
      </h1>
      <p>
        For class-like bookings, such as tutorials, masterclasses, review sessions etc. Does not
        include the "Sign up to peer-hosted session" feature.
      </p>
      <br />
      <Grid container>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            id="classAppointmentTypeCategory"
            label="Acuity AppointmentType Category*"
            type="text"
            value={classAppointmentTypeCategory}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setClassAppointmentTypeCategory(event.target.value)}
          />
          <br />
          <br />
          <span>* Appointment Type must be configured as a class in Acuity.</span>
          <br />
          <span>* Multiple Appointment Types can be joined by using the tilda "~" symbol.</span>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            id="classTypeName"
            label="Class Type Name (singular)"
            type="text"
            value={classTypeName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setClassTypeName(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <br />
          <Link
            to={`/public/intake/class/students/manage/${classAppointmentTypeCategory}?classTypeName=${classTypeName}`}
            target={'_blank'}>
            Manage {classTypeName ? classTypeName : 'INCOMPLETE'}
          </Link>
          <TotaraEmbedCodeCopyToClipBoard />
        </Grid>
      </Grid>

      <h1>
        Generic Acuity <u>Room</u> Booking Link Generator
      </h1>
      <p>
        For room-like bookings, such as Practice Teaching, Study Groups etc. Includes ability to
        self-sign-up to peer-hosted sessions.
      </p>
      <br />
      <Grid container>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            id="classAppointmentTypeCategory"
            label="Acuity AppointmentType Category*"
            type="text"
            value={roomAppointmentTypeCategory}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setRoomAppointmentTypeCategory(event.target.value)}
          />
          <br />
          <br />
          <span>* Multiple Appointment Types can be joined by using the tilda "~" symbol.</span>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            id="classTypeName"
            label="Room Type Name (singular)"
            type="text"
            value={roomTypeName}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setRoomTypeName(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={5}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            id="classTypeName"
            label="Session Types (comma separated; keep unique between links)"
            type="text"
            value={roomTypeSessionTypes}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setRoomTypeSessionTypes(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <br />
          <Link
            to={
              `/public/intake/room/students/manage/${roomAppointmentTypeCategory}?classTypeName=${roomTypeName}` +
              (roomTypeSessionTypes.trim().length > 0
                ? `&sessionTypes=${roomTypeSessionTypes}`
                : '')
            }
            target={'_blank'}>
            Manage {roomTypeName ? roomTypeName : 'INCOMPLETE'}
          </Link>
          <TotaraEmbedCodeCopyToClipBoard />
        </Grid>
      </Grid>

      <h1>Logbook</h1>
      <p>Requires a URL variable called "email". </p>
      <p>
        <Link to={'/public/student/logbook/' + CourseType.Certificate} target={'_blank'}>
          Student Logbook (Certificate, pre May 2023)
        </Link>
        <TotaraEmbedCodeCopyToClipBoard />
      </p>
      <p>
        <Link
          to={'/public/student/logbook/' + CourseType.Certificate + '?comprehensive=true'}
          target={'_blank'}>
          Student Logbook (Comprehensive Certificate, pre May 2023)
        </Link>
        <TotaraEmbedCodeCopyToClipBoard />
      </p>
      <p>
        <Link
          to={'/public/student/logbook/' + CourseType.Certificate + '?writtenreflection=true'}
          target={'_blank'}>
          Student Logbook (Certificate, May 2023 onwards, with written reflection)
        </Link>
        <TotaraEmbedCodeCopyToClipBoard />
      </p>
      <p>
        <Link
          to={
            '/public/student/logbook/' +
            CourseType.Certificate +
            '?comprehensive=true&writtenreflection=true'
          }
          target={'_blank'}>
          Student Logbook (Comprehensive Certificate, May 2023 onwards, with written reflection)
        </Link>
        <TotaraEmbedCodeCopyToClipBoard />
      </p>
      <h1>Grade book</h1>
      <p>Requires a URL variable called "email".</p>
      <p>
        <Link to={'/public/student/gradebook/' + CourseType.Certificate} target={'_blank'}>
          Student Grade book (Certificate / Comprehensive Certificate)
        </Link>
        <TotaraEmbedCodeCopyToClipBoard />
      </p>
    </div>
  );
};
