import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  Audience,
  ElearningUserDetails,
  IIntakeModel,
  IIntakeSummaryModel,
} from 'domain/store/OnlineCoursesModel';
import React, { useEffect, useState } from 'react';
import { useStore } from 'views/hooks';
import styles from './StudentList.module.scss';
import EditIcon from '@material-ui/icons/Edit';
import qs from 'qs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const StudentList: React.FC = function () {
  const history = useHistory();
  const location = useLocation();
  const [elearningAudienceList, setElearningAudienceList] = useState<Audience[]>([
    { name: '', id: '' },
  ]);
  const [allUsers, setAllUsers] = useState<ElearningUserDetails[]>([]);
  const [selectedAudience, setSelectedAudience] = useState<Audience | null>(null);
  const { onlineCoursesModel } = useStore();
  const [showSpinner, setShowSpinner] = useState(false);
  const [audienceUsers, setAudienceUsers] = useState<ElearningUserDetails[] | null>(null);
  const [audienceValue, setAudienceValue] = useState('');
  const [selectedIntake, setSelectedIntake] = useState<IIntakeSummaryModel | null>(null);
  const [selectedUser, setSelectedUser] = useState<ElearningUserDetails | null>(null);
  const [userInputValue, setUserInputValue] = useState('');
  const [intakes, setIntakes] = useState<IIntakeSummaryModel[]>([]);

  useEffect(() => {
    const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const email = qsParams.email;

    if (email) {
      setShowSpinner(true);
      onlineCoursesModel
        .getElearningAudienceUser(email)
        .then((value: ElearningUserDetails) => {
          setSelectedUser(value);
          setAudienceUsers([value]);
        })
        .catch((e) => {
          console.error('Error during getElearningAudienceUser', e);
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  }, [onlineCoursesModel]);

  useEffect(() => {
    setShowSpinner(true);
    Promise.all([
      onlineCoursesModel.getAllElearningUsers(),
      onlineCoursesModel.loadElearningAudiences(true),
      onlineCoursesModel.loadIntakesSummaries(),
    ])
      .then(([allUsers, audiences]) => {
        if (audiences) {
          setElearningAudienceList([{ name: '', id: '' }, ...audiences]);
        }
        if (allUsers) {
          setAllUsers(allUsers);
        }

        setIntakes([...onlineCoursesModel.intakes] as IIntakeSummaryModel[]);

        const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const queryIntakeId = qsParams.selectedIntake;
        const queryAudience = qsParams.selectedAudience;

        if (queryIntakeId) {
          const intake = onlineCoursesModel.intakes.find((i) => i.id === parseInt(queryIntakeId));
          setSelectedIntake(intake as IIntakeSummaryModel);
        }

        if (queryAudience) {
          const audience = audiences.find((a: Audience) => a.name === queryAudience);
          setSelectedAudience(audience as Audience);
        }
      })
      .catch((e) => {
        console.error('Unable to load data from server', e);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [onlineCoursesModel]);

  useEffect(() => {
    if (!selectedIntake) return;

    setShowSpinner(true);
    setSelectedAudience(null);
    setUserInputValue('');

    onlineCoursesModel.loadIntakeById(selectedIntake.id).then((intake: IIntakeModel) => {
      let promises = [];

      if (intake.elearningAudience) {
        promises.push(
          onlineCoursesModel.getElearningAudienceUsers(intake.elearningAudience as string)
        );
      }
      if (intake.elearningAudienceSecondary) {
        promises.push(
          onlineCoursesModel.getElearningAudienceUsers(intake.elearningAudienceSecondary as string)
        );
      }

      if (promises.length === 0) {
        return;
      }

      Promise.all(promises)
        .then((values: ElearningUserDetails[][]) => {
          setAudienceUsers(_.sortBy(values.flat(), (user) => user.firstName));
        })
        .catch((e) => {
          console.error('Unable to get elearning audience users list', e);
        })
        .finally(() => {
          setShowSpinner(false);
        });
    });
  }, [selectedIntake, onlineCoursesModel]);

  useEffect(() => {
    if (!selectedAudience) return;

    onlineCoursesModel
      .getElearningAudienceUsers(selectedAudience?.id as string)
      .then((value: ElearningUserDetails[]) => {
        setAudienceUsers(value);
      })
      .catch((e) => {
        console.error('Unable to get elearning audience users list', e);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [selectedAudience, onlineCoursesModel]);

  const handleStudentRowSelected = (email: string) => {
    history.push(`student/${email}`);
  };

  return (
    <Box>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <h1>Students</h1>
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <Box>
            <Autocomplete
              value={selectedIntake}
              onChange={(event, newValue) => {
                setSelectedIntake(newValue as IIntakeSummaryModel);
                const params = new URLSearchParams({
                  selectedIntake: newValue?.id.toString() ?? '',
                });
                history.replace({ pathname: location.pathname, search: params.toString() });
              }}
              getOptionLabel={(intake) => intake.title ?? ''}
              options={intakes}
              renderInput={(params) => <TextField {...params} label="Search by Intake" fullWidth />}
            />
          </Box>
          <Box>
            <FormControl fullWidth>
              <Autocomplete
                value={selectedAudience}
                inputValue={audienceValue}
                onInputChange={(event, newInputValue) => {
                  setAudienceValue(newInputValue);
                  if (newInputValue) {
                    const params = new URLSearchParams({ selectedAudience: newInputValue });
                    history.replace({ pathname: location.pathname, search: params.toString() });
                  }
                }}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    setAudienceUsers(null);
                    return;
                  }
                  setUserInputValue('');
                  setSelectedIntake(null);
                  setShowSpinner(true);
                  setSelectedAudience(newValue as Audience);
                }}
                getOptionLabel={(option) => option.name}
                options={elearningAudienceList}
                renderInput={(params) => (
                  <TextField {...params} label="Search by Audience" fullWidth />
                )}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <Autocomplete
                value={selectedUser}
                inputValue={userInputValue}
                onInputChange={(event, newInputValue) => {
                  setUserInputValue(newInputValue);
                  setAudienceValue('');
                }}
                onChange={(event, newValue) => {
                  if (!newValue?.lmsUserName) {
                    setAudienceUsers([]);
                    setSelectedUser(null);
                    return;
                  }

                  setShowSpinner(true);
                  setSelectedUser(newValue as ElearningUserDetails);
                  setAudienceValue('');
                  setSelectedIntake(null);
                  setAudienceUsers(null);

                  const params = new URLSearchParams({
                    email: newValue?.lmsUserName.toString() ?? '',
                  });
                  history.replace({ pathname: location.pathname, search: params.toString() });

                  onlineCoursesModel
                    .getElearningAudienceUser(newValue?.lmsUserName as string)
                    .then((value: ElearningUserDetails) => {
                      setAudienceUsers([value]);
                    })
                    .catch((e) => {
                      console.error('Unable to get elearning audiences for user', e);
                    })
                    .finally(() => {
                      setShowSpinner(false);
                    });
                }}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName} - ${option.email}`
                }
                // only load options when the user search input is least 4 characters
                // to avoid loading the whole list of users and overwhelming the browser
                options={userInputValue && userInputValue.length > 1 ? allUsers : []}
                renderInput={(params) => (
                  <TextField {...params} label="Search by name or email" fullWidth />
                )}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>Filters</h3>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="My students only" />
            <FormControlLabel control={<Checkbox />} label="Active Enrolments only" />
          </FormGroup>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid item xs={12} md={12}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name{' '}
                  {audienceUsers &&
                    audienceUsers.length > 0 &&
                    '(count: ' + audienceUsers.length + ')'}
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Flags</TableCell>
                <TableCell>Coach</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {audienceUsers &&
                audienceUsers.map((m, k) => (
                  <TableRow key={k}>
                    <TableCell>
                      {m.firstName} {m.lastName}
                    </TableCell>
                    <TableCell>
                      <a href={'mailto:' + m.email}>{m.email}</a>
                    </TableCell>
                    <TableCell>
                      <a href={'tel:' + (m.phone1 || m.phone2)}>{m.phone1 || m.phone2}</a>
                    </TableCell>
                    <TableCell>
                      {m.countryName}, {m.city}
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleStudentRowSelected(m.email)}
                        aria-label="edit"
                        color="primary">
                        <EditIcon />
                      </IconButton>
                      <Link
                        className={styles.openInNewIcon}
                        target={'_blank'}
                        to={`/admin/student/${m.email}`}>
                        <OpenInNewIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Box>
    </Box>
  );
};
