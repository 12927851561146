import ky from 'ky';
import { Instance, types } from 'mobx-state-tree';
import { CategoriesRepo } from './repos/CategoriesRepo';
import { getDefaultAjaxInstance } from './util';
import { OnlineCoursesModel } from './OnlineCoursesModel';
import { IAuthenticationProvider } from 'auth/AuthenticationTypes';
import { SecurityModel } from './SecurityModel';
import moment from 'moment';
import { TutorialAttendanceModel } from './TutorialAttendanceModel';
import { BookingModel } from './BookingModel';
import { CrmModel } from './CrmModel';
import { StoryTimeModel } from './StoryTimeModel';
import { LogbookModel } from './LogbookModel';
import { ReportModel } from './ReportModel';
import { StudentStore } from './StudentStore';
import { FormStore } from './FormStore';
import { SettingsStore } from './SettingsStore';

export type ModelLoadingState = 'loading' | 'done' | 'error' | 'unauthorized';

export const LoadingStateModel = types.optional(
  types.enumeration<ModelLoadingState>(['loading', 'done', 'error', 'unauthorized']),
  'loading'
);

const RootStoreModel = types.model(`RootStoreModel`, {
  state: LoadingStateModel,
  categoriesRepo: types.optional(CategoriesRepo, {}),
  onlineCoursesModel: types.optional(OnlineCoursesModel, {}),
  security: types.optional(SecurityModel, {}),
  defaultUserTimezone: types.string,
  tutorialAttendanceModel: types.optional(TutorialAttendanceModel, {}),
  bookingModel: types.optional(BookingModel, {}),
  crmModel: types.optional(CrmModel, {}),
  storyTimeModel: types.optional(StoryTimeModel, {}),
  logbookModel: types.optional(LogbookModel, {}),
  reportModel: types.optional(ReportModel, {}),
  studentStore: types.optional(StudentStore, {}),
  formStore: types.optional(FormStore, {}),
  settingsStore: types.optional(SettingsStore, {}),
});

export interface IRootStoreModel extends Instance<typeof RootStoreModel> {}

export interface IStoreEnvironment {
  ajax: typeof ky;
  auth: IAuthenticationProvider;
}

export function getDefaultStore(auth: IAuthenticationProvider): IRootStoreModel {
  const ajax = getDefaultAjaxInstance(auth);
  const defaultUserTimezone = localStorage.getItem('userTimezone') ?? moment.tz.guess();

  return RootStoreModel.create(
    { state: 'loading', defaultUserTimezone: defaultUserTimezone },
    { ajax, auth }
  );
}
