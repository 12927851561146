import { Box, Button, Card, Container, Grid, Link, TextField } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import { Autocomplete } from '@material-ui/lab';
import { IIntakeSummaryModel } from 'domain/store/OnlineCoursesModel';
import React, { useEffect, useState } from 'react';
import { useStore } from 'views/hooks';
import styles from './learningSchedule.module.scss';

export const LearningSchedule: React.FC = () => {
  const { onlineCoursesModel } = useStore();
  const [intakes, setIntakes] = useState<IIntakeSummaryModel[]>([]);
  const [selectedIntake, setSelectedIntake] = useState<IIntakeSummaryModel>();
  const [intakeLearningScheduleLink, setIntakeLearningScheduleLink] = useState('');

  useEffect(() => {
    onlineCoursesModel.loadIntakesSummaries().then(() => {
      setIntakes([...onlineCoursesModel.intakes] as IIntakeSummaryModel[]);
    });
  }, [onlineCoursesModel]);

  return (
    <>
      <div className={styles.container}>
        <h1>Learning Schedule</h1>
        <br />
        <span>Note, this is a work in progress. This is not functional.</span>
      </div>

      <Container className={styles.section}>
        <Box mt={2}>
          <Grid container justify={'flex-start'} spacing={2}>
            <Grid item xs={6} md={6}>
              <Autocomplete
                value={selectedIntake}
                onChange={(event, newValue) => {
                  setSelectedIntake(newValue as IIntakeSummaryModel);
                  setIntakeLearningScheduleLink('https://core.breathe.edu.au');
                }}
                getOptionLabel={(intake) => intake.title ?? ''}
                options={intakes}
                renderInput={(params) => (
                  <TextField {...params} label="Select an intake..." fullWidth />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box hidden={!intakeLearningScheduleLink} mt={4} width={'50%'}>
          <Card className={styles.learningCard} variant={'outlined'}>
            <Grid container justify={'flex-start'}>
              <Grid item xs={9} md={9} xl={9}>
                <Link target="_blank" rel="noopener noreferrer" href={intakeLearningScheduleLink}>
                  Open intake learning schedule
                </Link>
              </Grid>
              <Grid item xs={3} md={3} xl={3}>
                <Button
                  color={'primary'}
                  variant="outlined"
                  endIcon={<FileCopy />}
                  onClick={() => {
                    navigator.clipboard.writeText(intakeLearningScheduleLink);
                  }}>
                  Copy link
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Container>
    </>
  );
};
