import { createMuiTheme, darken, lighten } from '@material-ui/core/styles';

export const breatheTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#636c93',
      dark: darken('#636c93', 0.1),
      light: lighten('#636c93', 0.1),
    },
    secondary: {
      main: '#3e3e3e',
      dark: darken('#3e3e3e', 0.1),
      light: lighten('#3e3e3e', 0.1),
    },
  },
  typography: {
    fontFamily: ['Poppins', 'Roboto', 'Segoe UI', 'Helvetica'].join(','),
  },
});
