import { TimezoneSelector } from 'views/components/TimezoneSelector/TimezoneSelector';
import styles from 'views/public/StudentClasses/AttendanceMakeUpBooking/TutorialMakeUpBooking.module.scss';
import React, { useEffect, useState } from 'react';
import { useStore } from 'views/hooks';
import { useParams } from 'react-router-dom';
import {
  IAcuityAppointmentBookingResponse,
  IntakeTutorialModel,
  TutorialMakeUpOptions,
} from 'domain/store/BookingModel';
import moment from 'moment';
import _, { Dictionary } from 'lodash';
import { ArrowBack, CheckCircle, Clear, Error, ExpandMore } from '@material-ui/icons';
import {
  ITutorialAttendanceCaseModel,
  TutorialAttendanceCaseStatusType,
} from 'domain/store/TutorialAttendanceModel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import logo from 'images/breathe-education-logo-red-retina.png';
import { getClassTypeLabel, StudentClassType } from '../StudentClassType';

export const TutorialMakeUpBooking: React.FC = () => {
  const [showSpinner, setShowSpinner] = React.useState(false);
  const { publicId } = useParams<{ publicId: string }>();
  const [attendanceCase, setAttendanceCase] = useState<ITutorialAttendanceCaseModel>();
  const { defaultUserTimezone, bookingModel } = useStore();
  const [userTimezone, setUserTimezone] = useState<string>(defaultUserTimezone);
  const [classes, setClasses] = React.useState<IntakeTutorialModel[]>([]);
  const [groupedClasses, setGroupedClasses] = React.useState<Dictionary<IntakeTutorialModel[]>>({});
  const [selectedClassId, setSelectedClassId] = React.useState('');
  const accordionRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const bookButtonRef: React.RefObject<HTMLButtonElement> = React.useRef(null);
  const confirmationMessageRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [termsCheckbox, setTermsCheckbox] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [bookingResponse, setBookingResponse] = React.useState<IAcuityAppointmentBookingResponse>();
  const [error, setError] = React.useState<bookingError | null>(null);

  interface bookingError {
    errorTitle: string;
    errorDescription?: string;
  }

  useEffect(() => {
    setShowSpinner(true);
    bookingModel.getTutorialMakeUpAvailableTimes(publicId).then((result: TutorialMakeUpOptions) => {
      console.log(result);
      setClasses(result.availableTutorials);
      updateGroupedClasses(userTimezone, result.availableTutorials);
      setAttendanceCase(result.attendanceCase);

      if (
        result.attendanceCase.currentStatus.status ===
        TutorialAttendanceCaseStatusType.RebookingMade
      ) {
        setError({
          errorTitle: 'Uh oh! booking already exists.',
          errorDescription: 'A make up booking has already been made for this tutorial.',
        });
      }
      setShowSpinner(false);
    });
  }, [bookingModel, userTimezone, publicId]);

  const handleUserTimeZoneChange = (newValue: string) => {
    if (newValue !== userTimezone) {
      setUserTimezone(newValue);
      updateGroupedClasses(newValue, classes);
    }
  };

  const handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setExpanded(expanded);
    setSelectedClassId(event.target.value as string);

    setTimeout(() => {
      if (bookButtonRef.current) {
        bookButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  };

  const makeBooking = () => {
    setShowSpinner(true);
    setModalOpen(false);
    const tutorialClass = classes.find((c) => c.id.toString() === selectedClassId);

    bookingModel
      .postBookTutorialMakeUpAppointment(
        {
          appointmentTypeID: tutorialClass?.appointmentTypeID as number,
          datetime: tutorialClass?.time as Date,
          firstName: attendanceCase?.firstName as string,
          lastName: attendanceCase?.lastName as string,
          email: attendanceCase?.email as string,
          timezone: userTimezone,
          ptSessionType: '',
          allowPeerSelfSignup: false,
          classType: getClassTypeLabel(StudentClassType.Tutorial, false),
          sendConfirmationEmail: true,
          preRegisterParticipant: false,
          preRegisterQuestions: null,
          isJoiningWaitlist: false,
          calendar: tutorialClass?.calendar as string,
        },
        attendanceCase?.id as number
      )
      .then(
        (r) => {
          setBookingResponse(r);
          setShowSpinner(false);
          setTimeout(() => {
            confirmationMessageRef.current &&
              confirmationMessageRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 200);
        },
        (error) => {
          setError({
            errorTitle: 'Uh oh! There was an error while trying to make your booking.',
            errorDescription: 'Please try to book again or contact us if the problem persists.',
          });
          setShowSpinner(false);
          console.log('Booking error: ' + JSON.stringify(error));
          setTimeout(() => {
            confirmationMessageRef.current &&
              confirmationMessageRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 200);
        }
      );
  };

  const updateGroupedClasses = (newTimeZone: string, updatedClasses: IntakeTutorialModel[]) => {
    // group by date so we can show group of times for each day
    const grouped = _(updatedClasses)
      .filter((c) => moment(c.time) >= moment())
      .sortBy((a) => a.time)
      .groupBy((c) => moment(c.time).tz(newTimeZone).format('dddd, MMMM Do YYYY'))
      .value();

    setGroupedClasses(grouped);
  };

  const getAvailabilityLabel = (classesOnTheDay: IntakeTutorialModel[]) => {
    const numberOfClassesWithAvailableSpots = classesOnTheDay.filter((t) => t.slotsAvailable > 0)
      .length;
    const numberOfSpots = _.sumBy(classesOnTheDay, (t) => t.slotsAvailable);

    if (!numberOfClassesWithAvailableSpots) {
      return `No tutorials with spots left`;
    }

    return `${numberOfClassesWithAvailableSpots} tutorial(s) with ${numberOfSpots} spot(s) left`;
  };

  return (
    <>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.container}>
        <div className={styles.paperContainer}>
          <div hidden={!!bookingResponse || !!error}>
            {/* Classes date and time availability */}
            <>
              <Grid container justify="center">
                <Box mb={6}>
                  <img src={logo} alt="logo" />
                </Box>
              </Grid>
              <Box mt={2} mb={3}>
                <span>
                  Choose a date and time to book your{' '}
                  {attendanceCase?.tutorialWeek === 0
                    ? 'orientation week'
                    : 'week ' + attendanceCase?.tutorialWeek}{' '}
                  tutorial
                </span>
              </Box>
              <div style={{ border: 'ridge' }} ref={accordionRef}>
                <Box ml={8}>
                  <TimezoneSelector onChange={handleUserTimeZoneChange} />
                </Box>
                <Box m={2}></Box>
                <Box className={styles.root}>
                  <RadioGroup
                    aria-label="time"
                    name="time"
                    value={selectedClassId}
                    onChange={handleTimeChange}>
                    {!groupedClasses || Object.keys(groupedClasses).length === 0 ? (
                      <Box component="span" ml={8} mb={4}>
                        No times available for this tutorial week
                      </Box>
                    ) : (
                      Object.keys(groupedClasses).map((k, i) => (
                        <Accordion
                          key={'accordion' + i}
                          expanded={expanded === 'panel' + i}
                          onChange={(event: React.ChangeEvent<{}>, isExpanded: boolean) =>
                            setExpanded(isExpanded ? 'panel' + i : false)
                          }
                          className={
                            _.some(
                              groupedClasses[k],
                              (c: IntakeTutorialModel) => c.id.toString() === selectedClassId
                            )
                              ? styles.selectedTime
                              : ''
                          }>
                          <AccordionSummary
                            key={'accordionSummary' + i}
                            expandIcon={<ExpandMore />}
                            aria-controls={'panel' + i}
                            id={'panel' + i + '-header'}>
                            <Typography key={'typography1' + i} className={styles.heading}>
                              {' '}
                              {k}{' '}
                            </Typography>
                            <Typography key={'Typography2' + i} className={styles.secondaryHeading}>
                              {getAvailabilityLabel(groupedClasses[k])}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            key={'accordionDetails' + i}
                            className={styles.accordionDetails}>
                            {groupedClasses[k].map((v, i) => (
                              <FormControlLabel
                                key={'formControlLabel' + i}
                                value={v.id.toString()}
                                control={<Radio />}
                                disabled={v.slotsAvailable === 0}
                                label={
                                  <>
                                    <span>{moment(v.time).tz(userTimezone).format('h:mm a')}</span>
                                    {v.slotsAvailable === 0 ? (
                                      <Chip
                                        className={styles.chip}
                                        size="small"
                                        label="FULLY BOOKED"
                                      />
                                    ) : (
                                      <Chip
                                        className={styles.chip}
                                        color="secondary"
                                        size="small"
                                        label={`${v.slotsAvailable} spot${
                                          v.slotsAvailable > 1 ? 's' : ''
                                        } left`}
                                      />
                                    )}
                                    {
                                      <Chip
                                        className={styles.chip}
                                        size="small"
                                        label={v.calendar}
                                      />
                                    }
                                  </>
                                }
                              />
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ))
                    )}
                  </RadioGroup>
                </Box>
              </div>
            </>

            {/* Booking and cancel buttons */}
            <Box mt={5}>
              <Box component="span" hidden={!selectedClassId} ml={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  ref={bookButtonRef}
                  onClick={() => {
                    setModalOpen(true);
                    setTermsCheckbox(false);
                  }}>
                  Book your spot
                </Button>
              </Box>
            </Box>
            {/* Booking confirmation modal */}
            {selectedClassId && (
              <>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={styles.modal}
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}>
                  <Fade in={modalOpen}>
                    <div className={styles.paper}>
                      <Box width="5%" ml="auto">
                        <IconButton aria-label="close" onClick={() => setModalOpen(false)}>
                          <Clear />
                        </IconButton>
                      </Box>
                      <h2 id="transition-modal-title" className={styles.paragraphHeading}>
                        Attendance &amp; Participation
                      </h2>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={termsCheckbox}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setTermsCheckbox(event.target.checked)
                              }
                              name="termsCheckbox"
                            />
                          }
                          label="I confirm that I agree to the Code of Conduct and Waiver of
                                    Liability, and will be 10 minutes early for my class"
                        />
                        <Box ml={4}>
                          <div>
                            <a
                              href="https://breathe-education.com/waiver-of-liability"
                              target="_blank"
                              rel="noreferrer">
                              Waiver of Liability
                            </a>
                          </div>
                          <div>
                            <a
                              href="https://breathe-education.com/terms-of-service/#codeofconduct"
                              target="_blank"
                              rel="noreferrer">
                              Code of Conduct
                            </a>
                          </div>
                        </Box>
                        <Box mt={4}>
                          <Box component="span" mr={2}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="medium"
                              onClick={() => setModalOpen(false)}
                              startIcon={<ArrowBack />}>
                              Back
                            </Button>
                          </Box>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            disabled={!termsCheckbox}
                            onClick={makeBooking}>
                            Complete booking
                          </Button>
                        </Box>
                      </FormControl>
                    </div>
                  </Fade>
                </Modal>
              </>
            )}
          </div>

          <div ref={confirmationMessageRef}>
            {/* Booking success message */}
            <Box
              hidden={!bookingResponse}
              mt={4}
              pt={4}
              pb={4}
              mr="auto"
              ml="auto"
              width="60%"
              className={styles.confirmationMessage}>
              <h2 className={`${styles.paragraphHeading} ${styles.h2ConfirmationMessage}`}>
                <CheckCircle className={styles.successIcon} fontSize="large" />
                <span> Your appointment has been booked!</span>
              </h2>
              <div>
                <p>
                  <strong>{`Tutorial time`}</strong>
                </p>
                <p>
                  {moment(bookingResponse?.dateTime)
                    .tz(userTimezone)
                    .format('dddd, MMMM Do YYYY h:mm a')}
                </p>
                <br />
                <div>
                  <p>
                    <strong>Trainer</strong>
                  </p>
                  <p>{bookingResponse?.calendar}</p>
                </div>
              </div>
            </Box>

            {/* Booking error message */}
            <Box
              hidden={!error}
              mt={4}
              pt={4}
              pb={4}
              mr="auto"
              ml="auto"
              width="60%"
              className={styles.confirmationMessage}>
              <div>
                <h2 className={`${styles.paragraphHeading} ${styles.h2ConfirmationMessage}`}>
                  <Error className={styles.errorIcon} fontSize="large" />
                  <span> {error?.errorTitle}</span>
                </h2>
                <p>{error?.errorDescription}</p>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
