import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';

export const LogbookSummaryModel = types.model('LogbookSummaryModel', {
  name: types.string,
  email: types.string,
  personalPracticeOrObservationOtherMinutes: types.number,
  personalPracticeOrObservationReformerMinutes: types.number,
  practiceTeachingMinutes: types.number,
});

export interface ILogbookSummaryModel extends Instance<typeof LogbookSummaryModel> {}

export const ReportModel = types.model('ReportModel', {}).actions((self) => ({
  getLogbookReport: flow(function* (audienceId: string) {
    const { ajax } = getEnv(self);

    try {
      return yield ajax
        .get(`ProtectedReport/GetLogbookSummary?audienceId=${audienceId}`)
        .json<ILogbookSummaryModel[]>()
        .then((value) => value);
    } catch (error) {
      console.log(
        `error GET ProtectedReport/GetLogbookSummary?intakeId=${audienceId} error: ${error}`
      );
    }
  }),
}));
