import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from 'react-router-dom';
import styles from './IntakesList.module.scss';
import { useStore } from '../../hooks';
import { IIntakeSummaryModel } from '../../../domain/store/OnlineCoursesModel';
import EditIcon from '@material-ui/icons/Edit';
import { TotaraEmbedCodeCopyToClipBoard } from '../../components/TotaraEmbedCodeCopyToClipBoard';

export const IntakesList: React.FC = function () {
  const { onlineCoursesModel } = useStore();
  const [intakes, setIntakes] = useState<IIntakeSummaryModel[]>([]);

  useEffect(() => {
    onlineCoursesModel.loadIntakesSummaries().then(() => {
      setIntakes(onlineCoursesModel.intakes);
    });
  }, [onlineCoursesModel]);

  return (
    <div className={styles.container}>
      <h1>
        Intakes
        <Link to={'/admin/intakes/add'}>
          <AddBoxIcon color={'primary'} fontSize="large" />
        </Link>
      </h1>

      <p>
        Totara URL activity snippet <TotaraEmbedCodeCopyToClipBoard /> (enables link-to-button UI
        and widens embedded BE Core pages).
      </p>

      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Intake</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Public Schedule</TableCell>
            <TableCell>Lectures</TableCell>
            <TableCell>Tutorials</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {intakes!.map((i) => (
            <TableRow key={i.id}>
              <TableCell>{i.title}</TableCell>
              <TableCell>{i.id}</TableCell>
              <TableCell>
                <Link to={'/admin/intakes/edit/' + i.id}>
                  <EditIcon />
                </Link>
              </TableCell>
              <TableCell>
                <Link to={'/public/intake/schedule/' + i.id + '?country=AU'} target={'_blank'}>
                  Public Schedule
                </Link>
              </TableCell>
              <TableCell>
                <>
                  {i.modules.length > 0 && (
                    <>
                      <Link
                        to={'/public/intake/schedule/student/' + i.publicIdentifier}
                        target={'_blank'}>
                        My Lectures (All Modules)
                      </Link>
                      {i.modules.map((m, x) => (
                        <div key={x}>
                          <Link
                            to={
                              '/public/intake/schedule/student/' + i.publicIdentifier + '/' + m.id
                            }
                            target={'_blank'}>
                            My Lectures ({m.title})
                          </Link>
                        </div>
                      ))}
                    </>
                  )}
                </>
                <>
                  {i.modules.length === 0 && (
                    <>
                      <Link
                        to={'/public/intake/schedule/student/' + i.publicIdentifier}
                        target={'_blank'}>
                        My Lectures
                      </Link>
                    </>
                  )}
                </>
              </TableCell>
              <TableCell>
                <>
                  {i.modules.length > 0 && (
                    <>
                      {i.modules.map((m, x) => (
                        <div key={x}>
                          {(m.acuityCategory ?? '')?.length > 0 && (
                            <>
                              <Link
                                to={'/public/intake/tutorials/students/manage/' + m.acuityCategory}
                                target={'_blank'}>
                                Manage My Tutorials ({m.title})
                              </Link>
                            </>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </>

                <>
                  {i.modules.length === 0 && (
                    <>
                      {(i.acuityCategory ?? '')?.length > 0 && (
                        <>
                          <Link
                            to={'/public/intake/tutorials/students/manage/' + i.acuityCategory}
                            target={'_blank'}>
                            Manage My Tutorials
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
