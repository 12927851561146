import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';
import axios from 'axios';
import LogService from '../../services/LogService';

export const StoryTimeVideoMessageModel = types.model('StoryTimeVideoMessageModel', {
  id: types.maybeNull(types.identifierNumber),
  message: types.string,
  createdBy: types.string,
  createdOn: types.string,
});

export const StoryTimeVideoPublicModel = types.model('StoryTimeVideoPublicModel', {
  id: types.identifierNumber,
  email: types.string,
  moduleId: types.maybeNull(types.number),
  weekId: types.number,
  uploadCompletionDateTime: types.maybeNull(types.string),
  playerEmbedUrl: types.string,
  createdDateTime: types.string,
  messages: types.array(StoryTimeVideoMessageModel),
  hasFeedback: types.maybeNull(types.boolean),
  markedCompleteByTrainerTimestamp: types.maybeNull(types.string),
  lastVideoUploadSubmittedOn: types.maybeNull(types.string),
});

export const StudentStoryTimeVideoModel = types.model('StudentStoryTimeVideoModel', {
  firstName: types.string,
  lastName: types.string,
  email: types.string,
  storyTimeVideo: types.maybeNull(StoryTimeVideoPublicModel),
});

export const InitialiseUploadResponseModel = types.model('InitialiseUploadResponseModel', {
  uploadLink: types.string,
  breatheVideoID: types.string,
});

export interface IStoryTimeVideoMessageModel extends Instance<typeof StoryTimeVideoMessageModel> {}

export interface IInitialiseUploadResponse extends Instance<typeof InitialiseUploadResponseModel> {}

export interface IStoryTimeVideoPublicModel extends Instance<typeof StoryTimeVideoPublicModel> {
  messages: any;
}

export interface IStudentStoryTimeVideoModel extends Instance<typeof StudentStoryTimeVideoModel> {}

export const StoryTimeModel = types
  .model('StoryTimeModel', {
    uploadProgress: types.maybeNull(types.number),
  })
  .actions((self) => ({
    initiliaseUpload: flow(function* (
      sizeBytes: number,
      email: string,
      moduleId: number,
      week: number
    ) {
      self.uploadProgress = 0;
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .get(
            `PublicStoryTime/initialise-upload?email=${email}&moduleId=${moduleId}&week=${week}&size=${sizeBytes}`
          )
          .json<IInitialiseUploadResponse>()
          .then((value) => value);
      } catch (error) {
        return Promise.reject(error);
      }
    }),
    reportUploadCompleted: flow(function* (publicIdentifier: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`PublicStoryTime/confirm-upload-complete/` + publicIdentifier)
          .json<boolean>()
          .then((value) => value);
      } catch (error) {
        return Promise.reject(error);
      }
    }),
    checkAlreadySubmitted: flow(function* (email: string, moduleId: number, week: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`PublicStoryTime?email=${email}&moduleId=${moduleId}&week=${week}`)
          .json<IStoryTimeVideoPublicModel>()
          .then((value) => {
            return {
              isAlreadySubmitted: value && value.uploadCompletionDateTime,
              isAlreadyReviewed: value && value.markedCompleteByTrainerTimestamp,
            };
          });
      } catch (error) {
        console.log(`error GET PublicStoryTime error: ${error}`);
        return Promise.reject(error);
      }
    }),
    updateUpload: (value: number | null) => {
      self.uploadProgress = value;
    },
    getStoryTimeSubmissionsForWeek: flow(function* (moduleId: number | null, week: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`ProtectedStoryTime?moduleId=${moduleId ?? ''}&week=${week}`)
          .json<IStudentStoryTimeVideoModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error getStoryTimeSubmissionsForWeek ProtectedStoryTime?moduleId=${moduleId}&week=${week} error: ${error}`
        );
        return Promise.reject(error);
      }
    }),
    sendFeedback: flow(function* (
      feedback: string,
      storyTimeSubmissionId: number,
      outcome: string
    ) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`ProtectedStoryTime/feedback`, {
            json: {
              storyTimeSubmissionId: storyTimeSubmissionId,
              feedback: feedback,
              outcome: outcome,
            },
          })
          .json<boolean>()
          .then((value) => value);
      } catch (error) {
        console.log(`error ProtectedStoryTime/feedback error: ${error}`);
        return Promise.reject(error);
      }
    }),
  }))
  .actions((self) => ({
    uploadVideo: flow(function* (video: Blob, uploadLink: string) {
      self.uploadProgress = 0;
      let config = {
        onUploadProgress: (e: { loaded: number; total: number }) => {
          let number = Math.floor((e.loaded / e.total) * 100);
          LogService.info('StoryTimeModel - uploadVideo - onUploadProgress - ' + number);
          self.updateUpload(number);
        },
        headers: {
          'Content-Type': 'application/offset+octet-stream',
          'Tus-Resumable': '1.0.0',
          'Upload-Offset': '0',
          Accept: 'application/vnd.vimeo.*+json;version=3.4',
        },
      };
      return yield axios
        .patch(uploadLink, video, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          LogService.error('StoryTimeMode - uploadVideo - error: ' + JSON.stringify(error));
          return Promise.reject(error);
        });
    }),
  }))
  .views((self) => ({}));
