import React, { useCallback, useEffect, useState } from 'react';
import {
  Calendar,
  DateHeaderProps,
  DateLocalizer,
  momentLocalizer,
  SlotPropGetter,
} from 'react-big-calendar';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import styles from './BookingSystem.module.scss';
import './BookingSystemCalendarDefault.css';
import './BookingSystemCalendarOverrides.css';
import { useStore } from '../../hooks';
import { TimezoneSelector } from '../../components/TimezoneSelector/TimezoneSelector';
import { Backdrop, Chip, CircularProgress, useMediaQuery } from '@material-ui/core';
import {
  AcuityAppointmentBookingRequestV2,
  IAppointment,
  IntakeTutorialModel,
} from '../../../domain/store/BookingModel';
import { BookingSystemConfirmation } from './BookingSystemConfirmation';
import { lighten } from '@material-ui/core/styles';
import { ManageMyBookings, ManageMyBookingsChange } from './ManageMyBookings';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Moment } from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BookingSystemBookedSessionModal } from './BookingSystemBookedSessionModal';
import { CustomCalendarToolbar } from './Calendar/CustomCalendarToolbar';

export interface ISession {
  //react-big-calendar fields
  title: any;
  start: Date;
  end: Date;
  allDay?: boolean;
  resource?: any;

  //Acuity fields
  appointmentTypeId: number;
  appointmentTypeName: string;
  trainer: string;
  slots: number;
  slotsAvailable: number;
  color: string;
  waitListAvailable: boolean;
  weekAndEquipmentRegistrationRequired: boolean;

  //Augmented fields
  isBooked: boolean;
  isOnWaitlist: boolean;
  positionOnWaittlist: number | null;
}

function getFirstName(name: string) {
  return name.split(' ')[0];
}

function getForegroundColorForBackground(hexColor: string): string {
  // Remove the hash at the start if it's there
  hexColor = hexColor.replace(/^#/, '');

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the brightness (YIQ)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black or white depending on the brightness
  return yiq >= 128 ? '#000000' : '#ffffff';
}

export const BookingSystem: React.FC = function () {
  const { bookingModel, defaultUserTimezone } = useStore();

  const calendarRef = React.useRef<HTMLDivElement>(null);

  const [userTimezone, setUserTimezone] = useState<string>(defaultUserTimezone);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [scrollNextLoad, setScrollNextLoad] = React.useState(false);
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState<ISession[]>([]);
  const [selectedSession, setSelectedSession] = useState<ISession | null>(null);
  const [selectedAppointment, setSelectedAppointment] = useState<IAppointment | null>(null);
  const [upcomingAppointments, setUpcomingAppointments] = useState<IAppointment[]>([]);
  const [momentLocaliser, setMomentLocaliser] = useState<DateLocalizer | null>(null);
  const [refreshNumber, setRefreshNumber] = useState(0);

  const queryStringParams = new URLSearchParams(useLocation().search);

  //required params
  const email = queryStringParams.get('email');
  const firstName = queryStringParams.get('firstname');
  const lastName = queryStringParams.get('lastname');
  var categories = queryStringParams.get('categories');

  //optional params
  const isStartHereMode = queryStringParams.has('startHereMode');

  const isSmallerDisplay = useMediaQuery('(max-width: 768px)');

  function formatShortTime(date: Moment) {
    const hours = date.hours();
    const minutes = date.minutes();
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes ? `:${minutes.toString().padStart(2, '0')}` : '';
    return `${formattedHours}${formattedMinutes}${period}`;
  }

  useEffect(() => {
    if (categories === null) return;

    setSelectedSession(null);

    setShowSpinner(true);
    bookingModel
      .loadSessionsForCategory(categories, userTimezone, moment(date).toISOString())
      .then((sessions: IntakeTutorialModel[]) => {
        moment.tz.setDefault(userTimezone);
        const currentLocale = moment.locale();
        moment.updateLocale(currentLocale, {
          week: {
            dow: 1,
          },
        });
        setMomentLocaliser(momentLocalizer(moment));

        const events: (ISession | null)[] = sessions.map((session: IntakeTutorialModel) => {
          if (isStartHereMode && session.appointmentTypeID !== 65345334) {
            return null;
          }

          const start = new Date(
            moment.tz(session.time, userTimezone).format('YYYY-MM-DDTHH:mm:ssZ')
          );

          const matchingAppointment = upcomingAppointments.find((a) => {
            return (
              a.appointmentTypeID === session.appointmentTypeID &&
              moment(a.dateTime).tz(userTimezone).format() ===
                moment(start).tz(userTimezone).format()
            );
          });

          const isBooked = !!matchingAppointment;

          const event: ISession = {
            title: <> </>,
            start: start,
            end: new Date(start.getTime() + session.duration * 60000),
            appointmentTypeName: session.name!,
            trainer: session.calendar,
            slots: session.slots,
            slotsAvailable: session.slotsAvailable,
            color: session.slotsAvailable > 0 || session.waitlistAvailable ? session.color : 'grey',
            waitListAvailable: session.waitlistAvailable,
            weekAndEquipmentRegistrationRequired: session.weekAndEquipmentRegistrationRequired,
            appointmentTypeId: session.appointmentTypeID,
            isBooked: isBooked,
            isOnWaitlist: !!matchingAppointment?.isOnWaitlist,
            positionOnWaittlist: matchingAppointment?.positionOnWaitlistQueue ?? null,
          };

          let spotsLeftElement = <></>;

          const canBeBooked = getCanBeBooked(event);

          if (canBeBooked && !isBooked) {
            spotsLeftElement = (
              <Chip
                label={
                  event.slotsAvailable > 0
                    ? event.slotsAvailable +
                      (event.slotsAvailable > 1 ? ' Spots' : ' Spot') +
                      ' Left'
                    : event.waitListAvailable
                    ? 'Join Waitlist'
                    : 'Full'
                }
                size="small"
                className={styles.chip}
              />
            );
          } else if (isBooked && !event.isOnWaitlist) {
            spotsLeftElement = <Chip label={'Booked'} size="small" className={styles.chip} />;
          } else if (isBooked && event.isOnWaitlist) {
            spotsLeftElement = (
              <Chip
                label={<>#{event.positionOnWaittlist} on Waitlist</>}
                size="small"
                className={styles.chip}
              />
            );
          }

          let trainerElement = <span>| {getFirstName(event.trainer)}</span>;
          if (canBeBooked) {
            trainerElement = (
              <span>
                <i>|</i> <span>{getFirstName(event.trainer)}</span>
              </span>
            );
          }

          if (isSmallerDisplay)
            event.title = (
              <div>
                {event.appointmentTypeName} {trainerElement} {spotsLeftElement}
              </div>
            );
          else
            event.title = (
              <div>
                <span className={styles.bolder}>
                  {formatShortTime(moment(event.start).tz(userTimezone))}
                </span>{' '}
                | {event.appointmentTypeName} {trainerElement} {spotsLeftElement}
              </div>
            );

          return event;
        });

        const filteredEvents = events.filter((e) => e !== null) as ISession[];

        setEvents(filteredEvents);

        if (scrollNextLoad) {
          const calendarNode = calendarRef.current;
          if (calendarNode) {
            const dateCell = calendarNode.querySelector(
              `[data-date="${moment(date).format('YYYYMMDD')}"]`
            );
            if (dateCell) {
              dateCell.scrollIntoView({ behavior: 'smooth' });
            }
          }

          setScrollNextLoad(false);
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [
    bookingModel,
    date,
    userTimezone,
    categories,
    upcomingAppointments,
    isSmallerDisplay,
    scrollNextLoad,
    isStartHereMode,
  ]);

  useEffect(() => {
    let appointment: IAppointment | null = null;
    upcomingAppointments.forEach((a) => {
      const isBooked =
        a.appointmentTypeID === selectedSession?.appointmentTypeId &&
        moment(a.dateTime).tz(userTimezone).format() ===
          moment(selectedSession?.start).tz(userTimezone).format();
      if (isBooked) {
        appointment = a;
      }
    });

    setSelectedAppointment(appointment);
  }, [selectedSession, upcomingAppointments, userTimezone]);

  const handleUserTimeZoneChange = (newValue: string) => {
    if (newValue !== userTimezone) {
      setUserTimezone(newValue);
    }
  };

  const handleOnSelectEvent = (event: ISession) => {
    let appointment: IAppointment | null = null;
    upcomingAppointments.forEach((a) => {
      const isBooked =
        a.appointmentTypeID === event?.appointmentTypeId &&
        moment(a.dateTime).tz(userTimezone).format() ===
          moment(event?.start).tz(userTimezone).format();
      if (isBooked) {
        appointment = a;
      }
    });

    const canJoin = isInJoinWindow(event);

    if (getCanBeBooked(event) || (appointment && canJoin)) setSelectedSession(event);
  };

  const handleMakeBooking = useCallback(
    (week: string, equipment: string) => {
      setShowSpinner(true);

      if (selectedSession == null || firstName == null || lastName == null || email == null) return;

      const request: AcuityAppointmentBookingRequestV2 = {
        appointmentTypeID: selectedSession.appointmentTypeId,
        datetime: selectedSession!.start,
        calendar: selectedSession!.trainer,
        firstName: firstName,
        lastName: lastName,
        email: email,
        timezone: userTimezone,
        preRegisterQuestions: {
          week: week?.toString(),
          equipment: equipment,
        },
        isJoiningWaitlist:
          selectedSession.waitListAvailable && selectedSession.slotsAvailable === 0,
      };

      bookingModel
        .postIntakeBookAppointmentV2(request)
        .then(() => {
          setRefreshNumber(refreshNumber + 1);
        })
        .catch(() => {
          alert(
            'Your booking may not have been successful. Please refresh and check the My Bookings section. If your booking does not appear, try again. For further assistance, contact support@breathe-education.com'
          );
        })
        .finally(() => {
          setShowSpinner(false);
          setSelectedSession(null);
          setSelectedAppointment(null);
        });
    },
    [bookingModel, selectedSession, firstName, lastName, email, userTimezone, refreshNumber]
  );

  function getCanBeBooked(event: ISession) {
    let isInFuture = event.start.getTime() > new Date().getTime();
    return isInFuture && (event.slotsAvailable > 0 || event.waitListAvailable);
  }

  function isInJoinWindow(event: ISession) {
    const notEnded = event.end.getTime() > new Date().getTime();
    const withinJoinWindow = event.start.getTime() - new Date().getTime() <= 10 * 60 * 1000; // 10 minutes in milliseconds
    return notEnded && withinJoinWindow;
  }

  const handleEventPropGetter = useCallback(
    (event: ISession) => {
      const isInFuture = getCanBeBooked(event);

      const isBooked = upcomingAppointments.some((a) => {
        return (
          a.appointmentTypeID === event.appointmentTypeId &&
          moment(a.dateTime).tz(userTimezone).format() ===
            moment(event.start).tz(userTimezone).format()
        );
      });

      const inJoinWindow = isInJoinWindow(event);

      const eventColor = event.color;
      const lightenedEventColor = lighten(event.color, 0.33);
      const white = '#ffffff';
      const grey = '#e1e1e1';

      const isCurrentMonth = moment(date).month() === moment(event.start).month();

      const eventBackgroundColor = isBooked
        ? isInFuture || inJoinWindow
          ? eventColor
          : lightenedEventColor
        : isInFuture
        ? white
        : grey;
      const eventColorForeground = getForegroundColorForBackground(eventBackgroundColor);
      const borderColor = !isInFuture ? lightenedEventColor : eventColor;

      return {
        style: {
          backgroundColor: eventBackgroundColor,
          border: '5px solid',
          borderColor: borderColor,
          color: eventColorForeground,
          cursor: isInFuture || (isBooked && inJoinWindow) ? 'pointer' : 'not-allowed',
          display: isCurrentMonth ? 'block' : 'none',
        },
      };
    },
    [upcomingAppointments, userTimezone, date]
  );

  const handleManageBookingChange = useCallback(
    (change: ManageMyBookingsChange) => {
      const timeBufferMinutes = 120;

      let upComingAppointments = change.appointments.filter(
        (a) => moment(a.dateTime).add(a.duration + timeBufferMinutes, 'minutes') >= moment()
      );

      setUpcomingAppointments(upComingAppointments);
    },
    [setUpcomingAppointments]
  );

  if (!email || !firstName || !lastName || !categories) {
    return <div>Invalid URL configuration - missing parameters.</div>;
  }

  const customSlotsPropGetter: SlotPropGetter = () => {
    return {
      style: {
        minHeight: '1.75rem',
      },
    };
  };

  const monthDateHeader = ({ date, label }: DateHeaderProps) => {
    return (
      <div data-date={moment(date).format('YYYYMMDD')}>
        <div>{label}</div>
      </div>
    );
  };

  return (
    <div className={`${styles.container} ${isSmallerDisplay ? styles.mobile : ''}`}>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.timezoneSection}>
        <TimezoneSelector onChange={handleUserTimeZoneChange} />
      </div>

      <div className={styles.manageBookingSection}>
        <Accordion className={styles.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
            My Bookings{' '}
            <Chip size="small" label={upcomingAppointments.length} className={styles.manageChip} />
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <ManageMyBookings
              categories={categories}
              email={email}
              userTimezone={userTimezone}
              lastName={lastName}
              firstName={firstName}
              refreshRandomNumber={refreshNumber}
              onChange={handleManageBookingChange}
              isStartHereMode={isStartHereMode}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      {events && events.length > 0 && momentLocaliser && (
        <div className={styles.calendarContainer} ref={calendarRef}>
          <Calendar
            localizer={momentLocaliser}
            components={{
              toolbar: CustomCalendarToolbar,
              month: {
                dateHeader: monthDateHeader,
              },
            }}
            onNavigate={(date, view, action) => {
              setDate(date);
              if (action === 'DATE') {
                setScrollNextLoad(true);
              }
            }}
            events={events}
            startAccessor="start"
            endAccessor={isSmallerDisplay ? 'end' : 'start'}
            style={{ height: 'auto', display: '' }}
            drilldownView={null}
            views={['month', 'day']}
            view={isSmallerDisplay ? 'day' : 'month'}
            onView={(view) => {}}
            toolbar={true}
            showAllEvents={true}
            eventPropGetter={handleEventPropGetter}
            tooltipAccessor={null}
            onSelectEvent={handleOnSelectEvent}
            dayLayoutAlgorithm={'no-overlap'}
            slotPropGetter={customSlotsPropGetter}
            // dayPropGetter={dayPropGetter} doesn't work in monthly view
          />
        </div>
      )}

      {selectedSession && !selectedAppointment && (
        <BookingSystemConfirmation
          selectedSession={selectedSession}
          onMakeBooking={handleMakeBooking}
          onModalClose={() => setSelectedSession(null)}
          isStartHereMode={isStartHereMode}
        />
      )}

      {selectedSession && selectedAppointment && (
        <BookingSystemBookedSessionModal
          selectedSession={selectedSession}
          onCancel={() => {
            setRefreshNumber(refreshNumber + 1);
          }}
          selectedAppointment={selectedAppointment}
          userTimezone={userTimezone}
          onModalClose={() => setSelectedSession(null)}
          onUpdate={() => {
            setRefreshNumber(refreshNumber + 1);
          }}
          isStartHereMode={isStartHereMode}
        />
      )}
    </div>
  );
};
