import React, { FC, useEffect } from 'react';
import styles from './BookingSystem.module.scss';
import { useStore } from '../../hooks';
import { IAppointment } from '../../../domain/store/BookingModel';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Tab,
  Tabs,
} from '@material-ui/core';
import { StudentClassType } from '../../public/StudentClasses/StudentClassType';
import { ClassList, IClassListPeriod } from '../../public/StudentClasses/Manage/ClassList';
import moment from 'moment/moment';
import { Clear } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';

export interface ManageMyBookingsChange {
  appointments: IAppointment[];
}

interface ManageMyBookingsProps {
  categories: string;
  email: string;
  userTimezone: string;
  firstName: string;
  lastName: string;
  onChange: (change: ManageMyBookingsChange) => void;
  refreshRandomNumber: number;
  isStartHereMode: boolean;
}

export const ManageMyBookings: FC<ManageMyBookingsProps> = function ({
  categories,
  email,
  userTimezone,
  firstName,
  lastName,
  onChange,
  refreshRandomNumber,
  isStartHereMode,
}) {
  const pageSize = 11;
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  const { bookingModel } = useStore();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [appointments, setAppointments] = React.useState<IAppointment[]>([]);

  const [tabValue, setTabValue] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [openChangeModal, setOpenChangeModal] = React.useState(false);

  const [
    selectedAppointmentToCancel,
    setSelectedAppointmentToCancel,
  ] = React.useState<IAppointment>();
  const [
    selectedAppointmentToChange,
    setSelectedAppointmentToChange,
  ] = React.useState<IAppointment>();

  const [
    updateRegistrationQuestionWeek,
    setUpdateRegistrationQuestionWeek,
  ] = React.useState<string>('');
  const [
    updateRegistrationQuestionEquipment,
    setUpdateRegistrationQuestionEquipment,
  ] = React.useState<string>('');

  const [showSuccessMessage, setShowSuccessMessage] = React.useState<string | null>(null);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [showCustomErrorMessage, setCustomShowErrorMessage] = React.useState(false);
  const [customErrorMessage, setCustomErrorMessage] = React.useState(
    'Uh oh! Something went wrong. Please try again later.'
  );

  useEffect(() => {
    setShowSpinner(true);
    bookingModel
      .loadStudentBookings(categories, email)
      .then((appointments: IAppointment[]) => {
        appointments = appointments.sort((a, b) => {
          return moment(a.dateTime).diff(moment(b.dateTime));
        });
        setAppointments(appointments);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [bookingModel, refreshRandomNumber, categories, email]);

  useEffect(() => {
    onChange({ appointments });
  }, [appointments, categories, email, onChange]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const cancelAppointment = (appointmentId: string) => {
    setOpenCancelDialog(false);
    setShowSpinner(true);

    bookingModel.cancelAppointment(appointmentId).then(
      () => {
        setAppointments(appointments.filter((i) => i.id !== selectedAppointmentToCancel?.id));
        setShowSpinner(false);
        setShowSuccessMessage('Your booking has been cancelled.');
      },
      (error) => {
        console.log(error);
        setShowSpinner(false);
        setShowErrorMessage(true);
      }
    );
  };

  const leaveAppointment = (appointmentId: string) => {
    setOpenCancelDialog(false);
    setShowSpinner(true);

    bookingModel
      .cancelSelfSignupPracticeTeachingSession(
        appointmentId,
        email,
        firstName,
        lastName,
        userTimezone
      )
      .then(
        () => {
          setAppointments(appointments.filter((i) => i.id !== selectedAppointmentToCancel?.id));
          setShowSpinner(false);
          setShowSuccessMessage('Your booking has been cancelled.');
        },
        (error) => {
          console.log(error);
          setShowSpinner(false);
          setShowErrorMessage(true);
        }
      );
  };

  const updateAppointment = () => {
    if (!selectedAppointmentToChange) return;

    setShowSpinner(true);
    setOpenChangeModal(false);

    var updatedAppointment = _.cloneDeep(selectedAppointmentToChange);

    //update custom questions

    var weekQuestion = updatedAppointment.customQuestions?.find((q) => q.title === 'Week');
    var equipmentQuestion = updatedAppointment.customQuestions?.find(
      (q) => q.title === 'Equipment'
    );

    if (weekQuestion && updateRegistrationQuestionWeek)
      weekQuestion.value = updateRegistrationQuestionWeek.toString() ?? weekQuestion.value;
    if (equipmentQuestion && updateRegistrationQuestionEquipment)
      equipmentQuestion.value = updateRegistrationQuestionEquipment ?? equipmentQuestion.value;

    console.log('Week:', weekQuestion?.value);
    console.log('Equipment:', equipmentQuestion?.value);

    bookingModel
      .updateAppointment(updatedAppointment)
      .then(
        (updatedAppointment) => {
          if (updatedAppointment) {
            setAppointments(
              appointments.map((a) =>
                a.id === selectedAppointmentToChange.id ? updatedAppointment : a
              )
            );
            setShowSuccessMessage('Your booking has been updated.');
          } else {
            setCustomErrorMessage('Update failed. Zoom only allows 3 updates per day.');
            setCustomShowErrorMessage(true);
          }
        },
        (error) => {
          console.log(error);
          setCustomErrorMessage(error);
          setCustomShowErrorMessage(true);
        }
      )
      .finally(() => {
        setOpenChangeModal(false);
        setShowSpinner(false);
        setUpdateRegistrationQuestionWeek('');
        setUpdateRegistrationQuestionEquipment('');
      });
  };

  return (
    <>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.container}>
        <div className={styles.paperContainer}>
          <Box>
            <Box>
              <List className={styles.classList}>
                <Paper square>
                  <Tabs
                    className={styles.tabs}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="upcoming and past">
                    <Tab label="Upcoming"></Tab>
                    <Tab label="Past"></Tab>
                  </Tabs>
                </Paper>
                {Object.values(IClassListPeriod).map((period, i) => (
                  <TabPanel key={i} value={tabValue} index={period}>
                    <ClassList
                      appointments={appointments}
                      currentPage={page}
                      onAppointmentSelect={(appointment: IAppointment) => {
                        // setSelectedAppointment(appointment);
                        // setOpenDetailsModal(true);
                      }}
                      onAppointmentCancel={(appointment: IAppointment) => {
                        setSelectedAppointmentToCancel(appointment);
                        setOpenCancelDialog(true);
                      }}
                      onAppointmentChange={(appointment: IAppointment) => {
                        setSelectedAppointmentToChange(appointment);
                        setOpenChangeModal(true);
                      }}
                      onPageChange={handlePageChange}
                      userTimezone={userTimezone}
                      period={(period as unknown) as IClassListPeriod}
                      pageSize={pageSize}
                      urlRegex={urlRegex}
                      classType={StudentClassType.GenericClass}
                      email={email}
                    />
                  </TabPanel>
                ))}
              </List>
            </Box>
          </Box>
        </div>
        <Dialog
          open={openCancelDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {selectedAppointmentToCancel?.isOnWaitlist && (
              <>{`Are you sure you want to remove yourself from the waitlist for this session?`}</>
            )}
            {!selectedAppointmentToCancel?.isOnWaitlist && (
              <>{`Are you sure you want to cancel this booking?`}</>
            )}
          </DialogTitle>
          <DialogContent>
            <div>
              <p>
                <span className={styles.detailsLable}>Session:</span>{' '}
                {selectedAppointmentToCancel?.type}
              </p>
              <p>
                <span className={styles.detailsLable}>Trainer: </span>{' '}
                {selectedAppointmentToCancel?.calendar}
              </p>
              <p>
                <span className={styles.detailsLable}>Time: </span>
                {moment(selectedAppointmentToCancel?.dateTime)
                  .tz(userTimezone)
                  .format('dddd, MMMM Do YYYY h:mm a')}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className={styles.confirmCancelButton}
              color="primary"
              autoFocus
              onClick={() => setOpenCancelDialog(false)}>
              Back
            </Button>
            <Button
              color="primary"
              className={styles.confirmCancelButton}
              onClick={() => {
                if (selectedAppointmentToCancel) {
                  if (selectedAppointmentToCancel?.email === email) {
                    cancelAppointment(
                      selectedAppointmentToCancel.id
                        ? selectedAppointmentToCancel.id.toString()
                        : 'WaitlistId:' + selectedAppointmentToCancel.waitlistId
                    );
                  } else {
                    leaveAppointment(selectedAppointmentToCancel.id.toString());
                  }
                }
              }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          className={styles.modal}
          open={openChangeModal}
          onClose={() => {
            setOpenChangeModal(false);
            setUpdateRegistrationQuestionWeek('');
            setUpdateRegistrationQuestionEquipment('');
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={openChangeModal}>
            <div className={styles.paper}>
              <Box width="10%" ml="auto">
                <IconButton aria-label="close" onClick={() => setOpenChangeModal(false)}>
                  <Clear />
                </IconButton>
              </Box>
              <Box mb={2} ml={2} mr={2}>
                <h2 className={styles.paragraphHeading}>Change booking details</h2>

                <FormControl component="fieldset">
                  {selectedAppointmentToChange?.customQuestions?.find(
                    (q) => q.title === 'Week'
                  ) && (
                    <FormGroup className={styles.formControl}>
                      <FormControlLabel
                        control={<span></span>}
                        label={"Select the week you'll be teaching this session"}
                      />
                      <FormControl>
                        <Select
                          className={styles.select}
                          variant="outlined"
                          fullWidth={true}
                          value={
                            updateRegistrationQuestionWeek ||
                            selectedAppointmentToChange?.customQuestions?.find(
                              (q) => q.title === 'Week'
                            )?.value
                          }
                          displayEmpty
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setUpdateRegistrationQuestionWeek(event.target.value as string);
                          }}>
                          <MenuItem value="" disabled>
                            <span className={styles.inputLabel}>
                              {' '}
                              Select the week you'll be teaching this session
                            </span>
                          </MenuItem>

                          <MenuItem value="0">Warm Up Cluster</MenuItem>

                          {!isStartHereMode &&
                            Array.from({ length: 20 }, (_, i) => i + 1).map((i) => (
                              <MenuItem key={i} value={i}>
                                Week {i}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  )}

                  {selectedAppointmentToChange?.customQuestions?.find(
                    (q) => q.title === 'Equipment'
                  ) && (
                    <FormGroup className={styles.formControl}>
                      <FormControlLabel
                        control={<span></span>}
                        label={'Select the equipment you will have available this session'}
                      />
                      <FormControl>
                        <Select
                          className={styles.select}
                          variant="outlined"
                          value={
                            updateRegistrationQuestionEquipment ||
                            selectedAppointmentToChange?.customQuestions?.find(
                              (q) => q.title === 'Equipment'
                            )?.value
                          }
                          displayEmpty
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setUpdateRegistrationQuestionEquipment(event.target.value as string);
                          }}>
                          <MenuItem value="" disabled>
                            <span className={styles.inputLabel}>
                              {' '}
                              Select the equipment you will have available this session
                            </span>
                          </MenuItem>

                          <MenuItem value={'Mat'}>Mat only</MenuItem>
                          <MenuItem value={'Reformer'}>Reformer only</MenuItem>
                          <MenuItem value={'Mat/Reformer'}>Mat & Reformer</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  )}
                </FormControl>

                <div>
                  <Button
                    className={styles.confirmCancelButton}
                    color="primary"
                    autoFocus
                    onClick={() => {
                      setOpenChangeModal(false);
                      setUpdateRegistrationQuestionWeek('');
                      setUpdateRegistrationQuestionEquipment('');
                    }}>
                    Back
                  </Button>
                  <Button
                    color="primary"
                    disabled={
                      (!updateRegistrationQuestionWeek ||
                        updateRegistrationQuestionWeek ===
                          selectedAppointmentToChange?.customQuestions?.find(
                            (q) => q.title === 'Week'
                          )?.value) &&
                      (!updateRegistrationQuestionEquipment ||
                        updateRegistrationQuestionEquipment ===
                          selectedAppointmentToChange?.customQuestions?.find(
                            (q) => q.title === 'Equipment'
                          )?.value)
                    }
                    className={styles.confirmCancelButton}
                    onClick={() => updateAppointment()}>
                    Update
                  </Button>
                </div>
              </Box>
            </div>
          </Fade>
        </Modal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSuccessMessage != null}
          onClose={() => setShowSuccessMessage(null)}
          autoHideDuration={5000}>
          <Alert severity="success">{showSuccessMessage}</Alert>
        </Snackbar>
        <Snackbar
          open={showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}>
          <Alert severity="error">{` uh oh! There has been an error.`}</Alert>
        </Snackbar>
        <Snackbar
          open={showCustomErrorMessage}
          onClose={() => setCustomShowErrorMessage(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}>
          <Alert severity="error">{customErrorMessage}</Alert>
        </Snackbar>
      </div>
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}
