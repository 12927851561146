import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Dashboard } from './dashboard/Dashboard';
import { NotFound } from './notFound/NotFound';
import { AuthenticatedShell } from 'views/components/AuthenticatedShell';
import { ErrorBoundary } from 'views/components/ErrorBoundary';
import { IntakesList } from './intakes/IntakesList';
import { IntakesAdd } from './intakes/IntakesAdd';
import { PublicSchedule } from '../public/PublicSchedules/PublicSchedule';
import { PublicShell } from 'views/components/PublicShell';
import { IAuthenticationProvider } from '../../auth/AuthenticationTypes';
import { useAuthentication } from 'hooks/useAuthentication';
import { useStore } from '../hooks';
import Button from '@material-ui/core/Button';
import { StudentSchedule } from '../public/StudentSchedule';
import { DiplomaModuleList } from './diploma/ModuleList';
import { ModuleAddEdit } from './diploma/ModuleAddEdit';
import { BookClass } from '../public/StudentClasses/Book/BookClass';
import { ManageClass } from '../public/StudentClasses/Manage/ManageClass';
import { StudentClassType } from 'views/public/StudentClasses/StudentClassType';
import { TotaraLinks } from './totaraLinks/TotaraLinks';
import { TutorialAttendance } from './tutorialAttendance/TutorialAttendance';
import { TutorialMakeUpBooking } from 'views/public/StudentClasses/AttendanceMakeUpBooking/TutorialMakeUpBooking';
import { StudentList } from './studentList/StudentList';
import { LearningSchedule } from './learningSchedule/learningSchedule';
import { StoryTimeUpload } from 'views/public/StoryTime/StoryTimeUpload';
import { StoryTimeList } from './storyTime/StoryTimeList';
import { ReportsList } from './reports/ReportsList';
import { LogbookIntakeSummaryReport } from './reports/LogbookIntakeSummaryReport';
import { Student } from './student/Student';
import { StudentLogbookManage } from '../public/Logbook/StudentLogbookManage';
import { StudentGradebook } from '../public/Gradebook/StudentGradebook';
import { CertificateCommitmentStatement } from '../public/Forms/CommitmentStatement/Certificate/CertificateCommitmentStatement';
import { Settings } from './settings/Settings';
import { Competencies } from './competencies/Competencies';
import { BookingSystem } from './bookings/BookingSystem';
import { TermsOfServiceForm } from '../public/Forms/TermsOfServiceForm/TermsOfServiceForm';

export const INTAKES_LIST_PATH = '/admin/intakes';
export const INTAKE_ADD_PATH = '/admin/intakes/add';
export const INTAKE_EDIT_PATH = '/admin/intakes/edit/:id';
export const DIPLOMA_MODULES_LIST = '/admin/diploma/module';
export const DIPLOMA_MODULE_EDIT = '/admin/diploma/module/:id';
export const DIPLOMA_MODULE_ADD = '/admin/diploma/module';
export const TOTARALINKS_LIST = '/admin/totaralinks';
export const LEARNINGSCHEDULE = '/admin/learningschedule';
export const TUTORIALATTENDANCE_LIST = '/admin/tutorialattendance';
export const STUDENT_LIST = '/admin/studentlist';
export const COMPETENCIES = '/admin/studentcompetencies';
export const STORY_TIME = '/admin/storytime';
export const REPORTS_LIST = '/admin/reports';
export const STUDENT = '/admin/student/:email';
export const SETTINGS = '/admin/settings';

interface IProps {
  auth: IAuthenticationProvider;
}

export const AuthenticatedRoutes: React.FC<IProps> = function ({ auth }) {
  const location = useLocation();
  const authState = useAuthentication(auth);
  const store = useStore();

  if (authState === 'signed-in' && store.state === 'unauthorized') {
    return <p>Unauthorised</p>;
  }

  if (authState === 'loading') {
    return <p>Loading, please wait...</p>;
  }

  if (authState === 'signed-in') {
    return (
      <AuthenticatedShell>
        <ErrorBoundary key={location.pathname}>
          <Switch>
            <Route exact path="/admin/">
              <Dashboard />
            </Route>
            <Route exact path={INTAKES_LIST_PATH}>
              <IntakesList />
            </Route>
            <Route exact path={INTAKE_ADD_PATH}>
              <IntakesAdd />
            </Route>
            <Route exact path={INTAKE_EDIT_PATH}>
              <IntakesAdd />
            </Route>
            <Route exact path={DIPLOMA_MODULES_LIST}>
              <DiplomaModuleList />
            </Route>
            <Route exact path={DIPLOMA_MODULE_ADD}>
              <ModuleAddEdit />
            </Route>
            <Route exact path={DIPLOMA_MODULE_EDIT}>
              <ModuleAddEdit />
            </Route>
            <Route exact path={TOTARALINKS_LIST}>
              <TotaraLinks />
            </Route>
            <Route exact path={TUTORIALATTENDANCE_LIST}>
              <TutorialAttendance />
            </Route>
            <Route exact path={STUDENT_LIST}>
              <StudentList />
            </Route>
            <Route exact path={COMPETENCIES}>
              <Competencies />
            </Route>
            <Route exact path={STUDENT}>
              <Student />
            </Route>
            <Route exact path={STORY_TIME}>
              <StoryTimeList />
            </Route>
            <Route exact path={LEARNINGSCHEDULE}>
              <LearningSchedule />
            </Route>
            <Route exact path={REPORTS_LIST}>
              <ReportsList />
            </Route>
            <Route exact path={SETTINGS}>
              <Settings />
            </Route>
            <Route exact path={'/admin/reports/intakeLogbookSummary'}>
              <div>
                <LogbookIntakeSummaryReport />
              </div>
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </ErrorBoundary>
      </AuthenticatedShell>
    );
  }

  return (
    <p>
      Please <Button onClick={() => auth.signIn}>sign in</Button>
    </p>
  );
};

export const PublicRoutes: React.FC = function () {
  const location = useLocation();

  return (
    <PublicShell>
      <ErrorBoundary key={location.pathname}>
        <Switch>
          <Route exact path="/public/intake/schedule/:id?">
            <PublicSchedule />
          </Route>
          <Route exact path="/public/intake/schedule/student/:id/:module?">
            <StudentSchedule />
          </Route>
          <Route exact path="/public/intake/tutorials/students/book/:categories">
            <BookClass classType={StudentClassType.Tutorial} />
          </Route>
          <Route exact path="/public/intake/tutorials/students/manage/:categories">
            <ManageClass classType={StudentClassType.Tutorial} />
          </Route>
          <Route exact path="/public/intake/masterclasses/students/book/:categories">
            <BookClass classType={StudentClassType.Masterclass} />
          </Route>
          <Route exact path="/public/intake/class/students/book/:categories">
            <BookClass classType={StudentClassType.GenericClass} />
          </Route>
          <Route exact path="/public/intake/room/students/book/:categories">
            <BookClass classType={StudentClassType.GenericRoom} />
          </Route>
          <Route exact path="/public/intake/masterclasses/students/manage/:categories">
            <ManageClass classType={StudentClassType.Masterclass} />
          </Route>
          <Route exact path="/public/intake/class/students/manage/:categories">
            <ManageClass classType={StudentClassType.GenericClass} />
          </Route>
          <Route exact path="/public/intake/room/students/manage/:categories">
            <ManageClass classType={StudentClassType.GenericRoom} />
          </Route>
          <Route exact path="/public/intake/pt/students/book/:categories">
            <BookClass classType={StudentClassType.PracticeTeaching} />
          </Route>
          <Route exact path="/public/intake/pt/students/manage/:categories">
            <ManageClass classType={StudentClassType.PracticeTeaching} />
          </Route>
          <Route exact path="/public/tutorial/attendance/makeup/book/:publicId">
            <TutorialMakeUpBooking />
          </Route>
          <Route exact path={'/public/student/bookings'}>
            <BookingSystem />
          </Route>
          <Route exact path="/public/student/story-time/:moduleParam/:weekParam">
            <StoryTimeUpload />
          </Route>
          <Route exact path="/public/student/logbook/:courseTypeParam">
            <StudentLogbookManage />
          </Route>
          <Route exact path="/public/student/gradebook/:courseTypeParam">
            <StudentGradebook />
          </Route>
          <Route exact path="/public/student/forms/certificatecommitmentstatement">
            <CertificateCommitmentStatement />
          </Route>
          <Route exact path="/public/terms-of-service">
            <TermsOfServiceForm />
          </Route>
        </Switch>
      </ErrorBoundary>
    </PublicShell>
  );
};
