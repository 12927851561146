import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import {
  ICourseCompetencyModel,
  ICourseCompetencyTemplateModel,
  ICourseModel,
} from '../../../domain/store/StudentStore';
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import styles from './Settings.module.scss';
import { Clear } from '@material-ui/icons';
import _ from 'lodash';

export const CourseCompetencies = () => {
  const initialCompetencyState: ICourseCompetencyModel = {
    id: 0,
    competencyTitle: '',
    competencyDetails: '',
    lmsCourseId: 0,
    lms: '',
    competencyType: '',
    countTypeRequirement: null,
    displayOrder: 0,
    group1: null,
    templates: [] as ICourseCompetencyTemplateModel[],
  };

  const { studentStore } = useStore();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showTemplatesModal, setShowTemplatesModal] = React.useState(false);
  const [courses, setCourses] = useState<ICourseModel[]>([]);
  const [courseToEditId, setCourseToEditId] = useState<number | null>(null);
  const [courseToEdit, setCourseToEdit] = useState<ICourseModel | null>(null);
  const [currentCourseCompetencies, setCurrentCourseCompetencies] = useState<
    ICourseCompetencyModel[]
  >([]);
  const [newCompetency, setNewCompetency] = useState<ICourseCompetencyModel>(
    initialCompetencyState
  );
  const [editCompetency, setEditCompetency] = useState<ICourseCompetencyModel | null>(null);

  const initialTemplateState = {
    id: 0,
    title: '',
    type: '',
    emailSubject: '',
    emailContent: '',
    createdBy: null,
    createdOn: null,
    deletedBy: null,
    deletedOn: null,
  };
  const [newTemplate, setNewTemplate] = useState<ICourseCompetencyTemplateModel>(
    initialTemplateState
  );

  useEffect(() => {
    setShowSpinner(true);
    studentStore
      .getCourses()
      .then((courses: ICourseModel[]) => {
        setCourses(courses);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [studentStore]);

  useEffect(() => {
    setCurrentCourseCompetencies([]);
    if (courseToEditId != null) {
      setCourseToEdit(courses.find((course: ICourseModel) => course.id === courseToEditId) ?? null);

      setShowSpinner(true);

      studentStore
        .getCompetenciesForCourse(courseToEditId)
        .then((competencies: ICourseCompetencyModel[]) => {
          competencies.sort((a, b) => a.displayOrder - b.displayOrder);
          setCurrentCourseCompetencies(competencies);
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  }, [studentStore, courses, courseToEditId]);

  const addNewCompetency = async () => {
    newCompetency.lmsCourseId = courseToEdit?.id ?? 0;
    newCompetency.lms = 'Thinkific';
    if (newCompetency.competencyType === 'Checkbox') {
      newCompetency.countTypeRequirement = null;
    }

    const maxDisplayOrder = currentCourseCompetencies.reduce(
      (max, competency) => Math.max(max, competency.displayOrder ?? 0),
      0
    );

    newCompetency.displayOrder = maxDisplayOrder + 1;
    setShowSpinner(true);

    studentStore
      .upsertCourseCompetency(newCompetency)
      .then((savedCompetency: ICourseCompetencyModel) => {
        setCurrentCourseCompetencies([...currentCourseCompetencies, savedCompetency]);
        setNewCompetency(initialCompetencyState);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const editMode = (competency: ICourseCompetencyModel) => {
    setEditCompetency(competency);
  };

  const cancelEdit = () => {
    setEditCompetency(null);
  };

  const deleteCompetency = async (competencyId: number) => {
    try {
      setShowSpinner(true);
      const success = await studentStore.deleteCourseCompetency(competencyId);
      if (success)
        setCurrentCourseCompetencies(
          currentCourseCompetencies.filter(
            (competency: ICourseCompetencyModel) => competency.id !== competencyId
          )
        );
    } catch (error) {
      console.error(error);
    } finally {
      setShowSpinner(false);
    }
  };

  const updateCompetency = async (updatedCompetency: ICourseCompetencyModel) => {
    try {
      setShowSpinner(true);

      await studentStore
        .upsertCourseCompetency(updatedCompetency)
        .then((savedCompetency: ICourseCompetencyModel) => {
          if (savedCompetency) {
            const updatedCompetencies = currentCourseCompetencies.map(
              (competency: ICourseCompetencyModel) =>
                competency.id === updatedCompetency.id ? updatedCompetency : competency
            );

            updatedCompetencies.sort((a, b) => a.displayOrder - b.displayOrder);
            setCurrentCourseCompetencies(updatedCompetencies);
            setEditCompetency(null);
          }
        })
        .finally(() => {
          setShowSpinner(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMoveUp = async (competencyId: number) => {
    const competency = currentCourseCompetencies.find((c) => c.id === competencyId);
    if (!competency) {
      return;
    }

    setShowSpinner(true);

    setEditCompetency(null);

    const index = currentCourseCompetencies.indexOf(competency);

    const previousCompetency = currentCourseCompetencies[index - 1];
    const temp = previousCompetency.displayOrder;
    previousCompetency.displayOrder = competency.displayOrder;
    competency.displayOrder = temp;

    studentStore
      .upsertCourseCompetency(competency)
      .then((c) => {
        return studentStore.upsertCourseCompetency(previousCompetency).then((pc) => {
          return studentStore
            .getCompetenciesForCourse(courseToEditId!)
            .then((competencies: ICourseCompetencyModel[]) => {
              competencies.sort((a, b) => a.displayOrder - b.displayOrder);
              setCurrentCourseCompetencies(competencies);
            });
        });
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const handleMoveDown = (id: number) => {
    const competency = currentCourseCompetencies.find((c) => c.id === id);
    if (!competency) {
      return;
    }

    setShowSpinner(true);

    setEditCompetency(null);

    const index = currentCourseCompetencies.indexOf(competency);

    const nextCompetency = currentCourseCompetencies[index + 1];
    const temp = nextCompetency.displayOrder;
    nextCompetency.displayOrder = competency.displayOrder;
    competency.displayOrder = temp;

    studentStore
      .upsertCourseCompetency(competency)
      .then((c) => {
        return studentStore.upsertCourseCompetency(nextCompetency).then((nc) => {
          return studentStore
            .getCompetenciesForCourse(courseToEditId!)
            .then((competencies: ICourseCompetencyModel[]) => {
              competencies.sort((a, b) => a.displayOrder - b.displayOrder);
              setCurrentCourseCompetencies(competencies);
            });
        });
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const addNewTemplate = () => {
    setEditCompetency({
      ...editCompetency!,
      templates: [...editCompetency!.templates, newTemplate],
    });
    setNewTemplate(initialTemplateState);
  };

  const removeTemplate = (template: ICourseCompetencyTemplateModel) => {
    setEditCompetency({
      ...editCompetency!,
      templates: editCompetency!.templates.filter(
        (t: ICourseCompetencyTemplateModel) => t !== template
      ),
    });
  };

  return (
    <div className={styles.container}>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      Select course to edit: &nbsp;
      <select
        value={courseToEditId?.toString() ?? ''}
        onChange={(e) => setCourseToEditId(parseInt(e.target.value))}>
        <option value="" disabled={true}>
          Select
        </option>
        {courses.map((course: ICourseModel, i: number) => {
          return (
            <option key={i} value={course.id}>
              {course.name}
            </option>
          );
        })}
      </select>
      <br />
      <br />
      <div>
        {courseToEditId && (
          <div>
            <h3>{courseToEdit?.name}</h3>

            <TableContainer component={Paper}>
              <Table className={styles.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentCourseCompetencies.map(
                    (competency: ICourseCompetencyModel, i: number) => {
                      return (
                        <TableRow key={i}>
                          <>
                            {editCompetency?.id === competency.id ? (
                              <>
                                <TableCell component="th" scope={'row'}>
                                  <input
                                    type="text"
                                    value={editCompetency.competencyTitle ?? ''}
                                    onChange={(e) =>
                                      setEditCompetency({
                                        ...editCompetency,
                                        competencyTitle: e.target.value,
                                      })
                                    }
                                  />
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  <textarea
                                    value={editCompetency.competencyDetails ?? ''}
                                    onChange={(e) =>
                                      setEditCompetency({
                                        ...editCompetency,
                                        competencyDetails: e.target.value,
                                      })
                                    }
                                  />
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  {editCompetency.competencyType}

                                  {editCompetency.competencyType === 'Count' && (
                                    <>
                                      <input
                                        type="number"
                                        value={editCompetency?.countTypeRequirement ?? -1}
                                        onChange={(e) =>
                                          setEditCompetency({
                                            ...editCompetency,
                                            countTypeRequirement: parseInt(e.target.value),
                                          })
                                        }
                                      />
                                    </>
                                  )}
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  <input
                                    type="text"
                                    value={editCompetency.group1 ?? ''}
                                    onChange={(e) =>
                                      setEditCompetency({
                                        ...editCompetency,
                                        group1: e.target.value,
                                      })
                                    }
                                  />
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  <button
                                    onClick={() => {
                                      setShowTemplatesModal(true);
                                    }}>
                                    Templates
                                  </button>
                                  <button
                                    disabled={
                                      !editCompetency?.competencyTitle ||
                                      (editCompetency.competencyType === 'Count' &&
                                        (!editCompetency.countTypeRequirement ||
                                          editCompetency.countTypeRequirement! < 2))
                                    }
                                    onClick={() => updateCompetency(editCompetency)}>
                                    Save
                                  </button>
                                  <button onClick={cancelEdit}>Cancel</button>
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell component="th" scope={'row'}>
                                  {competency.competencyTitle}
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  {competency.competencyDetails}
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  {competency.competencyType}

                                  {competency.competencyType === 'Count' && (
                                    <>&nbsp;({competency.countTypeRequirement})</>
                                  )}
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  {competency.group1 ?? '-'}
                                </TableCell>
                                <TableCell component="th" scope={'row'}>
                                  <button
                                    disabled={i === 0}
                                    onClick={() => handleMoveUp(competency.id)}>
                                    Move Up
                                  </button>
                                  <button
                                    disabled={i === currentCourseCompetencies.length - 1}
                                    onClick={() => handleMoveDown(competency.id)}>
                                    Move Down
                                  </button>
                                  <button onClick={() => deleteCompetency(competency.id)}>
                                    Delete
                                  </button>
                                  <button onClick={() => editMode(competency)}>Edit</button>
                                </TableCell>
                              </>
                            )}
                          </>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <br />

            {!editCompetency?.id && (
              <TableContainer component={Paper}>
                <h3>Add new competency to this course:</h3>
                <Table className={styles.table}>
                  <TableBody>
                    <TableCell component="th" scope={'row'}>
                      <label>
                        * Title:
                        <br />
                        <input
                          type="text"
                          value={newCompetency.competencyTitle ?? ''}
                          onChange={(e) =>
                            setNewCompetency({
                              ...newCompetency,
                              competencyTitle: e.target.value,
                            })
                          }
                        />
                      </label>
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <label>
                        Description:
                        <br />
                        <textarea
                          value={newCompetency.competencyDetails ?? ''}
                          onChange={(e) =>
                            setNewCompetency({
                              ...newCompetency,
                              competencyDetails: e.target.value,
                            })
                          }
                        />
                      </label>
                    </TableCell>
                    <TableCell>
                      <label>
                        * Type:
                        <br />
                        <select
                          value={newCompetency.competencyType}
                          onChange={(e) =>
                            setNewCompetency({
                              ...newCompetency,
                              competencyType: e.target.value,
                            })
                          }>
                          <option value="">Select</option>
                          <option value="Checkbox">Checkbox</option>
                          <option value="Count">Count</option>
                        </select>
                        {newCompetency.competencyType === 'Count' && (
                          <>
                            <br />#{' '}
                            <input
                              type="number"
                              value={newCompetency?.countTypeRequirement ?? 0}
                              onChange={(e) =>
                                setNewCompetency({
                                  ...newCompetency,
                                  countTypeRequirement: parseInt(e.target.value),
                                })
                              }
                            />
                          </>
                        )}
                      </label>
                    </TableCell>
                    <TableCell>
                      <label>
                        Group:
                        <br />
                        <input
                          type="text"
                          value={newCompetency.group1 ?? ''}
                          onChange={(e) =>
                            setNewCompetency({
                              ...newCompetency,
                              group1: e.target.value,
                            })
                          }
                        />
                      </label>
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <button
                        onClick={addNewCompetency}
                        disabled={
                          !newCompetency.competencyTitle ||
                          !newCompetency.competencyType ||
                          (newCompetency.competencyType === 'Count' &&
                            (!newCompetency.countTypeRequirement ||
                              newCompetency.countTypeRequirement! < 2))
                        }>
                        Add
                      </button>
                    </TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
      </div>
      <Modal
        className={styles.modal}
        open={showTemplatesModal}
        closeAfterTransition
        onClose={() => {
          setNewTemplate(initialTemplateState);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}>
        <Fade in={showTemplatesModal}>
          <div className={styles.paper}>
            <Box width="10%" ml="auto">
              <IconButton aria-label="close" onClick={() => setShowTemplatesModal(false)}>
                <Clear />
              </IconButton>
            </Box>
            <Box mb={2} ml={2} mr={2}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Body</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.sortBy(
                    editCompetency?.templates,
                    (t: ICourseCompetencyTemplateModel) => t.title
                  ).map((template: ICourseCompetencyTemplateModel, i: number) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {template.title} {template.id === 0 && <strong> (new)</strong>}
                        </TableCell>
                        <TableCell>{template.type}</TableCell>
                        <TableCell>{template.emailSubject}</TableCell>
                        <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                          {template.emailContent}
                        </TableCell>
                        <TableCell>
                          <button onClick={() => removeTemplate(template)}>Delete</button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Name"
                        value={newTemplate.title}
                        onChange={(e) => setNewTemplate({ ...newTemplate, title: e.target.value })}
                      />
                    </TableCell>
                    <TableCell>
                      <select
                        value={newTemplate.type}
                        onChange={(e) =>
                          setNewTemplate({
                            ...newTemplate,
                            type: e.target.value,
                          })
                        }>
                        <option value="">Select</option>
                        <option value="NYC">NYC</option>
                        <option value="C">C</option>
                      </select>{' '}
                    </TableCell>
                    <TableCell>
                      <input
                        type="text"
                        placeholder="Subject"
                        value={newTemplate.emailSubject}
                        onChange={(e) =>
                          setNewTemplate({
                            ...newTemplate,
                            emailSubject: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <textarea
                        rows={5}
                        placeholder="Body"
                        value={newTemplate.emailContent}
                        onChange={(e) =>
                          setNewTemplate({ ...newTemplate, emailContent: e.target.value })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <button disabled={!newTemplate.type} onClick={addNewTemplate}>
                        Add
                      </button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <span>
                        You can use the following placeholders:
                        &#123;&#123;studentFirstName&#125;&#125;,
                        &#123;&#123;competencyTitle&#125;&#125;,
                        &#123;&#123;competencyGroup&#125;&#125;,
                        &#123;&#123;trainerFirstName&#125;&#125;
                      </span>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
