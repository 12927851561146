import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks';
import {
  CourseType,
  IIntakeModel,
  IDiplomaModuleModel,
} from '../../../domain/store/OnlineCoursesModel';
import { PublicCertSchedule } from './PublicCertSchedule';
import { PublicDiplomaSchedule } from './PublicDiplomaSchedule';
import qs from 'qs';

export interface CourseTypeSpecificPublicScheduleProps {
  intake?: IIntakeModel;
  module?: IDiplomaModuleModel;
  country: 'AU' | 'US';
}

export const PublicSchedule: React.FC = function (props) {
  const { id } = useParams<{ id: string; ct: string }>();
  const { onlineCoursesModel } = useStore();
  const [intake, setIntake] = useState<IIntakeModel>();
  const [lastHeightSent, setLastHeightSent] = useState(0);
  const containerRef = React.createRef<HTMLDivElement>();
  const [courseType, setCourseType] = useState<'Cert' | 'Dip' | 'DipMod' | undefined>(undefined);
  const [country, setCountry] = useState<'AU' | 'US'>('AU');
  const [diplomaModule, setDiplomaModule] = useState<IDiplomaModuleModel | undefined>(undefined);

  useEffect(() => {
    const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    let parsedId = parseInt(id);

    if (isNaN(parsedId)) {
      console.warn('Breathe Intake/Module ID not setup in the Online Course admin page.');
      return;
    }

    if (qsParams && qsParams.country) setCountry(qsParams.country);

    let courseType: 'Cert' | 'Dip' | 'DipMod' | undefined = undefined;
    if (qsParams && qsParams.ct === 'cert') courseType = 'Cert';
    else if (qsParams && qsParams.ct === 'dip') courseType = 'Dip';
    else if (qsParams && qsParams.ct === 'dipMod') courseType = 'DipMod';
    else console.warn('Course type not set in URL (&ct=). Will attempt fallback.');

    if (courseType) setCourseType(courseType);

    if (courseType === 'Cert' || courseType === 'Dip' || !courseType) {
      onlineCoursesModel.loadIntakeByIdForProspect(parsedId).then((i: IIntakeModel) => {
        setIntake(i);

        //Fallback: support for legacy public links
        if (
          i!! &&
          (i.courseType === CourseType.Certificate ||
            i.courseType === CourseType.AnatomyAndMovement)
        )
          setCourseType('Cert');
        if (i!! && i.courseType === CourseType.Diploma) setCourseType('Dip');
        //end Fallback
      });
    } else if (courseType === 'DipMod') {
      onlineCoursesModel
        .loadModuleByIdForProspect(parsedId)
        .then((moduleModel: IDiplomaModuleModel) => {
          setDiplomaModule(moduleModel);
        });
    }
  }, [id, onlineCoursesModel]);

  useEffect(() => {
    const intervalRef = setInterval(() => {
      let height = containerRef.current?.scrollHeight;
      if (height && height !== 0 && height !== lastHeightSent) {
        window.parent.postMessage(height, '*');
        setLastHeightSent(height ?? 0);
      }
    }, 333);
    return () => {
      clearInterval(intervalRef);
    };
  });

  return (
    <div ref={containerRef}>
      {courseType === 'Cert' && <PublicCertSchedule intake={intake} country={country} />}
      {courseType === 'Dip' && <PublicDiplomaSchedule intake={intake} country={country} />}
      {courseType === 'DipMod' && (
        <PublicDiplomaSchedule module={diplomaModule} country={country} />
      )}
    </div>
  );
};
