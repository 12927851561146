import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CourseType, IIntakeModel, ILectureModel } from '../../../domain/store/OnlineCoursesModel';
import moment from 'moment-timezone';
import qs from 'qs';
import styles from './PublicSchedule.module.scss';
import { FormControl, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { TutorialSchedule } from '../../components/TutorialSchedule/TutorialSchedule';
import { Moment } from 'moment';
import { CourseTypeSpecificPublicScheduleProps } from './PublicSchedule';

export interface ICertLectureScheduleRow {
  fromDate?: Moment;
  toDate?: Moment;
  isBreak?: boolean;
}

export const PublicCertSchedule: React.FC<CourseTypeSpecificPublicScheduleProps> = observer(
  (props: CourseTypeSpecificPublicScheduleProps) => {
    const [enrolBeforeDate, setEnroleBeforeDate] = useState('');
    const [hasLectures, setHasLectures] = useState(false);
    const [lectureRows, setLectureRows] = React.useState<ICertLectureScheduleRow[]>([]);
    const [timezones, setTimezones] = useState<string[]>([]);
    const [userTimezone, setUserTimezone] = useState<string>('');
    const [showTimezone, setShowTimezone] = useState(false);
    const [timezonesLoaded, setTimezonesLoaded] = useState(false);
    const [intake, setIntake] = useState<IIntakeModel>();

    const getPosixCorrectedFormat = (timezoneName: string) => {
      // Due to moment's POSIX-style timezones, Etc/GMT[+/-]X have inverted offset values - https://github.com/moment/moment-timezone/issues/167
      if (timezoneName.indexOf('Etc/GMT+') > -1) {
        return timezoneName.replace('+', '-');
      } else if (timezoneName.indexOf('Etc/GMT-') > -1) {
        return timezoneName.replace('-', '+');
      }
      return timezoneName;
    };

    useEffect(() => {
      if (!props.intake) return;
      setIntake(props.intake);
      if ((props.intake.lectures?.length ?? 0) === 0) return;
      setHasLectures(true);
      const orderedLectures = props.intake.lectures.sort((a: ILectureModel, b: ILectureModel) => {
        return a.datetime > b.datetime ? 1 : a.datetime === b.datetime ? 0 : -1;
      });
      setIntake({ ...props.intake, lectures: orderedLectures ?? [] });
    }, [props.intake]);

    useEffect(() => {
      const names = moment.tz.names();
      setTimezones(names);
      setUserTimezone(moment.tz.guess(true));
      setTimezonesLoaded(true);
    }, [timezonesLoaded]);

    useEffect(() => {
      if (!intake?.lectures || !userTimezone) return;
      let rows: ICertLectureScheduleRow[] = [];
      intake.lectures.forEach((l: ILectureModel) => {
        // if it's the first row or it's an Anatomy & Movement course, add the lecture
        // A&M lectures are listed individually as there are multiple per week
        if (rows.length === 0 || intake.courseType === CourseType.AnatomyAndMovement) {
          rows.push({ fromDate: moment.tz(l.datetime, getPosixCorrectedFormat(userTimezone)) });
        } else {
          const lastRow = rows[rows.length - 1];

          // get this lecture's time in the user's localtime
          const localTime = moment.tz(l.datetime, getPosixCorrectedFormat(userTimezone));
          // if last this lecture's localtime is exactly 7 days from max of ("toDate" - if exists, if not use "fromDate")
          if (
            moment(lastRow.toDate ?? lastRow.fromDate!)
              .add(7, 'days')
              .isSame(localTime)
          ) {
            // use this lecture's localtime as new toDate
            rows[rows.length - 1].toDate = localTime;
          } else {
            // if this lecture's time is ~ 1 week (but not exact) after the last lecture time
            let hoursDifference = localTime.diff(
              moment(lastRow.toDate ?? lastRow.fromDate!),
              'hours'
            );
            if (hoursDifference < 8 * 24 && hoursDifference > 6 * 24) {
              // ~7 days
              // create new row and use this lectures localtime as the fromDate
              rows.push({ fromDate: localTime });
            } else {
              // add break row
              rows.push({ isBreak: true });
              // add another row with this lecture's time as fromDate
              rows.push({ fromDate: localTime });
            }
          }
        }
      });
      setLectureRows(rows);
    }, [intake, userTimezone, hasLectures]);

    useEffect(() => {
      const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (!qsParams.eb && lectureRows.length > 0) {
        const enrolBefore = lectureRows[0]
          .fromDate!.clone()
          .subtract(2, 'months')
          .endOf('month')
          .format('ll');
        setEnroleBeforeDate(enrolBefore);
      } else setEnroleBeforeDate(moment.unix(qsParams.eb).format('ll'));
    }, [lectureRows]);

    const handleTimezoneChange = (event: any, newValue: any) => {
      setUserTimezone(newValue);
    };

    const courseOrProgram = props.country === 'US' ? 'Program' : 'Course';

    return (
      <>
        <div className={styles.container}>
          <div>
            <Grid container>
              <Grid item xs={12} md={6}>
                <h2>{courseOrProgram} start date:</h2>
                <p>Start now - immediate access on enrol{props.country === 'US' ? 'l' : ''}ment</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2>Enrol{props.country === 'US' ? 'l' : ''} before:</h2>
                <p>{enrolBeforeDate}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2>Average time to complete:</h2>
                {intake?.courseType === CourseType.AnatomyAndMovement && <p>6 weeks</p>}

                {intake?.courseType !== CourseType.AnatomyAndMovement && (
                  <p>
                    Certification only: 21 weeks <br />
                    Comprehensive (450+ hrs curriculum including Chair, Barrel and Cadillac
                    apparatus) Certification: 12 months
                  </p>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <h2>Location:</h2>
                <p>100% online - study from anywhere</p>
                <p>No travel required</p>
              </Grid>
            </Grid>
            <div className={styles.scheduleContainer}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <h1>Key Dates & Times:</h1>
                  {!showTimezone && (
                    <>
                      <p>
                        Your timezone is: <strong>{userTimezone}</strong>
                        <button
                          className={styles.timezoneButton}
                          onClick={() => setShowTimezone(true)}>
                          Change Timezone
                        </button>
                      </p>
                    </>
                  )}
                  {showTimezone && (
                    <FormControl fullWidth className={styles.timezoneSelector}>
                      <Autocomplete
                        id="timezone-location-autocomplete"
                        onChange={handleTimezoneChange}
                        options={timezones}
                        value={userTimezone}
                        getOptionLabel={(option) => option.replace(/_/g, ' ')}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField {...params} label="Timezone" variant="outlined" />
                        )}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} md={6} className={styles.tutorialScheduleContainer}>
                  <h2>Tutorials (1 per week):</h2>
                  <TutorialSchedule
                    acuityCategory={intake?.acuityCategory}
                    mode={'public'}
                    userTimezone={getPosixCorrectedFormat(userTimezone)}
                    location={props.country}
                  />
                  <br />
                  <i>*days and times are subject to change</i>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>Masterclasses:</h2>
                  <p>Attend Live & On-Demand</p>
                  <i>*recommended 2 per week</i>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>Practice Teaching:</h2>
                  <p>Teach your classmates online, or practice your own way</p>
                  <i>*recommended 2 per week</i>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>{courseOrProgram} Deadline:</h2>
                  <>
                    {lectureRows.length > 0
                      ? lectureRows[0].fromDate!.clone().add(1, 'year').format('ll')
                      : '12 months after first lecture'}
                  </>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
);
