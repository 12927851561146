// @ts-nocheck
import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../services/TelemetryService';
import { withRouter } from 'react-router-dom';
import styles from './TelemetryProvider.module.scss';

type State = {
  initialized: boolean;
};

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 * src: https://github.com/Azure-Samples/application-insights-react-demo/blob/master/src/telemetry-provider.jsx
 */
class TelemetryProvider extends Component<any, State> {
  state = {
    initialized: false,
  };

  componentDidMount() {
    const { history } = this.props;
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
    if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      this.setState({ initialized: true });
    }

    this.props.after();
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(
  withAITracking(ai.reactPlugin, TelemetryProvider, undefined, styles.fullHeight)
);
