//ttutorial-attendance/available-times/{publicId}

import { flow, types } from 'mobx-state-tree';
import { ITutorialAttendanceCaseModel } from './TutorialAttendanceModel';
import { getEnv } from './util';

export interface AcuityAppointmentBookingRequestPreRegisterQuestions {
  week: string;
  equipment: string;
}

export interface AcuityAppointmentBookingRequest {
  datetime: Date;
  appointmentTypeID: number;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  allowPeerSelfSignup: boolean;
  ptSessionType: string;
  classType: string;
  sendConfirmationEmail: boolean;
  preRegisterParticipant: boolean;
  preRegisterQuestions: AcuityAppointmentBookingRequestPreRegisterQuestions | null;
  isJoiningWaitlist: boolean;
  calendar: string;
}

export interface AcuityAppointmentBookingRequestV2 {
  datetime: Date;
  appointmentTypeID: number;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  preRegisterQuestions: AcuityAppointmentBookingRequestPreRegisterQuestions | null;
  isJoiningWaitlist: boolean;
  calendar: string;
}

export interface IAcuityAppointmentBookingResponse {
  Id: number;
  dateTime: Date;
  appointmentTypeID: number;
  firstName: string;
  lastName: string;
  email: string;
  dateCreated: Date;
  calendar: string;
  joinedWaitlist: boolean;
  waitlistQueuePosition: number | null;
  alreadyOnWaitlist: boolean;
}

export interface IntakeAppointmentTypeModel {
  id: number;
  name: string;
  description: string;
  duration: number;
  type: string;
  calendarIDs: Array<number>;
}

export interface IAppointment {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateTime: Date;
  dateTimeCreated: Date;
  type: string;
  category: string;
  appointmentTypeID: number;
  calendar: string;
  duration: number;
  location: string;
  notes: string;
  waitlistId: number | null;
  isOnWaitlist: boolean;
  positionOnWaitlistQueue: number | null;
  customQuestions: ZoomParticipantCustomQuestions[] | null;
}

export interface ZoomParticipantCustomQuestions {
  title: string;
  value: string;
}

export interface INotesUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface IPracticeTeachingNotesObject {
  allowSelfSignup: boolean;
  sessionType: string;
  initiator: INotesUser;
  participants: INotesUser[];
}

export interface IPracticeTeachingAppointment extends IAppointment {
  practiceTeachingNotesObject: IPracticeTeachingNotesObject;
}

export interface IntakeTutorialModel {
  id: number;
  name: string;
  calendar: string;
  duration: number;
  slots: number;
  slotsAvailable: number;
  time: Date;
  calendarTimezone: string;
  appointmentTypeID: number;
  category: string;
  color: string;
  waitlistAvailable: boolean;
  weekAndEquipmentRegistrationRequired: boolean;
}

export interface Calendar {
  id: number;
  name: string;
}

export interface TutorialMakeUpOptions {
  availableTutorials: IntakeTutorialModel[];
  attendanceCase: ITutorialAttendanceCaseModel;
}

export const ModuleSummaryModel = types.model('ModuleSummaryModel', {
  id: types.optional(types.number, 0),
  title: types.string,
  displayName: types.string,
  acuityCategory: types.optional(types.string, ''),
});

export const IntakeSummaryModel = types.model('IntakeSummaryModel', {
  id: types.optional(types.number, 0),
  title: types.string,
  publicIdentifier: types.string,
  acuityCategory: types.optional(types.string, ''),
  modules: types.array(ModuleSummaryModel),
});

export const BookingModel = types
  .model('BookingModel', {})
  .actions((self) => ({
    loadIntakeTutorialClasses: flow(function* (appointmentTypeId: string, timeZone: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(
            `PublicBooking/intake/tutorial-classes/${appointmentTypeId}?timeZone=${encodeURIComponent(
              timeZone
            )}`
          )
          .json<IntakeTutorialModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error /PublicBooking/intake/tutorial-classes/${appointmentTypeId}?timeZone=${encodeURIComponent(
            timeZone
          )} error: ${error}`
        );
      }
    }),
    // input is a list of categories delimited by `~`
    loadIntakeAppointmentTypes: flow(function* (categories: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`PublicBooking/appointment-types/${categories}`)
          .json<IntakeTutorialModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error /PublicBooking/appointment-types/${categories} error: ${error}`);
      }
    }),
    // input is a list of categories delimited by `~`
    loadStudentTutorialBookings: flow(function* (
      categories: string,
      email: string,
      classType?: string,
      getIndividualJoinLinkForAppointmentIds?: number[]
    ) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(
            `PublicBooking/appointments/${categories}/${email}` +
              (classType ? `/${classType}` : '') +
              (getIndividualJoinLinkForAppointmentIds
                ? '?GetIndividualJoinLinkAppointmentIds=' +
                  getIndividualJoinLinkForAppointmentIds.join(',')
                : '')
          )
          .json<IAppointment[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error /PublicBooking/appointments/${categories}/${email} error: ${error}`);
      }
    }),
    loadCalendars: flow(function* (calendarIDs: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`PublicBooking/calendar/${calendarIDs}`)
          .json<Calendar[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error PublicBooking/calendars/${calendarIDs} error: ${error}`);
      }
    }),
    getAvailabilityDates: flow(function* (
      appointmentID: string,
      calendarID: string,
      startingDate: string
    ) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(
            `PublicBooking/availability/dates/${appointmentID}/${calendarID}?startingDate=${startingDate}`
          )
          .json<IntakeTutorialModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error PublicBooking/availability/dates/${appointmentID}/${calendarID}?startingDate=${startingDate} error: ${error}`
        );
      }
    }),
    postIntakeBookAppointment: flow(function* (
      acuityAppointmentBookingRequest: AcuityAppointmentBookingRequest
    ) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`PublicBooking/appointment/book`, {
            json: acuityAppointmentBookingRequest,
          })
          .json<IAcuityAppointmentBookingResponse>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error POST PublicBooking/appointment/book body:${acuityAppointmentBookingRequest} error: ${error}`
        );
        throw error;
      }
    }),
    updateAppointment: flow(function* (appointment: IAppointment) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .patch(`PublicBooking/appointment`, {
            json: appointment,
          })
          .json<IAppointment>()
          .then((value) => value);
      } catch (error) {
        console.log(`error PUT PublicBooking/appointment body:${appointment} error: ${error}`);
        throw error;
      }
    }),
    cancelAppointment: flow(function* (appointmentId: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .put(`PublicBooking/appointment/${appointmentId}/cancel`)
          .then((value) => value);
      } catch (error) {
        console.log(`error PublicBooking/appointment/${appointmentId}/cancel error: ${error}`);
        throw error;
      }
    }),
    getTutorialMakeUpAvailableTimes: flow(function* (publicId: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`PublicBooking/tutorial-attendance/available-times/${publicId}`)
          .json<TutorialMakeUpOptions>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error PublicBooking/tutorial-attendance/available-times/${publicId} error: ${error}`
        );
        throw error;
      }
    }),
    confirmTutorialRebooking: flow(function* (attendanceCaseId: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .put(`PublicBooking/tutorial-attendance/confirm-makeup-booking/${attendanceCaseId}`)
          .then((value) => value);
      } catch (error) {
        console.log(
          `error PublicBooking/tutorial-attendance/confirm-makeup-booking/${attendanceCaseId} error: ${error}`
        );
        throw error;
      }
    }),
    postBookTutorialMakeUpAppointment: flow(function* (
      acuityAppointmentBookingRequest: AcuityAppointmentBookingRequest,
      attendanceCaseId: number
    ) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`PublicBooking/tutorial-attendance/makeup/book/${attendanceCaseId}`, {
            json: acuityAppointmentBookingRequest,
          })
          .json<IAcuityAppointmentBookingResponse>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error PublicBooking/tutorial-attendance/makeup/book/${attendanceCaseId} error: ${error}`
        );
        throw error;
      }
    }),
    getPracticeTeachingSessionsOpenToSelfSignup: flow(function* (appointmentTypeId: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`PublicBooking/practice-teaching/self-signup/${appointmentTypeId}`)
          .json<IPracticeTeachingAppointment>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error PublicBooking/practice-teaching/self-signup/${appointmentTypeId} error: ${error}`
        );
        throw error;
      }
    }),
    bookSelfSignupPracticeTeachingSession: flow(function* (
      appointmentId: number,
      email: string,
      firstName: string,
      lastName: string,
      userTimezone: string
    ) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .post(`PublicBooking/practice-teaching/self-signup`, {
            json: { appointmentId, email, firstName, lastName, timezone: userTimezone },
          })
          .json<boolean>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST PublicBooking/practice-teaching/self-signup error: ${error}`);
        throw error;
      }
    }),
    cancelSelfSignupPracticeTeachingSession: flow(function* (
      appointmentId: string,
      email: string,
      firstName: string,
      lastName: string,
      timezone: string
    ) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .delete(`PublicBooking/practice-teaching/self-signup`, {
            json: { appointmentId, email, firstName, lastName, timezone },
          })
          .json<boolean>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST PublicBooking/practice-teaching/self-signup error: ${error}`);
        throw error;
      }
    }),
    // booking system v2 below
    loadSessionsForCategory: flow(function* (
      acuityCategory: string,
      userTimezone: string,
      isoDate: string
    ) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(
            `PublicBooking/bookings/get-sessions?acuityCategory=${acuityCategory}&userTimezone=${userTimezone}&date=${isoDate}`
          )
          .json<IntakeTutorialModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error PublicBooking/ error: ${error}`);
        throw error;
      }
    }),
    loadStudentBookings: flow(function* (categories: string, email: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`PublicBooking/bookings/${categories}/${email}`)
          .json<IAppointment[]>()
          .then((value) => value);
      } catch (error) {
        console.log(
          `error loadStudentBookings /PublicBooking/appointments/${categories}/${email} error: ${error}`
        );
      }
    }),
    postIntakeBookAppointmentV2: flow(function* (request: AcuityAppointmentBookingRequestV2) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post(`PublicBooking/appointment/book-v2`, {
            json: request,
          })
          .json<IAcuityAppointmentBookingResponse>()
          .then((value) => value);
      } catch (error) {
        console.log(`error POST PublicBooking/appointment/bookv2 body:${request} error: ${error}`);
        throw error;
      }
    }),
  }))
  .views((self) => ({}));
