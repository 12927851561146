import React from 'react';
import Table from '@material-ui/core/Table';
import styles from './MasterclassList.module.scss';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Link } from 'react-router-dom';
import { TotaraEmbedCodeCopyToClipBoard } from 'views/components/TotaraEmbedCodeCopyToClipBoard';

export const MasterclassList: React.FC = function () {
  return (
    <div className={styles.container}>
      <h1>Masterclasses</h1>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Course</TableCell>
            <TableCell>My Masterclasses</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>CertIV</TableCell>
            <TableCell>
              {' '}
              <Link
                to={'/public/intake/masterclasses/students/manage/Masterclasses'}
                target={'_blank'}>
                My Masterclasses
              </Link>
              <TotaraEmbedCodeCopyToClipBoard />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Diploma</TableCell>
            <TableCell>
              {' '}
              <Link
                to={
                  '/public/intake/masterclasses/students/manage/Masterclasses~Diploma - Masterclasses'
                }
                target={'_blank'}>
                My Masterclasses
              </Link>
              <TotaraEmbedCodeCopyToClipBoard />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
