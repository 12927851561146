import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { ITagModel } from '../../../domain/store/SettingsStore';
import styles from './Settings.module.scss';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

export const Tags = () => {
  const { settingsStore } = useStore();

  const [tags, setTags] = useState<ITagModel[]>([]);

  const initialTagState: ITagModel = {
    id: 0,
    tagDescription: '',
    tag: '',
    level: '',
    fieldScope: '',
    createdTimestamp: null,
    createdBy: null,
    deletedBy: null,
    deletedTimestamp: null,
  };

  const [newTag, setNewTag] = useState<ITagModel>(initialTagState);
  const [editTag, setEditTag] = useState<ITagModel | null>(null);

  useEffect(() => {
    settingsStore.getTags().then(() => {
      setTags(settingsStore.tags);
    });
  }, [settingsStore]);

  const addNewTag = async () => {
    settingsStore.upsertTag(newTag).then((newTagId: number) => {
      newTag.id = newTagId;
      setTags([...tags, newTag]);
      setNewTag(initialTagState);
    });
  };

  const deleteTag = async (tagId: number) => {
    try {
      const success = await settingsStore.deleteTag(tagId);
      if (success) setTags(tags.filter((tag: ITagModel) => tag.id !== tagId));
    } catch (error) {
      console.error(error);
    }
  };

  const editMode = (tag: ITagModel) => {
    setEditTag(tag);
  };

  const cancelEdit = () => {
    setEditTag(null);
  };

  const updateTag = async (updatedTag: ITagModel) => {
    try {
      await settingsStore.upsertTag(updatedTag).then((success) => {
        if (success) {
          const updatedTags = tags.map((tagModel: ITagModel) =>
            tagModel.id === updatedTag.id ? updatedTag : tagModel
          );
          setTags(updatedTags);
          setEditTag(null);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Tags</h1>

      <TableContainer component={Paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Tag</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Level</TableCell>
              <TableCell>Field Scope</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((tag) => (
              <TableRow key={tag.id}>
                {editTag?.id === tag.id ? (
                  <>
                    <TableCell component="th" scope={'row'}>
                      <input
                        type="text"
                        value={editTag.tag}
                        onChange={(e) =>
                          setEditTag({
                            ...editTag,
                            tag: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <textarea
                        value={editTag.tagDescription}
                        onChange={(e) =>
                          setEditTag({
                            ...editTag,
                            tagDescription: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <select
                        value={editTag.level}
                        onChange={(e) => setEditTag({ ...editTag, level: e.target.value })}>
                        <option value="Info">Info</option>
                        <option value="Warn">Warn</option>
                      </select>
                    </TableCell>
                    <TableCell>
                      <label>
                        Field Scope:
                        <br />
                        <select
                          value={editTag.fieldScope}
                          onChange={(e) => setEditTag({ ...editTag, fieldScope: e.target.value })}>
                          <option value="Intake">Intake</option>
                          <option value="Student">Student</option>
                        </select>
                      </label>
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <button
                        disabled={
                          !editTag.tag ||
                          !editTag.tagDescription ||
                          !editTag.level ||
                          !editTag.fieldScope
                        }
                        onClick={() => updateTag(editTag)}>
                        Save
                      </button>
                      <button onClick={cancelEdit}>Cancel</button>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell component="th" scope={'row'}>
                      {tag.tag}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {tag.tagDescription}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {tag.level}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {tag.fieldScope}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <button onClick={() => deleteTag(tag.id)}>Delete</button>
                      <button onClick={() => editMode(tag)}>Edit</button>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <h3>Add New Tag</h3>
      <div>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <label>
                    Tag Name:
                    <br />
                    <input
                      type="text"
                      value={newTag.tag}
                      onChange={(e) => setNewTag({ ...newTag, tag: e.target.value })}
                    />
                  </label>
                </TableCell>
                <TableCell>
                  <label>
                    Tag Description:
                    <br />
                    <textarea
                      value={newTag.tagDescription}
                      onChange={(e) => setNewTag({ ...newTag, tagDescription: e.target.value })}
                    />
                  </label>
                </TableCell>
                <TableCell>
                  <label>
                    Level:
                    <br />
                    <select
                      value={newTag.level}
                      onChange={(e) => setNewTag({ ...newTag, level: e.target.value })}>
                      <option value="">Select</option>
                      <option value="Info">Info</option>
                      <option value="Warn">Warn</option>
                    </select>
                  </label>
                </TableCell>
                <TableCell>
                  <label>
                    Field Scope:
                    <br />
                    <select
                      value={newTag.fieldScope}
                      onChange={(e) => setNewTag({ ...newTag, fieldScope: e.target.value })}>
                      <option value="">Select</option>
                      <option value="Intake">Intake</option>
                      <option value="Student">Student</option>
                    </select>
                  </label>
                </TableCell>
                <TableCell>
                  <button
                    onClick={addNewTag}
                    disabled={
                      !newTag.tag || !newTag.tagDescription || !newTag.level || !newTag.fieldScope
                    }>
                    Save New Tag
                  </button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
