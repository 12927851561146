import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';

export const LogbookEntryModel = types.model('StudentStoryTimeVideoModel', {
  id: types.maybe(types.identifierNumber),
  publicId: types.maybe(types.string),
  courseType: types.string,
  durationMinutes: types.number,
  entryType: types.string,
  studentEmail: types.string,
  createdDateTime: types.maybe(types.string),
  entryTypeCategory: types.string,
  entryTypeOtherDetails: types.maybe(types.string),
  timestamp: types.string,
  writtenReflection: types.maybeNull(types.string),
});

export interface ILogbookEntryModel extends Instance<typeof LogbookEntryModel> {}

export const LogbookModel = types.model('LogbookEntryModel', {}).actions((self) => ({
  upsertLogbookEntry: flow(function* (entry: ILogbookEntryModel) {
    const { ajax } = getEnv(self);

    try {
      return yield ajax
        .post(`PublicLogbook`, {
          json: { logbookEntry: entry },
        })
        .json<ILogbookEntryModel>()
        .then((value) => value);
    } catch (error) {
      console.log(`error POST ProtectedLogbook error: ${error}`);
    }
  }),
  getLogbookList: flow(function* (courseType: string, email: string) {
    const { ajax } = getEnv(self);

    try {
      return yield ajax
        .get(`PublicLogbook/list?courseType=${courseType}&email=${email}`)
        .json<ILogbookEntryModel[]>()
        .then((value) => value);
    } catch (error) {
      console.log(`error GET ProtectedLogbook/list error: ${error}`);
    }
  }),
  deleteLogbookEntry: flow(function* (email: string, publicId: string) {
    const { ajax } = getEnv(self);

    try {
      return yield ajax
        .delete(`PublicLogbook`, { json: { studentEmail: email, publicId: publicId } })
        .json<boolean>()
        .then((value) => value);
    } catch (error) {
      console.log(`error DELETE ProtectedLogbook error: ${error}`);
    }
  }),
}));
