import React, { useEffect, useState } from 'react';
import styles from '../intakes/IntakesList.module.scss';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import EditIcon from '@material-ui/icons/Edit';
import { useStore } from '../../hooks';
import { IDiplomaModuleSummaryModel } from '../../../domain/store/OnlineCoursesModel';

export const DiplomaModuleList: React.FC = function () {
  const { onlineCoursesModel } = useStore();
  const [modules, setModules] = useState<IDiplomaModuleSummaryModel[]>([]);

  useEffect(() => {
    onlineCoursesModel.loadDiplomaModuleSummaries().then(() => {
      setModules(onlineCoursesModel.diplomaModuleSummaries);
    });
  }, [onlineCoursesModel]);

  return (
    <div className={styles.container}>
      <h1>
        Diploma Modules
        <Link to={'/admin/diploma/module/add'}>
          <AddBoxIcon color={'primary'} fontSize="large" />
        </Link>
      </h1>

      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Module</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Live Schedule</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules!.map((i) => (
            <TableRow key={i.id}>
              <TableCell>{i.title}</TableCell>
              <TableCell>{i.id}</TableCell>
              <TableCell>
                <Link to={'/admin/diploma/module/' + i.id}>
                  <EditIcon />
                </Link>
              </TableCell>
              <TableCell>
                <Link
                  to={'/public/intake/schedule/' + i.id + '?country=AU&ct=dipMod'}
                  target={'_blank'}>
                  Live Schedule
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
