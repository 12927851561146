import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styles from './IntakesAdd.module.scss';
import Select from '@material-ui/core/Select';
import { useStore } from 'views/hooks';
import { observer } from 'mobx-react-lite';
import {
  Audience,
  CourseType,
  CourseTypeModelType,
  ICertModuleModel,
  IGradeBookItemModel,
  IIntakeModel,
  IKeapProductModel,
  ILectureModel,
} from '../../../domain/store/OnlineCoursesModel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { SnapshotIn } from 'mobx-state-tree';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment-timezone';
import { TutorialSchedule } from '../../components/TutorialSchedule/TutorialSchedule';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  defaultCertModules,
  defaultLectureDetails,
  defaultTutorialDescription,
} from './intakeCertDefaults';
import { IProductModel } from '../../../domain/store/CrmModel';
import { matchSorter } from 'match-sorter';
import { TotaraEmbedCodeCopyToClipBoard } from '../../components/TotaraEmbedCodeCopyToClipBoard';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useDebounce from '../../../hooks/useDebounce';

export const IntakesAdd: React.FC = observer(function () {
  const [intake, setIntake] = useState<SnapshotIn<IIntakeModel>>({
    id: 0,
    joinLiveLink: '',
    recordingLink: '',
    timezone: 'Australia/Brisbane',
    lectures: [],
    diplomaModules: [],
    keapProducts: [],
    certModules: defaultCertModules,
    title: '',
    acuityCategory: '',
    joinLiveMeetingId: '',
    joinLiveMeetingPassword: '',
    courseType: CourseType.Certificate,
    elearningAudience: '',
    elearningAudienceSecondary: '',
    enrolmentCapacityTotal: null,
    enrolmentCapacityRemaining: null,
    elearningPrimaryCourseIds: '',
    elearningSecondaryCourseIds: '',
    gradeBookItemReferenceIds: [],
    isDeleted: false,
  });
  const { onlineCoursesModel, crmModel } = useStore();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [hasLoadedAvailableModules, setHasLoadedAvailableModules] = useState(false);
  const [elearningAudienceList, setElearningAudienceList] = useState<Audience[]>([
    { name: '', id: '' },
  ]);
  const [productList, setProductList] = useState<IProductModel[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [referenceGradeBookItems, setReferenceGradeBookItems] = useState<IGradeBookItemModel[]>([]);
  const [secondaryReferenceGradeBookItems, setSecondaryReferenceGradeBookItems] = useState<
    IGradeBookItemModel[]
  >([]);

  const [primaryCourseIdsLoadedRefItems, setPrimaryCourseIdsLoadedRefItems] = useState<string>('');
  const [secondaryCourseIdsLoadedRefItems, setSecondaryCourseIdsLoadedRefItems] = useState<string>(
    ''
  );

  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (!id || !hasLoadedAvailableModules) return;

    setShowSpinner(true);
    onlineCoursesModel
      .loadIntakeById(parseInt(id))
      .then((i: IIntakeModel) => {
        const tzConvertedIntake = {
          ...i,
          certModules: i.certModules.length > 0 ? i.certModules : defaultCertModules,
          lectures: i.lectures?.map((l) => {
            const dtFormatted = moment(l.datetime).utcOffset(0, true).format('YYYY-MM-DDTHH:mm');
            const offset = moment.tz(dtFormatted, i.timezone).utcOffset();
            const adjusted = moment(dtFormatted)
              .utcOffset(offset, false)
              .format('YYYY-MM-DDTHH:mm');

            return {
              ...l,
              datetime: new Date(adjusted),
            };
          }),
        };
        setIntake(tzConvertedIntake);
      })
      .catch((e) => {
        console.error('Unable to load intake with ID of ' + id, e);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [onlineCoursesModel, id, hasLoadedAvailableModules]);

  useEffect(() => {
    onlineCoursesModel.loadDiplomaModuleSummaries().then(() => setHasLoadedAvailableModules(true));
  }, [onlineCoursesModel, setHasLoadedAvailableModules]);

  function stringToNumberArray(input: string): number[] {
    if (!input) {
      return [];
    }
    return input.split(',').reduce((acc: number[], str) => {
      let num: number = Number(str);
      if (!isNaN(num)) {
        acc.push(num);
      }
      return acc;
    }, []);
  }

  useDebounce(() => {
    if (intake.courseType === CourseType.Certificate) {
      const primary = intake.elearningPrimaryCourseIds ?? '';
      const secondary = intake.elearningSecondaryCourseIds ?? '';

      if (
        primary === primaryCourseIdsLoadedRefItems &&
        secondary === secondaryCourseIdsLoadedRefItems
      ) {
        // unchanged & already loaded
        return;
      }

      let courseIds: number[] = [];
      const primaryArray = stringToNumberArray(primary);
      courseIds = courseIds.concat(primaryArray);
      const secondaryArray = stringToNumberArray(secondary);
      courseIds = courseIds.concat(secondaryArray);

      if (courseIds.length === 0) {
        setReferenceGradeBookItems([]);
        setSecondaryReferenceGradeBookItems([]);
        return;
      }

      onlineCoursesModel.loadReferenceGradeBookItems(courseIds).then((v: IGradeBookItemModel[]) => {
        setPrimaryCourseIdsLoadedRefItems(primary);
        setSecondaryCourseIdsLoadedRefItems(secondary);

        const primaryGbItems = _.filter(v, (i) => _.includes(primaryArray, i.elearningCourseId));

        const secondaryGbItems = _.filter(v, (i) =>
          _.includes(secondaryArray, i.elearningCourseId)
        );

        setReferenceGradeBookItems(primaryGbItems);
        setSecondaryReferenceGradeBookItems(secondaryGbItems);

        // if it's a new intake, default all reference grade book items as applying to this intake.
        if (intake.id === 0) {
          setIntake((i) => {
            return { ...i, gradeBookItemReferenceIds: v.map((i) => i.id) };
          });
        }
      });
    }
  }, 3000);

  useEffect(() => {
    onlineCoursesModel
      .loadElearningAudiences(false)
      .then((a: Array<Audience>) => {
        if (a) {
          setElearningAudienceList([{ name: '', id: '' }, ...a]);
        }
      })
      .catch((e) => {
        console.error('Unable to load elearning audience list', e);
      });
  }, [onlineCoursesModel]);

  useEffect(() => {
    setShowSpinner(true);
    crmModel
      .loadAllProducts()
      .then((r) => setProductList(r))
      .finally(() => {
        setShowSpinner(false);
      });
  }, [crmModel]);

  const isCert = useCallback(() => {
    return (
      intake.courseType === CourseType.Certificate ||
      intake.courseType === CourseType.AnatomyAndMovement
    );
  }, [intake]);

  const isDiploma = useCallback(() => {
    return intake.courseType === CourseType.Diploma;
  }, [intake]);

  const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, title: event.target.value as string });
  };

  const handleLiveLinkChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, joinLiveLink: event.target.value as string });
  };

  const handleLiveMeetingIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, joinLiveMeetingId: event.target.value as string });
  };

  const handleLiveMeetingPasswordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, joinLiveMeetingPassword: event.target.value as string });
  };

  const handleEnrolmentCapacityTotal = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({
      ...intake,
      enrolmentCapacityTotal: event.target.value === '' ? null : Number(event.target.value),
    });
  };

  const handleEnrolmentCapacityRemaining = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({
      ...intake,
      enrolmentCapacityRemaining: event.target.value === '' ? null : Number(event.target.value),
    });
  };

  const handleAcuityCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, acuityCategory: event.target.value as string });
  };

  const handleTimezoneChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, timezone: event.target.value as string });
  };

  const handleCourseTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, courseType: event.target.value as CourseTypeModelType });
  };

  const handleCourseDeadlineChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({
      ...intake,
      courseDeadline: moment(event.target.value as string).toDate(),
    });
  };

  const onAddLecture = () => {
    const lectures = intake.lectures!;

    const lastLecture = lectures.length ? lectures[lectures.length - 1] : null;
    const nextDate: Date | null =
      lastLecture && lastLecture.datetime !== -1
        ? moment(lastLecture.datetime).add(7, 'days').toDate()
        : null;

    lectures.push({
      title: !lectures.length ? 'Orientation Week' : 'Week ' + lectures.length,
      datetime: nextDate ? nextDate : -1,
      details: defaultLectureDetails[lectures.length],
      duration: lastLecture ? lastLecture.duration : 90,
      lectureNotesLink: '',
      certModule: defaultCertModules[getLectureModule(lectures.length)],
      homeworkDescription:
        lectures.length === 0
          ? '‘Start Here’ module'
          : `Week ${lectures.length} of Module ${
              defaultCertModules[getLectureModule(lectures.length)].title
            } \nPractice teaching (2hrs)`,
      tutorialDescription: defaultTutorialDescription[lectures.length],
    });
    setIntake({ ...intake, lectures: lectures });
  };

  const onAddKeapProduct = () => {
    let keapProducts = intake.keapProducts ?? [];

    keapProducts.push({ id: 0, keapProductId: 0 });

    setIntake({ ...intake, keapProducts: keapProducts });
  };

  const getLectureModule = (lectureLength: number) =>
    lectureLength === 0
      ? 0
      : lectureLength < 5
      ? 1
      : lectureLength < 9
      ? 2
      : lectureLength < 13
      ? 3
      : lectureLength < 17
      ? 4
      : 5;

  const onDiplomaAddModule = () => {
    const modules = intake.diplomaModules!;
    modules.push({ id: 0, title: '', displayName: '', timezone: 'Etc/UTC' });
    setIntake({ ...intake, diplomaModules: modules });
  };

  const onCertAddModule = () => {
    const modules = intake.certModules!;
    modules.push({ id: 0, intakeId: intake.id, title: '', description: '' });
    setIntake({ ...intake, certModules: modules });
  };

  const onRemoveLecture = (index: number) => {
    intake.lectures!.splice(index, 1);
    setIntake({ ...intake, lectures: intake.lectures });
  };

  const onRemoveProduct = (index: number) => {
    intake.keapProducts!.splice(index, 1);
    setIntake({ ...intake, keapProducts: intake.keapProducts });
  };

  const onRemoveDiplomaModule = (index: number) => {
    intake.diplomaModules!.splice(index, 1);
    setIntake({ ...intake, diplomaModules: intake.diplomaModules });
  };

  const onRemoveCertModule = (index: number) => {
    intake.certModules!.splice(index, 1);
    setIntake({ ...intake, certModules: intake.certModules });
  };

  const handleLectureChange = (index: number, value: SnapshotIn<ILectureModel>) => {
    intake.lectures!.splice(index, 1, value);
    setIntake({ ...intake, lectures: intake.lectures });
  };

  const handleKeapProductChange = (index: number, value: SnapshotIn<IKeapProductModel>) => {
    intake.keapProducts!.splice(index, 1, value);
    setIntake({ ...intake, keapProducts: intake.keapProducts });
  };

  const handleDiplomaModuleChange = (index: number, value: string) => {
    intake.diplomaModules!.splice(index, 1, {
      id: parseInt(value),
      title: '',
      displayName: '',
      timezone: '',
    });
    setIntake({ ...intake, diplomaModules: intake.diplomaModules });
  };

  const handleCertModuleTitleChange = (index: number, value: string) => {
    const module = intake.certModules![index];
    intake.certModules!.splice(index, 1, { ...module, title: value });
    setIntake({ ...intake, certModules: intake.certModules });
  };

  const handleCertModuleDescriptionChange = (index: number, value: string) => {
    const module = intake.certModules![index];
    intake.certModules!.splice(index, 1, { ...module, description: value });
    setIntake({ ...intake, certModules: intake.certModules });
  };

  const handleElearningPrimaryCourseIdsChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIntake({ ...intake, elearningPrimaryCourseIds: event.target.value as string });
  };

  const handleElearningSecondaryCourseIdsChanged = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setIntake({ ...intake, elearningSecondaryCourseIds: event.target.value as string });
  };

  const handleGradeBookItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checked = event.target.checked;
    let item = event.target.name;

    if (checked && intake.gradeBookItemReferenceIds!.indexOf(parseInt(item)) === -1) {
      intake.gradeBookItemReferenceIds!.push(parseInt(item));
    } else if (!checked) {
      let index = intake.gradeBookItemReferenceIds!.indexOf(parseInt(item));
      if (index > -1) {
        intake.gradeBookItemReferenceIds!.splice(index, 1);
      }
    }
    setIntake({ ...intake, gradeBookItemReferenceIds: intake.gradeBookItemReferenceIds });
  };

  const handleToggleDelete = () => {
    if ((intake.id ?? 0) <= 0) return;

    if (confirmDelete) {
      onlineCoursesModel.updateIsDeleteFlag(intake.id!, !intake.isDeleted).then(() => {
        history.push('/admin/intakes');
      });
    } else {
      setConfirmDelete(true);
    }
  };

  const isValid = useCallback(() => {
    const intakeHasMinFields = typeof intake.timezone !== 'undefined';
    const allLecturesHaveMinFields =
      isDiploma() || intake.lectures!.every((l) => l.datetime && l.title.trim() && l.duration > 0);

    const modulesAreValid = isCert() || intake.diplomaModules!.every((m) => m!!);

    return intakeHasMinFields && allLecturesHaveMinFields && modulesAreValid;
  }, [intake, isCert, isDiploma]);

  const handleSave = () => {
    if (isValid()) {
      if (isCert()) intake.diplomaModules = [];
      else if (isDiploma()) intake.lectures = [];

      const getUtcDateAdjustedToTimezone = (date: Date | number) => {
        // treat it as UTC, even though it's originally in local time
        const dtFormatted = moment(date).utcOffset(0, true).format('YYYY-MM-DDTHH:mm');
        // get the appropriate offset, adjusted for daylight savings
        const offset = moment.tz(dtFormatted, intake.timezone).utcOffset();
        // override the offset while keeping the time numbers intact
        return moment(date).utcOffset(offset, true).toDate();
      };

      const tzConvertedIntake = {
        ...intake,
        lectures: intake.lectures?.map((l) => {
          return { ...l, datetime: getUtcDateAdjustedToTimezone(l.datetime) };
        }),
      };

      onlineCoursesModel
        .saveIntake(tzConvertedIntake)
        .then(() => {
          history.push('/admin/intakes');
        })
        .catch((e) => {
          alert('An error occured while saving the intake: ' + JSON.stringify(e));
        });
    }
  };

  return (
    <>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles.container}>
        <h1>{intake.id ? 'Edit' : 'Add'} Intake</h1>
        <Fab
          disabled={!isValid()}
          color="primary"
          aria-label="save"
          className={styles.floatingButton}
          onClick={handleSave}>
          <SaveIcon />
        </Fab>
        <Link to="/admin/intakes" className={styles.floatingButtonTwo}>
          <Fab color="secondary" aria-label="cancel">
            <CancelIcon />
          </Fab>
        </Link>

        <form>
          <Container maxWidth={'md'} className={styles.section}>
            <Grid container justify={'center'} spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="course-type-label">Course Type</InputLabel>
                  <Select
                    labelId="course-type-label"
                    id="course-type"
                    value={intake.courseType}
                    onChange={handleCourseTypeChange}>
                    <MenuItem value={CourseType.Certificate}>{CourseType.Certificate}</MenuItem>
                    <MenuItem value={CourseType.Diploma}>{CourseType.Diploma}</MenuItem>
                    <MenuItem value={CourseType.AnatomyAndMovement}>
                      {CourseType.AnatomyAndMovement}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Tooltip title="For display purposes in BE Core only">
                  <FormControl fullWidth>
                    <TextField
                      value={intake.title}
                      id="intakeTitle"
                      name="intakeTitle"
                      label="Intake Name"
                      placeholder={'YYYY Month Intake – Cert IV|Diploma of Clinical Pilates'}
                      onChange={handleTitleChange}
                      fullWidth
                    />
                  </FormControl>
                </Tooltip>
              </Grid>

              {isCert() && (
                <>
                  <Grid item xs={12} md={6}>
                    <Tooltip title="The timezone this intake's lectures are scheduled in">
                      <FormControl fullWidth>
                        <InputLabel id="timezone-location-select-label">Timezone</InputLabel>
                        <Select
                          labelId="timezone-location-select-label"
                          id="timezone-select"
                          value={intake.timezone}
                          onChange={handleTimezoneChange}>
                          <MenuItem value={'Australia/Brisbane'}>Brisbane (AEST)</MenuItem>
                          <MenuItem value={'Europe/London'}>London (GMT/BST)</MenuItem>
                          <MenuItem value={'America/Los_Angeles'}>Los Angeles (PST/PDT)</MenuItem>
                          <MenuItem value={'Australia/Melbourne'}>Melbourne (AEST/AEDT)</MenuItem>
                          <MenuItem value={'Etc/UTC'}>UTC (GMT)</MenuItem>
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Tooltip title="This links the BE Core intake to Acuity for tutorial scheduling">
                      <TextField
                        id="acuityCategory"
                        name="acuityCategory"
                        label="Acuity Tutorial Appointment Type Category"
                        value={intake.acuityCategory}
                        onChange={handleAcuityCategoryChange}
                        fullWidth
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tooltip title="Needed for Learning Schedule">
                      <TextField
                        fullWidth
                        id="courseDealine"
                        label="Course Deadline"
                        type="date"
                        value={
                          intake.courseDeadline
                            ? moment(intake.courseDeadline).format('YYYY-MM-DD')
                            : ''
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleCourseDeadlineChange}
                      />
                    </Tooltip>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                &nbsp;
              </Grid>
            </Grid>
          </Container>

          <h2>eLearning</h2>
          <Container maxWidth={'md'} className={styles.section}>
            <Grid container justify={'center'} spacing={2}>
              <Grid item xs={12} md={6}>
                <Tooltip title="Links BE Core Intakes to our LMS. Mostly used for Tutorial Attendance reporting">
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        elearningAudienceList.find((a) => a.id === intake.elearningAudience) ??
                        elearningAudienceList[0]
                      }
                      onChange={(event, newValue) => {
                        setIntake({ ...intake, elearningAudience: newValue?.id as string });
                      }}
                      getOptionLabel={(option) => option.name}
                      options={elearningAudienceList}
                      renderInput={(params) => (
                        <TextField {...params} label="Intake Elearning Audience" fullWidth />
                      )}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
              {isCert() && (
                <>
                  <Grid item xs={12} md={6}>
                    <Tooltip title="Links BE Core Intakes to our LMS. Secondary audience is for the upgraded version of the course  (e.g. comprehensive for certificate).">
                      <FormControl fullWidth>
                        <Autocomplete
                          value={
                            elearningAudienceList.find(
                              (a) => a.id === intake.elearningAudienceSecondary
                            ) ?? elearningAudienceList[0]
                          }
                          onChange={(event, newValue) => {
                            setIntake({
                              ...intake,
                              elearningAudienceSecondary: newValue?.id as string,
                            });
                          }}
                          getOptionLabel={(option) => option.name}
                          options={elearningAudienceList}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Intake Elearning Audience (Comprehensive)"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tooltip title="The ID of the course in the LMS. In Totara, Menu -> BE Staff -> Course Admin -> All Courses, find the course and open it. The URL will look like 'https://elearning.breathe.edu.au/course/view.php?id=133/'. In this case the Id is 133. Supports comma separated Ids for multiple courses contributing to the enrolment, i.e. a program in the LMS.">
                      <TextField
                        id="primaryCourseId"
                        name="primaryCourseId"
                        label="Course Ids"
                        value={intake.elearningPrimaryCourseIds}
                        onChange={handleElearningPrimaryCourseIdsChanged}
                        fullWidth
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Tooltip title="The ID of the secondary course in the LMS. For Certificate the secondary course is the comprehensive version of it, if different. In Totara, Menu -> BE Staff -> Course Admin -> All Courses, find the course and open it. The URL will look like 'https://elearning.breathe.edu.au/course/view.php?id=133/'. In this case the Id is 133. Supports comma separated Ids for multiple courses contributing to the enrolment, i.e. a program in the LMS.">
                      <TextField
                        id="secondaryCourseId"
                        name="secondaryCourseId"
                        label="Course Ids (Comprehensive)"
                        value={intake.elearningSecondaryCourseIds}
                        onChange={handleElearningSecondaryCourseIdsChanged}
                        fullWidth
                      />
                    </Tooltip>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={6}>
                &nbsp;
              </Grid>
            </Grid>
          </Container>

          {isCert() && (
            <>
              <h2>Lectures (Zoom)</h2>
              <Container maxWidth={'md'} className={styles.section}>
                <Grid container justify={'center'} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Tooltip title="Log in to Zoom using the be.courses@breathe.edu.au account and retrieve the relevant Zoom meeting information for this Lecture">
                      <TextField
                        id="joinLiveLink"
                        name="joinLiveLink"
                        label="Join Live Link"
                        value={intake.joinLiveLink}
                        onChange={handleLiveLinkChange}
                        fullWidth
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="joinLiveMeetingId"
                      name="joinLiveMeetingId"
                      label="Meeting ID"
                      value={intake.joinLiveMeetingId}
                      onChange={handleLiveMeetingIdChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="joinLiveMeetingPassword"
                      name="joinLiveMeetingPassword"
                      label="Meeting Password"
                      value={intake.joinLiveMeetingPassword}
                      onChange={handleLiveMeetingPasswordChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    &nbsp;
                  </Grid>
                </Grid>
              </Container>
            </>
          )}

          <h2>Enrolment Caps</h2>
          <Container maxWidth={'md'} className={styles.section}>
            <Grid container justify={'center'} spacing={2}>
              <Grid item xs={12} md={6}>
                <Tooltip title="The total number of enrolled students allowed.">
                  <TextField
                    id="enrolmentCapacityTotal"
                    name="enrolmentCapacityTotal"
                    label="Total"
                    value={intake.enrolmentCapacityTotal ?? ''}
                    onChange={handleEnrolmentCapacityTotal}
                    type="number"
                    fullWidth
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6}>
                <Tooltip title="The number of remaining seats for this intake. This decrements automatically when any of the Keap Products below are purchased.">
                  <TextField
                    id="enrolmentCapacityRemaining"
                    name="enrolmentCapacityRemaining"
                    label="Remaining"
                    value={intake.enrolmentCapacityRemaining ?? ''}
                    onChange={handleEnrolmentCapacityRemaining}
                    type="number"
                    fullWidth
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Container>
          <Tooltip title="The Keap Products that enroll the purchaser to this intake. When any of these products are purchased the remaining capacity (above) is decremented. Not retrospectively applied.">
            <h2>
              Keap Products
              <div className={styles.buttonLike} onClick={onAddKeapProduct}>
                <AddBoxIcon color={'primary'} fontSize="large" />
              </div>
            </h2>
          </Tooltip>

          <Container maxWidth={'md'} className={styles.section}>
            {(intake.keapProducts ?? []).map((keapProduct, index) => (
              <Grid container justify={'center'} spacing={2} key={index}>
                <Grid item xs={10} md={10}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={productList.find((p) => p.id === keapProduct.keapProductId) ?? null}
                      onChange={(event, newValue) => {
                        handleKeapProductChange(index, {
                          id: keapProduct.id,
                          keapProductId: newValue?.id as number,
                        });
                      }}
                      getOptionLabel={(option) => option.product_Name}
                      options={productList}
                      filterOptions={(options: IProductModel[], { inputValue }) =>
                        matchSorter(options, inputValue, {
                          keys: ['product_Name'],
                          threshold: matchSorter.rankings.MATCHES,
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Keap Product Name Search" fullWidth />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => {
                      onRemoveProduct(index);
                    }}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Container>

          <div className={styles.section}>
            {isCert() ? (
              <>
                <Tooltip title="Module definitions used for Learning Schedule. If defaults are no longer correct, please inform DevOps.">
                  <h2>
                    Modules
                    <div className={styles.buttonLike} onClick={onCertAddModule}>
                      <AddBoxIcon color={'primary'} fontSize="large" />
                    </div>
                  </h2>
                </Tooltip>

                <Container maxWidth={'md'} className={styles.section}>
                  {(intake.certModules ?? []).map((module, index) => (
                    <Card key={index} className={styles.lectureCard} variant={'outlined'}>
                      <Grid container justify={'center'}>
                        <Grid item xs={11} md={11}>
                          <FormControl fullWidth>
                            <TextField
                              id="module-title"
                              value={module.title}
                              label="Module Title"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleCertModuleTitleChange(index, event.target.value)
                              }
                            />
                            <TextField
                              id="module-description"
                              value={module.description}
                              multiline
                              rowsMax={6}
                              label="Module Description"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleCertModuleDescriptionChange(index, event.target.value)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={1} md={1}>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            onClick={() => onRemoveCertModule(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </Container>
                <Tooltip title="Add lectures. Lectures are defaulted to a week after last existing lecture. If defaults are incorrect please inform DevOps.">
                  <h2>
                    Lectures
                    <div className={styles.buttonLike} onClick={onAddLecture}>
                      <AddBoxIcon color={'primary'} fontSize="large" />
                    </div>
                  </h2>
                </Tooltip>
                <Container maxWidth={'md'} className={styles.section}>
                  {intake.lectures
                    ? intake.lectures.map((lecture, index) => (
                        <Card key={index} className={styles.lectureCard} variant={'outlined'}>
                          <Grid container justify={'center'}>
                            <Lecture
                              onChange={(i, v) => handleLectureChange(i, v)}
                              index={index}
                              value={lecture}
                              onRemove={onRemoveLecture}
                              timezoneLocation={intake.timezone}
                              // certModules={certModules}
                              certModules={intake.certModules as ICertModuleModel[]}
                            />
                          </Grid>
                        </Card>
                      ))
                    : ''}
                </Container>
              </>
            ) : (
              <>
                <h2>
                  Modules
                  <div className={styles.buttonLike} onClick={onDiplomaAddModule}>
                    <AddBoxIcon color={'primary'} fontSize="large" />
                  </div>
                </h2>

                <Container maxWidth={'md'} className={styles.section}>
                  {intake.diplomaModules
                    ? intake.diplomaModules.map((module, index) => (
                        <Card key={index} className={styles.lectureCard} variant={'outlined'}>
                          <Grid container justify={'center'}>
                            <Grid item xs={11} md={11}>
                              <FormControl fullWidth>
                                <InputLabel id="module-select-label">Module {index + 1}</InputLabel>
                                <Select
                                  labelId="timezone-location-select-label"
                                  id="module-select"
                                  value={module.id}
                                  onChange={(
                                    event: React.ChangeEvent<{
                                      value: unknown;
                                    }>
                                  ) => {
                                    handleDiplomaModuleChange(index, event.target.value as string);
                                  }}>
                                  <MenuItem value={'0'}>Select</MenuItem>
                                  {onlineCoursesModel.diplomaModuleSummaries.map(
                                    (module, index) => (
                                      <MenuItem key={index} value={module.id}>
                                        {module.title}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={1} md={1}>
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => {
                                  onRemoveDiplomaModule(index);
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Card>
                      ))
                    : ''}
                </Container>
              </>
            )}
          </div>

          {isCert() && (
            <>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={styles.accordionSummary}>
                  Grade Book (Certificate)
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.section}>
                    <Tooltip title="Select the Grade Book items relevant to this intake for correct %-Complete calculation.">
                      <h2>Grade Book</h2>
                    </Tooltip>
                    <Container maxWidth={'md'} className={styles.section}>
                      <FormGroup>
                        {Object.entries(_.groupBy(referenceGradeBookItems, 'courseTitle')).map(
                          (course, index: number) => (
                            <div key={index}>
                              <h3>{course[0] ?? 'Title Not Found'}</h3>
                              {course[1].map((gradeBookItem: IGradeBookItemModel, i) => (
                                <div key={i}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            intake.gradeBookItemReferenceIds &&
                                            intake.gradeBookItemReferenceIds.indexOf(
                                              gradeBookItem.id
                                            ) > -1
                                          }
                                          onChange={handleGradeBookItemChange}
                                          name={gradeBookItem.id.toString()}
                                        />
                                      }
                                      label={`${gradeBookItem.sectionName} ${gradeBookItem.itemName}`}
                                    />
                                  </FormGroup>
                                </div>
                              ))}
                            </div>
                          )
                        )}

                        {!intake.elearningPrimaryCourseIds && (
                          <p>
                            eLearning Course IDs is not set above. Unable to set gradebook items
                            until it is.
                          </p>
                        )}

                        {intake.elearningPrimaryCourseIds &&
                          referenceGradeBookItems.length === 0 && (
                            <p>
                              eLearning Course IDs is set. However no reference items exist for
                              course Id {intake.elearningPrimaryCourseIds}. Double check course ID
                              and contact DevOps.
                            </p>
                          )}
                      </FormGroup>
                    </Container>
                  </div>
                </AccordionDetails>
              </Accordion>
              <br />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={styles.accordionSummary}>
                  Grade Book (Comprehensive Certfificate)
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.section}>
                    <Tooltip title="Select the Grade Book items relevant to this intake for correct %-Complete calculation.">
                      <h2>Grade Book</h2>
                    </Tooltip>
                    <Container maxWidth={'md'} className={styles.section}>
                      {Object.entries(
                        _.groupBy(secondaryReferenceGradeBookItems, 'courseTitle')
                      ).map((course, index: number) => (
                        <div key={index}>
                          <h3>{course[0] ?? 'Title Not Found'}</h3>
                          {course[1].map((gradeBookItem: IGradeBookItemModel, i) => (
                            <FormGroup key={i}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      intake.gradeBookItemReferenceIds &&
                                      intake.gradeBookItemReferenceIds.indexOf(gradeBookItem.id) >
                                        -1
                                    }
                                    onChange={handleGradeBookItemChange}
                                    name={gradeBookItem.id.toString()}
                                  />
                                }
                                label={`${gradeBookItem.sectionName} ${gradeBookItem.itemName}`}
                              />
                            </FormGroup>
                          ))}
                        </div>
                      ))}

                      {!intake.elearningSecondaryCourseIds && (
                        <p>
                          eLearning Secondary Course ID is not set above. Unable to set gradebook
                          items until it is.
                        </p>
                      )}

                      {intake.elearningSecondaryCourseIds &&
                        secondaryReferenceGradeBookItems.length === 0 && (
                          <p>
                            eLearning Secondary Course ID is set. However no reference items exist
                            for course Id {intake.elearningSecondaryCourseIds}. Double check course
                            Id and contact DevOps.
                          </p>
                        )}
                    </Container>
                  </div>
                </AccordionDetails>
              </Accordion>
              <br />

              <div className={styles.section}>
                <Tooltip title="Will show first week's tutorial times if Acuity Tutorial Category is set correctly (above).">
                  <h2>Tutorials</h2>
                </Tooltip>
                <Container maxWidth={'md'} className={styles.section}>
                  <TutorialSchedule
                    acuityCategory={intake.acuityCategory}
                    mode={'admin'}
                    userTimezone={moment.tz.guess()}
                    location={'AU'}
                  />
                </Container>
              </div>

              {(intake.id ?? 0) > 0 && (
                <div className={styles.section}>
                  <h2>Control Panel</h2>
                  <Button color="primary" onClick={() => handleToggleDelete()}>
                    {confirmDelete
                      ? "I'm sure (click to continue)"
                      : intake.isDeleted
                      ? 'Restore this Intake'
                      : 'Delete this Intake'}
                  </Button>
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </>
  );
});

interface LectureProps {
  onChange: (index: number, lecture: SnapshotIn<ILectureModel>) => void;
  value: SnapshotIn<ILectureModel>;
  index: number;
  onRemove: (index: number) => void;
  timezoneLocation: string;
  certModules: ICertModuleModel[];
}

const Lecture: React.FC<LectureProps> = observer((props: LectureProps) => {
  const [showStoryTime, setShowStoryTime] = useState<boolean>(false);

  const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, title: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handleDetailsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, details: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handleDurationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, duration: Number(event.target.value) };
    props.onChange(props.index, lecture);
  };

  const handleDateTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, datetime: event.target.value as number };
    props.onChange(props.index, lecture);
  };

  const handleLectureNotesLinkChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, lectureNotesLink: event.target.value as string };
    props.onChange(props.index, lecture);
  };
  const handleLectureRecordingLinkChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, recordingLink: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handleTutorialDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, tutorialDescription: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handleHomeworkDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, homeworkDescription: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handlePracticalTutorialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lecture = { ...props.value!, practicalTutorial: event.target.checked as boolean };
    props.onChange(props.index, lecture);
  };

  const handleLectureCertModuleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const module = props.certModules!.find((c) => c.title === (event.target.value as string));
    const lecture = {
      ...props.value!,
      certModule: module,
    };

    props.onChange(props.index, lecture);
  };

  const formatDateString = (value: Date | number) => {
    if (value <= 0) return '';
    return moment(value).format('YYYY-MM-DDTHH:mm');
  };

  return (
    <>
      <Grid item container xs={11} md={11} spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            id="week"
            name="week"
            label="Week #"
            onChange={handleTitleChange}
            value={props.value?.title}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type={'datetime-local'}
            id="datetime"
            name="datetime"
            label="Date and time"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={formatDateString(props.value?.datetime)}
            onChange={handleDateTimeChange}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            id="timezone"
            name="timezone"
            label="Timezone"
            fullWidth
            value={moment.tz(props.value?.datetime, props.timezoneLocation).format('z')}
            disabled
          />
        </Grid>
        <Grid item xs={6} md={1}>
          <TextField
            id="duration"
            name="duration"
            label="Duration (min.)"
            type={'number'}
            value={props.value?.duration}
            fullWidth
            onChange={handleDurationChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="details"
            name="details"
            label="Details"
            multiline={true}
            fullWidth
            variant={'outlined'}
            value={props.value?.details}
            onChange={handleDetailsChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id="cert-module-label">Cert Module</InputLabel>
            <Select
              labelId="lecture-cert-select-label"
              id="cert-module-select"
              value={props.value.certModule?.title ?? ''}
              onChange={handleLectureCertModuleChange}>
              <MenuItem disabled key={0} value={undefined} />
              {props.certModules?.map((module, index) => (
                <MenuItem key={index} value={module?.title}>
                  {module.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="lectureNotesLink"
            name="lectureNotesLink"
            label="Lecture Notes Link"
            fullWidth
            value={props.value?.lectureNotesLink ?? ''}
            onChange={handleLectureNotesLinkChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="recordingLink"
            name="recordingLink"
            label="Recording Link"
            value={props.value?.recordingLink ?? ''}
            onChange={handleLectureRecordingLinkChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="tutorialDescription"
            name="tutorialDescription"
            label="Tutorial Description"
            fullWidth
            value={props.value?.tutorialDescription ?? ''}
            onChange={handleTutorialDescriptionChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="homeworkDescription"
            name="homeworkDescription"
            label="Homework Description"
            fullWidth
            value={props.value?.homeworkDescription ?? ''}
            onChange={handleHomeworkDescriptionChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.value.practicalTutorial ?? false}
                  onChange={handlePracticalTutorialChange}
                />
              }
              label="Practical tutorial"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={1} md={1}>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => props.onRemove(props.index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <InputLabel id="story-time-label">
          Story Time{' '}
          {!showStoryTime && (
            <Button
              onClick={() => {
                setShowStoryTime(true);
              }}>
              Show
            </Button>
          )}
        </InputLabel>

        {showStoryTime && !!props.value.id && (
          <>
            <ol>
              <li>
                Unique link:
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.origin}/public/student/story-time/cert/${props.value.id}`}>{`${window.location.origin}/public/student/story-time/cert/${props.value.id}`}</a>
              </li>
              <li>
                <span>
                  Totara embed code: <TotaraEmbedCodeCopyToClipBoard /> (update URL NAME placeholder
                  to match Link Name)
                </span>
              </li>
              <li>Add "email" URL variable</li>
              <li>Appearance - Display - New Window</li>
            </ol>
          </>
        )}

        {showStoryTime && !props.value.id && (
          <span>Intake must be saved before Story Time link is available.</span>
        )}
      </Grid>
    </>
  );
});
