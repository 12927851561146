import { flow, Instance, types } from 'mobx-state-tree';
import { getEnv } from './util';

export const FormModel = types.model('FormModel', {
  id: types.optional(types.number, 0),
  email: types.string,
  formType: types.string,
  formData: types.string,
  createdTimestamp: types.string,
});

export interface IFormModel extends Instance<typeof FormModel> {}

export const FormStore = types
  .model('FormStore', {})
  .actions((self) => ({
    saveForm: flow(function* (
      email: string,
      formData: object,
      formId: number | null,
      formType: string
    ) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax
          .post(`PublicForm/form`, {
            json: { formData: JSON.stringify(formData), formId, formType, email },
          })
          .json<boolean>();
      } catch (error) {
        console.log(`error PublicForm/${email} error: ${error}`);
      }
    }),
    getForm: flow(function* (email: string, formType: string) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax.get(`PublicForm/form/${email}/${formType}`).json<IFormModel>();
      } catch (error) {
        console.log(`error PublicForm/${email}/${formType} error: ${error}`);
      }
    }),
  }))
  .views((self) => ({}));
