import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks';
import { IIntakeWarning } from '../../../domain/store/OnlineCoursesModel';
import _ from 'lodash';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Backdrop, CircularProgress, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from '../student/Student.module.scss';

export const Dashboard: React.FC = observer(function () {
  const { onlineCoursesModel } = useStore();
  const [showSpinner, setShowSpinner] = useState<boolean>(true);

  useEffect(() => {
    setShowSpinner(true);
    onlineCoursesModel
      .loadDashboardWarnings()
      .then(() => {})
      .finally(() => {
        setShowSpinner(false);
      });
  }, [onlineCoursesModel]);

  return (
    <section>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <h1>Dashboard</h1>
      {Object.entries(
        _.groupBy(onlineCoursesModel.dashboardWarnings.intakeWarnings, 'intakeId')
      ).map(([intakeId, intakeWarnings], i) => (
        <div key={i}>
          <br />
          <h3>
            <Link to={'/admin/intakes/edit/' + intakeWarnings[0].intakeId}>
              {intakeWarnings[0].intakeTitle}
            </Link>
          </h3>
          <Paper>
            {intakeWarnings.map((intakeWarning: IIntakeWarning, i) => (
              <Alert severity="warning" key={i}>
                <AlertTitle>{intakeWarning.warning}</AlertTitle>
                {intakeWarning.impact}
              </Alert>
            ))}
          </Paper>
        </div>
      ))}
    </section>
  );
});
