import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App } from 'views/App';
import { createBrowserHistory } from 'history';
import { getDefaultStore } from 'domain/store/RootStoreModel';
import { AuthenticationProvider } from 'auth/AuthenticationProvider';
import { IAuthenticationProvider } from 'auth/AuthenticationTypes';
import { AUTHORITY, CLIENT_ID } from 'appSettings';

export const auth: IAuthenticationProvider = new AuthenticationProvider(
  {
    auth: {
      clientId: CLIENT_ID,
      authority: AUTHORITY,
      validateAuthority: true,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
  },
  {
    scopes: ['openid', 'profile'],
  }
);

const history = createBrowserHistory();
const store = getDefaultStore(auth);

ReactDOM.render(
  <App history={history} store={store} auth={auth} />,
  document.getElementById('root')
);
