import React, { forwardRef } from 'react';
import { Navigate } from 'react-big-calendar';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export const CustomCalendarToolbar: React.FC<any> = ({
  date,
  view,
  views,
  onView,
  onNavigate,
  messages,
  label,
}) => {
  const handleDateSelected = (date: Date | null) => {
    onNavigate('DATE', date);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className="calendar-datepicker" onClick={onClick} ref={ref}>
      {label}
    </button>
  ));

  return (
    <div className={`rbc-toolbar`}>
      <span className="rbc-toolbar-label">
        <button type="button" onClick={() => onNavigate(Navigate.PREVIOUS)} aria-label={'Previous'}>
          &#60;
        </button>
        <DatePicker
          selected={date}
          minDate={new Date()}
          onChange={handleDateSelected}
          customInput={<ExampleCustomInput className="calendar-datepicker" />}
        />
        <button type="button" onClick={() => onNavigate(Navigate.NEXT)} aria-label={'Next'}>
          &#62;
        </button>
      </span>
      {/*<span className={'rbc-btn-group'}>*/}

      {/*    <button*/}
      {/*        type="button"*/}
      {/*        onClick={() => onNavigate(Navigate.TODAY)}*/}
      {/*        aria-label={"Today"}*/}
      {/*    >*/}
      {/*      {view === 'month' ? 'This Month' : 'Today'}*/}
      {/*    </button>*/}

      {/*</span>*/}
    </div>
  );
};
