import React from 'react';
import styles from './App.module.scss';
import { Route, Router, Switch } from 'react-router-dom';
import { History } from 'history';
import { AuthenticatedRoutes, PublicRoutes } from 'views/routes/Routes';
import { IRootStoreModel } from 'domain/store/RootStoreModel';
import { NotFound } from './routes/notFound/NotFound';
import { IAuthenticationProvider } from '../auth/AuthenticationTypes';
import TelemetryProvider from './components/TelemetryProvider';

export const StoreContext = React.createContext<IRootStoreModel | undefined>(undefined);

interface IAppProps {
  history: History;
  store: IRootStoreModel;
  auth: IAuthenticationProvider;
}

export const App: React.FC<IAppProps> = function ({ history, store, auth }) {
  return (
    <div className={styles.root}>
      <StoreContext.Provider value={store}>
        <Router history={history}>
          <TelemetryProvider
            instrumentationKey="f29ba314-721d-4cbb-8a19-11468d868d37"
            after={() => {
              // appInsights = getAppInsights()
            }}>
            <Switch>
              <Route path="/admin">
                <Router history={history}>
                  <AuthenticatedRoutes auth={auth} />
                </Router>
              </Route>
              <Route path="/public">
                <Router history={history}>
                  <PublicRoutes />
                </Router>
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </TelemetryProvider>
        </Router>
      </StoreContext.Provider>
    </div>
  );
};
