import React, { useEffect, useState } from 'react';
import { useStore } from 'views/hooks';
import styles from './Student.module.scss';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Grid,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import _ from 'lodash';
import moment from 'moment';
import { LogbookManage } from '../../public/Logbook/LogbookManage';
import { Gradebook } from '../../public/Gradebook/Gradebook';
import { StudentNotes } from './notes/studentNotes';
import StudentCustomFields from './customFields/StudentCustomFields';
import { StudentTags } from './tags/StudentTags';

export const Student: React.FC = observer(function () {
  const { email } = useParams<{ email: string }>();
  const { studentStore } = useStore();
  const [expandedEnrolmentKey, setExpandedEnrolmentKey] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);

  useEffect(() => {
    setShowSpinner(true);
    studentStore
      .loadStudent(email)
      .then(() => {})
      .finally(() => {
        setShowSpinner(false);
      });
  }, [email, studentStore]);

  const student = studentStore.student;

  return (
    <div className={styles.container}>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {student && (
        <>
          <Grid container>
            <Grid item sm={12} md={4}>
              <h1>
                {student.contact?.firstName} {student.contact?.lastName}
              </h1>
              <div>
                <a
                  href={
                    'https://yb303.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=' +
                    student.contact?.id
                  }
                  target={'_blank'}
                  rel="noreferrer">
                  Keap
                </a>{' '}
                |{' '}
                <a
                  href={
                    'https://elearning.breathe.edu.au/user/profile.php?id=' + student.lmsId_totara
                  }
                  target={'_blank'}
                  rel="noreferrer">
                  Totara
                </a>
              </div>
              <div>
                {student?.email && (
                  <>
                    email: <a href={'mailto:' + student.email}>{student?.email}</a>
                  </>
                )}
              </div>
              <div>
                {(student?.contact?.phone || student?.contact?.phone2) && (
                  <>
                    tel:{' '}
                    <a href={'tel:' + (student?.contact?.phone || student?.contact?.phone2)}>
                      {student?.contact?.phone || student?.contact?.phone2}
                    </a>
                  </>
                )}
              </div>
              <div>{student.contact?.location && <>Location: {student?.contact?.location}</>}</div>
              <div>{student.contact?.timezone && <>Timezone: {student?.contact?.timezone}</>}</div>
              <div>
                {student.contact?.lastRefreshDateTime && (
                  <>CRM last sync: {moment(student?.contact?.lastRefreshDateTime).fromNow()}</>
                )}
              </div>
              <div>
                LMS last logged in:{' '}
                {student.lastLoggedIn ? moment(student.lastLoggedIn).fromNow() : 'unknown'}
              </div>
            </Grid>
            <Grid item sm={12} md={8}>
              <StudentNotes />
              <StudentCustomFields scope={'Student'} />
              <StudentTags scope={'Student'} />
            </Grid>
          </Grid>

          <div className={styles.enrolments}>
            {student?.intakeEnrolments &&
              student.intakeEnrolments.map((intakeEnrollment, i) => (
                <Accordion
                  key={i}
                  expanded={expandedEnrolmentKey === i}
                  onChange={() => {
                    setExpandedEnrolmentKey(expandedEnrolmentKey === i ? -1 : i);
                  }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.accordionSummary}>
                    <h2>
                      {intakeEnrollment.intake?.title}{' '}
                      {_.some(
                        intakeEnrollment.courseEnrolments,
                        (c) =>
                          c.elearningAudience ===
                          intakeEnrollment.intake?.elearningAudienceSecondary
                      ) && ' (Comprehensive)'}
                    </h2>
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetails}>
                    <Grid container>
                      <>
                        <Grid container>
                          <Grid item sm={6} md={6}>
                            <Tooltip
                              title={
                                (intakeEnrollment.graduationCriteriaMetTimestamp
                                  ? 'Met: ' +
                                    moment(
                                      intakeEnrollment.graduationCriteriaMetTimestamp
                                    ).fromNow() +
                                    ' '
                                  : '') +
                                'Last checked: ' +
                                moment(
                                  intakeEnrollment.graduationEligibilityLastCheckedTimestamp
                                ).fromNow()
                              }>
                              <span>
                                Eligible to graduate:{' '}
                                {!!intakeEnrollment.graduationCriteriaMetTimestamp ? 'Yes' : 'No'}
                              </span>
                            </Tooltip>
                          </Grid>
                          <Grid item sm={6} md={6}>
                            <StudentTags scope={'Intake'} />
                          </Grid>
                        </Grid>

                        <Grid item md={12} lg={6}>
                          <Accordion className={styles.accordion}>
                            <AccordionSummary className={styles.accordionSummary}>
                              Grade book - {intakeEnrollment.intakeCompletionPercentage}%
                              {intakeEnrollment.courseEnrolments.length > 1 && (
                                <>&nbsp;({intakeEnrollment.courseEnrolments.length} courses)</>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item sm={12} md={12}>
                                  {intakeEnrollment.courseEnrolments &&
                                    intakeEnrollment.courseEnrolments.map((courseEnrollment, i) => (
                                      <Accordion key={i} className={styles.accordion}>
                                        <AccordionSummary className={styles.accordionSummary}>
                                          {courseEnrollment.courseTitle}
                                          {' - '}
                                          {Math.round(
                                            (_.sum(
                                              courseEnrollment.gradebookForStudent.map(
                                                (x: any) => x.completionPercentage
                                              )
                                            ) /
                                              (courseEnrollment.gradebookForStudent.length * 100)) *
                                              100
                                          )}
                                          % &nbsp;{' '}
                                          <a
                                            href={`https://elearning.breathe.edu.au/user/view.php?id=${student.lmsId_totara}}&course=${courseEnrollment.lmsCourseId}`}
                                            target={'_blank'}
                                            rel="noreferrer">
                                            Open in Totara
                                          </a>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid container>
                                            <Grid item sm={12} md={12}>
                                              <Gradebook
                                                gradebookItems={
                                                  courseEnrollment.gradebookForStudent
                                                }
                                              />
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    ))}
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                        {intakeEnrollment.hasLogbook() && (
                          <Grid item md={12} lg={6}>
                            <Accordion className={styles.accordion}>
                              <AccordionSummary className={styles.accordionSummary}>
                                Logbook
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container>
                                  <Grid item md={12}>
                                    <LogbookManage
                                      email={email}
                                      isComprehensive={_.some(
                                        intakeEnrollment.courseEnrolments,
                                        (c) =>
                                          c.elearningAudience ===
                                          intakeEnrollment.intake?.elearningAudienceSecondary
                                      )}
                                      enableWrittenReflection={intakeEnrollment.intake!.id >= 27}
                                      courseType={
                                        '10721NAT Certificate IV in Pilates Matwork and Reformer'
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        )}

                        {!intakeEnrollment.hasLogbook() && <Grid item md={12} lg={6}></Grid>}

                        <Grid item sm={12} md={12} lg={6}>
                          <Accordion className={styles.accordion}>
                            <AccordionSummary className={styles.accordionSummary}>
                              Story Time
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item md={12}>
                                  Coming soon...
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>

                        <Grid item sm={12} md={12} lg={6}>
                          <Accordion className={styles.accordion}>
                            <AccordionSummary className={styles.accordionSummary}>
                              Tutorial Attendance
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                <Grid item md={12}>
                                  Coming soon...
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </>
      )}
    </div>
  );
});
