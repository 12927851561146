import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { ICustomFieldModel } from '../../../domain/store/SettingsStore';
import styles from './Settings.module.scss';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

export const CustomFields = () => {
  const { settingsStore } = useStore();

  const [fields, setFields] = useState<ICustomFieldModel[]>([]);

  const initialFieldState = {
    id: 0,
    fieldName: '',
    fieldDescription: '',
    fieldType: '',
    fieldScope: '',
    deletedBy: null,
    deletedTimestamp: null,
    createdBy: null,
    createdTimestamp: null,
  };

  const [newField, setNewField] = useState<ICustomFieldModel>(initialFieldState);
  const [editField, setEditField] = useState<ICustomFieldModel | null>(null);

  useEffect(() => {
    settingsStore.getCustomFields().then(() => {
      setFields(settingsStore.customFields);
    });
  }, [settingsStore]);

  const addNewCustomField = async () => {
    settingsStore.upsertCustomField(newField).then((fieldId: number) => {
      newField.id = fieldId;
      setFields([...fields, newField]);
      setNewField(initialFieldState);
    });
  };

  const deleteField = async (fieldId: number) => {
    try {
      const success = settingsStore.deleteCustomField(fieldId);
      if (success) setFields(fields.filter((field: ICustomFieldModel) => field.id !== fieldId));
    } catch (error) {
      console.error(error);
    }
  };

  const editMode = (field: ICustomFieldModel) => {
    setEditField(field);
  };

  const cancelEdit = () => {
    setEditField(null);
  };

  const updateField = async (updatedField: ICustomFieldModel) => {
    try {
      const success = settingsStore.upsertCustomField(updatedField).then((fieldId: number) => {
        if (success) {
          const updatedFields = fields.map((f: ICustomFieldModel) =>
            f.id === updatedField.id ? updatedField : f
          );
          setFields(updatedFields);
          setEditField(null);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Custom Fields</h1>

      <TableContainer component={Paper}>
        <Table className={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Field Type</TableCell>
              <TableCell>Field Scope</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field) => (
              <TableRow key={field.id}>
                {editField?.id === field.id ? (
                  <>
                    <TableCell component="th" scope={'row'}>
                      <input
                        type="text"
                        value={editField.fieldName}
                        onChange={(e) =>
                          setEditField({
                            ...editField,
                            fieldName: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <textarea
                        value={editField.fieldDescription}
                        onChange={(e) =>
                          setEditField({
                            ...editField,
                            fieldDescription: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {editField.fieldType}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {editField.fieldScope}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <button onClick={() => updateField(editField)}>Save</button>
                      <button onClick={cancelEdit}>Cancel</button>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell component="th" scope={'row'}>
                      {field.fieldName}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {field.fieldDescription}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {field.fieldType}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      {field.fieldScope}
                    </TableCell>
                    <TableCell component="th" scope={'row'}>
                      <button onClick={() => deleteField(field.id)}>Delete</button>
                      <button onClick={() => editMode(field)}>Edit</button>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <h3>Add New Field</h3>
      <div>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <label>
                    Field Name:
                    <br />
                    <input
                      type="text"
                      value={newField.fieldName}
                      onChange={(e) => setNewField({ ...newField, fieldName: e.target.value })}
                    />
                  </label>
                </TableCell>
                <TableCell>
                  <label>
                    Field Description:
                    <br />
                    <textarea
                      value={newField.fieldDescription}
                      onChange={(e) =>
                        setNewField({ ...newField, fieldDescription: e.target.value })
                      }
                    />
                  </label>
                </TableCell>
                <TableCell>
                  <label>
                    Field Type:
                    <br />
                    <select
                      value={newField.fieldType}
                      onChange={(e) => setNewField({ ...newField, fieldType: e.target.value })}>
                      <option value="">Select</option>
                      <option value="Text">Text</option>
                      <option value="Date">Date</option>
                      <option value="Checkbox">Checkbox</option>
                      <option value="Number">Number</option>
                    </select>
                  </label>
                </TableCell>
                <TableCell>
                  <label>
                    Field Scope:
                    <br />
                    <select
                      value={newField.fieldScope}
                      onChange={(e) => setNewField({ ...newField, fieldScope: e.target.value })}>
                      <option value="">Select</option>
                      <option value="Intake">Intake</option>
                      <option value="Student">Student</option>
                    </select>
                  </label>
                </TableCell>
                <TableCell>
                  <button
                    onClick={addNewCustomField}
                    disabled={
                      !newField.fieldName ||
                      !newField.fieldType ||
                      !newField.fieldScope ||
                      !newField.fieldDescription
                    }>
                    Save New Field
                  </button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
