import React, { useEffect } from 'react';
import { useStore } from '../../../hooks';
import { Checkbox, FormControl, Grid, Input, ListItemText, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { observer } from 'mobx-react-lite';
import styles from './StudentTags.module.scss';
import { ITagModel } from 'domain/store/SettingsStore';
import WarningIcon from '@material-ui/icons/Warning';

interface StudentTagsProps {
  scope: 'Student' | 'Intake';
}

export const StudentTags = observer((props: StudentTagsProps) => {
  const { settingsStore, studentStore } = useStore();
  const [tagSettings, setTagSettings] = React.useState<ITagModel[]>([]);
  const [studentTags, setStudentTags] = React.useState<number[]>([]);

  useEffect(() => {
    settingsStore.getTags().then(() => {
      setTagSettings(settingsStore.tags.filter((t) => t.fieldScope === props.scope));
    });
  }, [setTagSettings, settingsStore, props.scope]);

  useEffect(() => {
    if (!tagSettings) {
      return;
    }

    setStudentTags(
      studentStore.student?.tags
        .filter(
          (t) =>
            tagSettings.findIndex((ts) => ts.id === t.tagId && ts.fieldScope === props.scope) > -1
        )
        .map((t) => t.tagId) || []
    );
  }, [tagSettings, props.scope, studentStore.student?.tags]);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    var existingTags = studentTags;
    var newTags = event.target.value as number[];
    var addedTags = newTags.filter((t) => existingTags.findIndex((et) => et === t) === -1);
    var removedTags = existingTags.filter((t) => newTags.findIndex((nt) => nt === t) === -1);

    addedTags.forEach((t) => {
      studentStore.addStudentTag(t);
    });

    removedTags.forEach((t) => {
      studentStore.deleteStudentTag(t);
    });

    setStudentTags(event.target.value as number[]);
  };

  if (tagSettings.length === 0)
    return (
      <div className={styles.container}>
        <h3>Tags</h3>
        No Tags have been configured. See Settings.
      </div>
    );

  return (
    <div className={styles.container}>
      <h3>Tags</h3>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <Select
              id="tags-checkbox"
              multiple
              value={studentTags}
              onChange={handleChange}
              input={<Input />}
              renderValue={(selected) =>
                (selected as number[]).map((id, key) => (
                  <span className={styles.tag} key={key}>
                    {tagSettings.find((ts) => ts.id === id)!.tag}{' '}
                    {tagSettings.find((ts) => ts.id === id)!.level === 'Warn' && <WarningIcon />}
                  </span>
                ))
              }
              className={styles.select}
              fullWidth={true}>
              {tagSettings?.map((tag, index) => (
                <MenuItem key={index} value={tag.id}>
                  <Checkbox
                    checked={
                      studentTags.findIndex((st: number) => {
                        return st === tag.id;
                      }) > -1
                    }
                  />
                  <ListItemText primary={tag.tag} /> {tag.level === 'Warn' && <WarningIcon />}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
});
