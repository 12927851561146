// xx@ts-nocheck - may need to be at the start of file

import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Timer from './Timer';
import Countdown from './CountdownTimer';
// @ts-ignore
import styled from 'styled-components';
import StopButton from './StopButton';

export const CustomVideoRecorderActions = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  showReplayControls,
  replayVideoAutoplayAndLoopOff,
  useVideoInput,

  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onPauseRecording,
  onResumeRecording,
  onStopReplaying,
  onConfirm,

  uploadVideo,
  switchToUploadFile,
}: {
  isVideoInputSupported?: boolean;
  isInlineRecordingSupported?: boolean;
  thereWasAnError?: boolean;
  isRecording?: boolean;
  isCameraOn?: boolean;
  streamIsReady?: boolean;
  isConnecting?: boolean;
  isRunningCountdown?: boolean;
  countdownTime?: number;
  timeLimit?: number;
  showReplayControls?: boolean;
  replayVideoAutoplayAndLoopOff?: boolean;
  isReplayingVideo?: boolean;
  useVideoInput?: boolean;

  onTurnOnCamera?: () => void;
  onTurnOffCamera?: () => void;
  onOpenVideoInput?: () => void;
  onStartRecording?: () => void;
  onStopRecording?: () => void;
  onPauseRecording?: () => void;
  onResumeRecording?: () => void;
  onStopReplaying?: () => void;
  onConfirm?: () => void;

  uploadVideo?: () => void;
  switchToUploadFile: () => void;
}) => {
  const [uploadStarted, setUploadStared] = useState<boolean>(false);

  const renderContent = () => {
    const shouldUseVideoInput = !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <>
          {!uploadStarted && (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setUploadStared(true);
                  uploadVideo && uploadVideo();
                  return;
                }}
                data-qa="start-replaying">
                Use this video
              </Button>
              <br />

              <Button variant="contained" onClick={onStopReplaying} data-qa="start-replaying">
                Record another video
              </Button>
              <br />
              <Button variant="contained" onClick={switchToUploadFile} data-qa="upload-file">
                Choose from gallery
              </Button>
              <br />
              <br />
              <Button
                variant="contained"
                onClick={() => {
                  window.history.back();
                  window.close();
                }}>
                Back
              </Button>
            </>
          )}
        </>
      );
    }

    if (isRecording) {
      return (
        <StopButton
          variant="contained"
          onClick={onStopRecording}
          data-qa="stop-recording"></StopButton>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <>
          <Button variant="contained" onClick={onStartRecording} data-qa="start-recording">
            Start Recording
          </Button>
          <br />
          <Button variant="contained" onClick={switchToUploadFile} data-qa="upload-file">
            Choose from gallery
          </Button>
          <br />
          <br />
          <Button
            variant="contained"
            onClick={() => {
              window.history.back();
              window.close();
            }}>
            Back
          </Button>
        </>
      );
    }

    if (useVideoInput) {
      return (
        <Button variant="contained" onClick={onOpenVideoInput} data-qa="open-input">
          Upload a video
        </Button>
      );
    }

    return shouldUseVideoInput ? (
      <>
        <Button variant="contained" onClick={onOpenVideoInput} data-qa="open-input">
          Record a video
        </Button>
        <br />
        <Button variant="contained" onClick={switchToUploadFile} data-qa="upload-file">
          Choose from gallery
        </Button>
        <br />
        <br />
        <Button
          variant="contained"
          onClick={() => {
            window.history.back();
            window.close();
          }}>
          Back
        </Button>
      </>
    ) : (
      <Button variant="contained" onClick={onTurnOnCamera} data-qa="turn-on-camera">
        Turn my camera ON
      </Button>
    );
  };

  // @ts-ignore
  const Root = styled.div`
    position: absolute;
    top: 50%;
    font-size: 28px;
    text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
  `;

  return (
    <>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <Root>{renderContent()}</Root>
    </>
  );
};
