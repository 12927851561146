import React, { useEffect, useState } from 'react';
import styles from './TutorialAttendance.module.scss';
import { useStore } from '../../hooks';
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextareaAutosize,
} from '@material-ui/core';
import {
  ITutorialAttendanceCaseModel,
  ITutorialAttendanceCaseStatusModel,
  TutorialAttendanceCaseStatusType,
} from 'domain/store/TutorialAttendanceModel';
import { Close, MoreVert } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import SearchBar from 'material-ui-search-bar';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const TutorialAttendance: React.FC = function () {
  interface ICases extends ITutorialAttendanceCaseModel {
    isSelected: boolean;
    displayTime: boolean;
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && <Box p={2}>{children}</Box>}
      </div>
    );
  }

  const { tutorialAttendanceModel } = useStore();
  const [cases, setCases] = useState<ICases[]>([]);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [openCloseDialog, setOpenCloseDialog] = React.useState(false);
  const [
    openNonAttendanceReasonAndFeePaymentDialog,
    setOpenNonAttendanceReasonAndFeePaymentDialog,
  ] = React.useState(false);
  const [isFeePaymentRequired, setIsFeePaymentRequired] = React.useState(false);
  const [closureComment, setClosureComment] = React.useState('');
  const [nonAttendanceReason, setNonAttendanceReason] = React.useState<string>('');
  const [selectedCase, setSelectedCase] = React.useState<ITutorialAttendanceCaseModel>();
  const [openHistoryDialog, setOpenHistoryDialog] = React.useState(false);
  const [attendanceCaseHistory, setAttendanceCaseHistory] = React.useState<
    ITutorialAttendanceCaseStatusModel[]
  >();
  const [page, setPage] = React.useState(1);
  const [tabValue, setTabValue] = React.useState(0);
  const pageSize = 10;
  const confirm = useConfirm();
  const [returnClosedCasesOnly, setReturnClosedCasesOnly] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [menuPosition, setMenuPosition] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  const loadCases = () => {
    setShowSpinner(true);
    tutorialAttendanceModel
      .getAttendanceCasesList(returnClosedCasesOnly, pageSize, searchValue ? 1 : page, searchValue)
      .then(() => {
        const displayCases: ICases[] = tutorialAttendanceModel.cases.map((c) => {
          return { ...c, isSelected: false, displayTime: false };
        });
        setCases(displayCases);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    setShowSpinner(true);
    tutorialAttendanceModel
      .getAttendanceCasesList(returnClosedCasesOnly, pageSize, page)
      .then(() => {
        const displayCases: ICases[] = tutorialAttendanceModel.cases.map((c) => {
          return { ...c, isSelected: false, displayTime: false };
        });
        setCases(displayCases);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  }, [tutorialAttendanceModel, returnClosedCasesOnly, pageSize, page]);

  const toggleDisplayTime = (c: ICases) => {
    const updatedCases = cases.map((oc) => {
      if (oc.id === c.id) {
        return {
          ...oc,
          displayTime: !c.displayTime,
        };
      }
      return oc;
    });
    setCases(updatedCases);
  };

  const toggleSelectCase = (c: ICases) => {
    const updatedCases = cases.map((oc) => {
      if (oc.id === c.id) {
        return {
          ...oc,
          isSelected: !c.isSelected,
        };
      }
      return oc;
    });
    setCases(updatedCases);
  };

  const clearCasesSelection = () => {
    if (cases) {
      const updatedCases = cases.map((oc) => {
        oc.isSelected = false;
        return oc;
      });
      setCases(updatedCases);
    }
  };

  const closeMenu = () => {
    setMenuPosition({ mouseX: null, mouseY: null });
  };

  const closeCases = () => {
    setShowSpinner(true);
    const selectedCasesIds = selectedCase
      ? [selectedCase.id]
      : cases.filter((c) => c.isSelected).map((c) => c.id);

    tutorialAttendanceModel
      .closeCases(selectedCasesIds, closureComment)
      .then(() => {
        //closing the menu first to prevent error when list of cases change
        closeMenu();

        const updatedCases = cases.filter(
          (c) => selectedCasesIds.findIndex((s) => c.id === s) === -1
        );
        setCases(updatedCases);
      })
      .finally(() => {
        setOpenCloseDialog(false);
        setClosureComment('');
        setShowSpinner(false);
      });
  };

  const updateNonAttendanceReasonAndFeePayment = () => {
    setShowSpinner(true);
    tutorialAttendanceModel
      .setNonAttendanceReasonAndFeePayment(
        selectedCase!.id,
        nonAttendanceReason,
        isFeePaymentRequired
      )
      .then((updatedModel: ITutorialAttendanceCaseModel) => {
        //closing the menu first to prevent error when list of cases change
        closeMenu();
        const updatedCases = cases.map((c) => {
          if (c.id === updatedModel.id) {
            return updatedModel as ICases;
          }

          return c;
        });

        setCases(updatedCases);
      })
      .finally(() => {
        setOpenNonAttendanceReasonAndFeePaymentDialog(false);
        setNonAttendanceReason('');
        setShowSpinner(false);
      });
  };

  const setFeeManuallyPaid = () => {
    setShowSpinner(true);
    selectedCase &&
      tutorialAttendanceModel
        .setStatusType(selectedCase.id, TutorialAttendanceCaseStatusType.FeeManuallyProcessed)
        .then((updatedModel: ITutorialAttendanceCaseModel) => {
          const updatedCases = cases.map((c) => {
            if (c.id === updatedModel.id) {
              return updatedModel as ICases;
            }

            return c;
          });

          setCases(updatedCases);
        })
        .finally(() => {
          closeMenu();
          setShowSpinner(false);
        });
  };

  const sendMakeUpBookingEmail = () => {
    setShowSpinner(true);
    selectedCase &&
      tutorialAttendanceModel
        .sendTutorialMakeUpBookingEmail(selectedCase.id)
        .then(() => {
          tutorialAttendanceModel
            .getAttendanceCase(selectedCase.id)
            .then((updatedModel: ITutorialAttendanceCaseModel) => {
              const updatedCases = cases.map((c) => {
                if (c.id === updatedModel.id) {
                  return updatedModel as ICases;
                }

                return c;
              });

              setCases(updatedCases);
            })
            .catch((error) =>
              console.log('an error has occurred while sending rebooking email', error)
            )
            .finally(() => {
              setShowSpinner(false);
              closeMenu();
            });
        })
        .catch((error) => {
          console.log('an error has occurred while sending rebooking email', error);
          setShowSpinner(false);
        });
  };

  const showAttendanceCaseHistory = (attendanceCase: ICases) => {
    setShowSpinner(true);
    setSelectedCase(attendanceCase);
    tutorialAttendanceModel
      .getAttendanceCaseHistory(attendanceCase.id)
      .then((value) => {
        setAttendanceCaseHistory(value);
        setOpenHistoryDialog(true);
        setShowSpinner(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setReturnClosedCasesOnly(newValue === 1);
    setPage(1);

    if (
      newValue === 2 &&
      tutorialAttendanceModel.studentsNotBookedForCurrentTutorialWeek?.length === 0
    ) {
      setShowSpinner(true);
      tutorialAttendanceModel
        .getStudentsNotBookedForTutorialWeek()
        .catch((err) => console.error(err))
        .finally(() => setShowSpinner(false));
    }
  };

  return (
    <div className={styles.container}>
      <Backdrop className={styles.backdrop} open={showSpinner}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <h1>Tutorial Attendance</h1>
      <Box mt={3} mb={3} width="50%">
        <SearchBar
          placeholder="Search students name or email"
          value={searchValue}
          onChange={(newValue) => setSearchValue(newValue)}
          onCancelSearch={() => loadCases()}
          onRequestSearch={() => {
            loadCases();
          }}
        />
      </Box>
      <Box mt={2}>
        <Paper square>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="upcoming and past">
            <Tab label="Open Cases"></Tab>
            <Tab label="Closed Cases"></Tab>
            <Tab label="not booked this week"></Tab>
          </Tabs>
        </Paper>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Box>
          <ButtonGroup color="primary" aria-label="cases action button group">
            <Button
              disabled={!cases.some((c) => c.isSelected)}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setOpenCloseDialog(true);
              }}
              startIcon={<Close />}>
              Close Cases
            </Button>
          </ButtonGroup>
        </Box>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Intake</TableCell>
              <TableCell>Tutorial Week</TableCell>
              <TableCell width={200}>Status</TableCell>
              <TableCell>Status Since</TableCell>
              <TableCell>Trainer</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cases.map((c) => (
              <TableRow key={c.id}>
                <TableCell>
                  <Checkbox checked={c.isSelected} onChange={() => toggleSelectCase(c)} />
                </TableCell>
                <TableCell>
                  <Link to={`/admin/student/${c.email}`}>
                    {c.firstName} {c.lastName}
                  </Link>
                  &nbsp;
                  <Link
                    className={styles.openInNewIcon}
                    target={'_blank'}
                    to={`/admin/student/${c.email}`}>
                    <OpenInNewIcon />
                  </Link>
                </TableCell>
                <TableCell>{c.email}</TableCell>
                <TableCell>{c.intakeTitle}</TableCell>
                <TableCell>
                  {c.tutorialWeek === 0 ? 'Orientation Week' : 'Week ' + c.tutorialWeek}
                </TableCell>
                <TableCell>
                  {c.currentStatus.status.replace(/([A-Z])/g, ' $1').trim()}
                  {(c.currentStatus.status === TutorialAttendanceCaseStatusType.New ||
                    c.currentStatus.status ===
                      TutorialAttendanceCaseStatusType.NewStudentNotFoundInTutorialElearningAudience) &&
                    ` (${c.tutorialAttendanceCaseType.replace(/([A-Z])/g, ' $1').trim()})`}
                </TableCell>
                <TableCell onClick={() => toggleDisplayTime(c)}>
                  {c.displayTime
                    ? c.currentStatus.createdDate.format('dddd, MMMM Do YYYY h:mm a Z')
                    : c.currentStatus.createdDate.fromNow()}
                </TableCell>
                <TableCell>{c.trainer}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="actions"
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      clearCasesSelection();
                      setSelectedCase(c);
                      setMenuPosition({ mouseX: event.clientX, mouseY: event.clientY });
                    }}>
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Student</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Intake</TableCell>
              <TableCell>Tutorial Week</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Status Since</TableCell>
              <TableCell>Trainer</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cases.map((c) => (
              <TableRow key={c.id}>
                <TableCell>
                  {c.firstName} {c.lastName}
                </TableCell>
                <TableCell>{c.email}</TableCell>
                <TableCell>{c.intakeTitle}</TableCell>
                <TableCell>
                  {c.tutorialWeek === 0 ? 'Orientation Week' : 'Week ' + c.tutorialWeek}
                </TableCell>
                <TableCell>
                  {c.currentStatus.status.replace(/([A-Z])/g, ' $1').trim()} (
                  {c.tutorialAttendanceCaseType.replace(/([A-Z])/g, ' $1').trim()})
                </TableCell>
                <TableCell onClick={() => toggleDisplayTime(c)}>
                  {c.displayTime
                    ? c.currentStatus.createdDate.format('dddd, MMMM Do YYYY h:mm a Z')
                    : c.currentStatus.createdDate.fromNow()}
                </TableCell>
                <TableCell>{c.trainer}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="actions"
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      clearCasesSelection();
                      setSelectedCase(c);
                      setMenuPosition({ mouseX: event.clientX, mouseY: event.clientY });
                    }}>
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Intake</TableCell>
              <TableCell>Week</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tutorialAttendanceModel.studentsNotBookedForCurrentTutorialWeek?.map((s) => (
              <TableRow key={s.email + s.week}>
                <TableCell>{`${s.firstName ?? ''} ${s.lastName ?? ''}`}</TableCell>
                <TableCell>{s.email}</TableCell>
                <TableCell>{s.intake}</TableCell>
                <TableCell>{s.week}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TabPanel>
      <Box hidden={tabValue === 2} m={4}>
        <Pagination
          className={styles.pagination}
          count={Math.ceil(tutorialAttendanceModel.casesTotalCount / pageSize)}
          page={page}
          onChange={handlePageChange}
          color="secondary"
        />
      </Box>
      <Menu
        id="actions-menu"
        open={menuPosition.mouseY !== null}
        anchorReference="anchorPosition"
        keepMounted
        anchorPosition={
          menuPosition.mouseY !== null && menuPosition.mouseX !== null
            ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
            : undefined
        }
        onClose={() => closeMenu()}>
        {selectedCase?.currentStatus.status !== TutorialAttendanceCaseStatusType.ManuallyClosed && (
          <MenuItem
            onClick={() => {
              setOpenCloseDialog(true);
            }}>
            Close
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            showAttendanceCaseHistory(selectedCase as ICases);
          }}>
          Show details and history
        </MenuItem>

        {(selectedCase?.currentStatus.status === TutorialAttendanceCaseStatusType.New ||
          selectedCase?.currentStatus.status ===
            TutorialAttendanceCaseStatusType.NewStudentNotFoundInTutorialElearningAudience) && (
          <MenuItem
            onClick={() => {
              setNonAttendanceReason(selectedCase?.nonAttendanceReason ?? '');
              setOpenNonAttendanceReasonAndFeePaymentDialog(true);
            }}>
            Set reason and fee required
          </MenuItem>
        )}

        {selectedCase?.currentStatus.status ===
          TutorialAttendanceCaseStatusType.FeeRequiredDecisionMade && (
          <MenuItem
            onClick={() => {
              confirm({
                title: 'Confirm',
                description: 'Are you sure you want to mark this case as fee manually paid?',
              })
                .then(() => {
                  setFeeManuallyPaid();
                })
                .catch((e) => {
                  console.log(e);
                });
            }}>
            Mark as fee manually paid
          </MenuItem>
        )}

        {selectedCase?.currentStatus.status !== TutorialAttendanceCaseStatusType.ManuallyClosed && (
          <MenuItem
            onClick={() => {
              selectedCase &&
                navigator.clipboard.writeText(
                  `${window.location.origin}/public/tutorial/attendance/makeup/book/${selectedCase.publicId}`
                );

              closeMenu();
            }}>
            Copy booking link
          </MenuItem>
        )}

        {(selectedCase?.currentStatus.status ===
          TutorialAttendanceCaseStatusType.FeeNotRequiredDecisionMade ||
          selectedCase?.currentStatus.status ===
            TutorialAttendanceCaseStatusType.FeeManuallyProcessed ||
          selectedCase?.currentStatus.status ===
            TutorialAttendanceCaseStatusType.RebookingLinkSent) && (
          <MenuItem
            onClick={() => {
              confirm({
                title: 'Confirm',
                description: 'Are you sure you want to send a booking email?',
              })
                .then(() => {
                  sendMakeUpBookingEmail();
                })
                .catch((e) => {
                  console.log(e);
                });
            }}>
            Send rebooking email
          </MenuItem>
        )}
      </Menu>

      <Dialog
        open={openCloseDialog}
        aria-labelledby="close-dialog-title"
        aria-describedby="close-dialog-description">
        <DialogTitle id="close-dialog-title">Close case</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            autoFocus
            className={styles.closeDialog}
            aria-label="reason for closure"
            rowsMin={3}
            placeholder="Type a reason for case closure"
            value={closureComment}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setClosureComment(event.target.value)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setClosureComment('');
              setOpenCloseDialog(false);
            }}>
            Cancel
          </Button>
          <Button color="primary" disabled={!closureComment} onClick={() => closeCases()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openNonAttendanceReasonAndFeePaymentDialog}
        aria-labelledby="update-dialog-title"
        aria-describedby="update-dialog-description">
        <DialogTitle id="update-dialog-title">Set non attendance reason</DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <TextareaAutosize
                  autoFocus
                  className={styles.closeDialog}
                  aria-label="non attendance reason"
                  rowsMin={3}
                  placeholder="Type a reason for non attendance"
                  value={nonAttendanceReason}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setNonAttendanceReason(event.target.value ?? '')
                  }
                />
              }
              labelPlacement="start"
              label=""
            />

            <FormControlLabel
              control={
                <Switch
                  checked={isFeePaymentRequired}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setIsFeePaymentRequired(event.target.checked)
                  }
                  name="fee required"
                  color="primary"
                />
              }
              label="Is fee payment required?"
              labelPlacement="start"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setNonAttendanceReason('');
              setOpenNonAttendanceReasonAndFeePaymentDialog(false);
            }}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => updateNonAttendanceReasonAndFeePayment()}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openHistoryDialog}
        onClose={() => setOpenHistoryDialog(false)}
        aria-labelledby="history-dialog-title"
        aria-describedby="history-dialog-description">
        <DialogTitle id="history-dialog-title">History</DialogTitle>
        <DialogContent>
          <Box hidden={!selectedCase?.nonAttendanceReason} mb={3}>
            <Box fontWeight={500} component="span">
              Student non attendance reason:{' '}
            </Box>
            <Box component="span">{selectedCase?.nonAttendanceReason}</Box>
          </Box>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Created at</TableCell>
                  <TableCell>Created by</TableCell>
                  <TableCell>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceCaseHistory &&
                  attendanceCaseHistory.map((a) => (
                    <TableRow>
                      <TableCell>{a.status.replace(/([A-Z])/g, ' $1').trim()}</TableCell>
                      <TableCell>{moment(a.createdDate).toString()}</TableCell>
                      <TableCell>{a.createdBy}</TableCell>
                      <TableCell>{a.comments}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenHistoryDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
