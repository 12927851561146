import React, { FC, useEffect, useState } from 'react';
import qs from 'qs';
import { useParams } from 'react-router-dom';
import { useStore } from '../../hooks';
import { LogbookManage } from './LogbookManage';

export const StudentLogbookManage: FC = () => {
  const { logbookModel } = useStore();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const { courseTypeParam } = useParams<{ courseTypeParam: string }>();
  const [isComprehensive, setIsComprehensive] = useState<boolean>(false);
  const [isWrittenReflectionEnabled, setIsWrittenReflectionEnabled] = useState<boolean>(false);

  useEffect(() => {
    const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    setEmail(qsParams.email);
    setIsComprehensive(qsParams.comprehensive === 'true');
    setIsWrittenReflectionEnabled(qsParams.writtenreflection === 'true');
  }, [courseTypeParam, logbookModel]);

  return (
    <div>
      {email && (
        <LogbookManage
          email={email}
          isComprehensive={isComprehensive}
          enableWrittenReflection={isWrittenReflectionEnabled}
          courseType={courseTypeParam}
        />
      )}

      {!email && <>Missing required parameters.</>}
    </div>
  );
};
