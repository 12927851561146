import React, { useEffect } from 'react';
import { useStore } from '../../../hooks';
import { Grid, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import styles from './StudentCustomFields.module.scss';
import { ICustomFieldModel } from '../../../../domain/store/SettingsStore';

interface StudentCustomFieldsProps {
  scope: 'Student' | 'Intake';
}

export default function StudentCustomFields(props: StudentCustomFieldsProps) {
  const { settingsStore, studentStore } = useStore();
  const [customFieldsSettings, setCustomFieldsSettings] = React.useState<ICustomFieldModel[]>([]);

  useEffect(() => {
    settingsStore.getCustomFields().then(() => {
      setCustomFieldsSettings(settingsStore.customFields);
    });
  }, [settingsStore]);

  // const scopedCustomFields = settingsStore.customFields.filter(
  //   (cf) => cf.fieldScope === props.scope
  // );

  if (!customFieldsSettings || customFieldsSettings?.length === 0)
    return (
      <div>
        <h3>
          Custom Fields
          <IconButton aria-label="delete" color="primary" disabled={true}>
            <Tooltip title={'Add Note'}>
              <AddIcon />
            </Tooltip>
          </IconButton>
        </h3>
        <div>No Custom Fields have been configured. See Settings.</div>
      </div>
    );

  if (studentStore.student?.customFields?.length === 0) {
    return (
      <div className={styles.container}>
        <h3>
          Custom Fields
          <IconButton aria-label="delete" color="primary" onClick={() => {}}>
            <Tooltip title={'Add Note'}>
              <AddIcon />
            </Tooltip>
          </IconButton>
        </h3>
        <div>Student has no custom fields set.</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3>
        Custom Fields
        <IconButton aria-label="delete" color="primary" onClick={() => {}}>
          <Tooltip title={'Add Note'}>
            <AddIcon />
          </Tooltip>
        </IconButton>
      </h3>

      <Grid container spacing={3}>
        {studentStore.student?.customFields.map((cf) => {
          return (
            <Grid item sm={12} md={6}>
              {cf.fieldValue}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
