import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../hooks';
import { CourseType, IIntakeModel, ILectureModel } from '../../domain/store/OnlineCoursesModel';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import styles from './StudentSchedule.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { TimezoneSelector } from '../components/TimezoneSelector/TimezoneSelector';

interface ILectureScheduleRow {
  lecture: ILectureModel;
  localtime: Moment;
  recordingLink: string | null;
  joinLiveLink: string;
  joinLiveMeetingId: string;
  joinLiveMeetingPassword: string;
}

interface ILectureGroup {
  groupingTitle: string;
  lectures: ILectureScheduleRow[];
}

const useResponsiveStyles = makeStyles((theme) => ({
  hideOnSmallScreens: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideOnMediumAndUpScreen: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export const StudentSchedule: React.FC = function () {
  const { id } = useParams<{ id: string }>();
  const { module } = useParams<{ module?: string }>();
  const { onlineCoursesModel } = useStore();
  const [lectureRowGroups, setLectureRowGroups] = React.useState<ILectureGroup[]>([]);
  const [userTimezone, setUserTimezone] = useState<string>('');
  const responsiveStyles = useResponsiveStyles();
  const [intake, setIntake] = React.useState<IIntakeModel>();

  useEffect(() => {
    onlineCoursesModel.loadIntakeByPublicIdentifier(id).then((i: IIntakeModel) => {
      if (!i) return;

      setIntake(i);

      if (
        i.courseType === CourseType.Certificate ||
        i.courseType === CourseType.AnatomyAndMovement
      ) {
        if ((i.lectures?.length ?? 0) === 0) return;

        const orderedLectures = i.lectures.sort((a: ILectureModel, b: ILectureModel) => {
          return a.datetime > b.datetime ? 1 : a.datetime === b.datetime ? 0 : -1;
        });

        let rows: ILectureScheduleRow[] = [];

        orderedLectures.forEach((l: ILectureModel) => {
          const localTime = moment.tz(l.datetime, userTimezone);
          rows.push({
            lecture: l,
            localtime: localTime,
            recordingLink: l.recordingLink ?? i.recordingLink,
            joinLiveLink: i.joinLiveLink,
            joinLiveMeetingId: i.joinLiveMeetingId,
            joinLiveMeetingPassword: i.joinLiveMeetingPassword,
          });
        });

        let singleGroupLectures: ILectureGroup[] = [{ groupingTitle: 'NA', lectures: rows }];
        setLectureRowGroups(singleGroupLectures);
      } else {
        //Diploma
        if ((i.diplomaModules?.length ?? 0) === 0) return;

        let moduleLectures: ILectureGroup[] = [];

        i.diplomaModules
          .filter((m) => !module || m.id === parseInt(module))
          .forEach((m) => {
            const orderedLectures = m.lectures.sort((a: ILectureModel, b: ILectureModel) => {
              return a.datetime > b.datetime ? 1 : a.datetime === b.datetime ? 0 : -1;
            });

            let rows: ILectureScheduleRow[] = [];

            orderedLectures.forEach((l: ILectureModel) => {
              const localTime = moment.tz(l.datetime, userTimezone);
              rows.push({
                lecture: l,
                localtime: localTime,
                recordingLink: l.recordingLink ?? m.recordingLink,
                joinLiveLink: m.joinLiveLink,
                joinLiveMeetingId: m.joinLiveMeetingId,
                joinLiveMeetingPassword: m.joinLiveMeetingPassword,
              });
            });
            moduleLectures.push({ groupingTitle: m.displayName, lectures: rows });
          });
        setLectureRowGroups(moduleLectures);
      }
    });
  }, [id, module, onlineCoursesModel, userTimezone]);

  const handleTimezoneChange = (newValue: string) => {
    setUserTimezone(newValue);
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.paperContainer}>
          <h2>My Lectures</h2>
          <div>
            <TimezoneSelector onChange={handleTimezoneChange} />
          </div>

          {userTimezone && (
            <>
              {lectureRowGroups.length > 1 && (
                <div className={styles.moduleLinks}>
                  {lectureRowGroups.map((r, i) => (
                    <div key={i}>
                      <span>Go to </span> <a href={'#group' + i}>{r.groupingTitle}</a>
                    </div>
                  ))}
                </div>
              )}
              {lectureRowGroups.map((g, i) => (
                <div key={i} className={styles.moduleDiv}>
                  <h1 id={'group' + i}>{g.groupingTitle !== 'NA' ? g.groupingTitle : ''}</h1>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" className={styles.table}>
                      <TableHead>
                        <TableRow className={styles.rowHeaderCell}>
                          <TableCell className={responsiveStyles.hideOnMediumAndUpScreen}>
                            Lectures
                          </TableCell>
                          <TableCell className={responsiveStyles.hideOnSmallScreens}>
                            Week
                          </TableCell>
                          <TableCell className={responsiveStyles.hideOnSmallScreens}>
                            Topics
                          </TableCell>
                          <TableCell className={responsiveStyles.hideOnSmallScreens}>
                            Attend Lecture Live (Date/Time)
                          </TableCell>
                          <TableCell className={responsiveStyles.hideOnSmallScreens}>
                            Duration
                          </TableCell>
                          <TableCell className={responsiveStyles.hideOnSmallScreens}>
                            Join Lecture Live
                          </TableCell>
                          <TableCell className={responsiveStyles.hideOnSmallScreens}>
                            Lecture Notes
                          </TableCell>
                          {intake?.courseType !== CourseType.AnatomyAndMovement && (
                            <TableCell className={responsiveStyles.hideOnSmallScreens}>
                              Watch Recording
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {g.lectures.map((row, i) => (
                          <LectureRow key={i} row={row} intake={intake} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

interface ILectureRowProps {
  row: ILectureScheduleRow;
  intake?: IIntakeModel;
}

function LectureRow(props: ILectureRowProps) {
  const { row, intake } = props;
  const detailsRef = React.createRef();
  const detailsSmallRef: React.RefObject<HTMLParagraphElement> = React.createRef();
  const responsiveStyles = useResponsiveStyles();

  useEffect(() => {
    let detailsLarge: any = detailsRef.current;
    let detailsSmall: any = detailsSmallRef.current;
    let detailsContent = row.lecture.details.replace('\n', '<br/>');
    detailsLarge.innerHTML = detailsSmall.innerHTML = detailsContent;
  }, [detailsRef, row.lecture.details, detailsSmallRef]);

  return (
    <TableRow className={styles.row}>
      <TableCell className={responsiveStyles.hideOnMediumAndUpScreen}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <strong>
              {row.lecture.title} - {row.localtime.format('MMM DD, h:mm A')}
            </strong>
            <p>Duration: {row.lecture.duration}-min</p>
            <p ref={detailsSmallRef}>{''}</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            {row.joinLiveLink && (
              <div className={styles.joinDiv}>
                <a
                  className={styles.buttonLink}
                  href={row.joinLiveLink}
                  target="_blank"
                  rel="noreferrer">
                  Join Live
                </a>
                {row.joinLiveMeetingId && <Box mt={1}>Meeting ID: {row.joinLiveMeetingId}</Box>}
                {row.joinLiveMeetingPassword && (
                  <Box mt={1}>Meeting Password: {row.joinLiveMeetingPassword}</Box>
                )}
              </div>
            )}
            {!row.joinLiveLink && <p>Zoom link TBA</p>}
            {row.lecture.lectureNotesLink && (
              <p>
                <a href={row.lecture.lectureNotesLink} target="_blank" rel="noreferrer">
                  Download Lecture Notes
                </a>
              </p>
            )}
            {intake?.courseType !== CourseType.AnatomyAndMovement && row.recordingLink && (
              <p>
                <a href={row.recordingLink} target="_blank" rel="noreferrer">
                  Click here for recording
                </a>
              </p>
            )}
            {intake?.courseType !== CourseType.AnatomyAndMovement && !row.recordingLink && (
              <p>Recording link TBA</p>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={[styles.noWrapCell, responsiveStyles.hideOnSmallScreens].join(' ')}>
        {row.lecture.title}
      </TableCell>
      <TableCell className={responsiveStyles.hideOnSmallScreens} ref={detailsRef} />

      <TableCell className={responsiveStyles.hideOnSmallScreens}>
        {row.localtime.format('ll h:mm A')}
      </TableCell>
      <TableCell className={responsiveStyles.hideOnSmallScreens}>
        {row.lecture.duration}-min
      </TableCell>
      <TableCell className={responsiveStyles.hideOnSmallScreens}>
        {row.joinLiveLink && (
          <>
            <a
              className={styles.buttonLink}
              href={row.joinLiveLink}
              target="_blank"
              rel="noreferrer">
              Join Live
            </a>
            {row.joinLiveMeetingId && <Box mt={1}>Meeting ID: {row.joinLiveMeetingId}</Box>}
            {row.joinLiveMeetingPassword && (
              <Box mt={1}>Meeting Password: {row.joinLiveMeetingPassword}</Box>
            )}
          </>
        )}
        {!row.joinLiveLink && <p>Zoom link TBA</p>}
      </TableCell>
      <TableCell className={responsiveStyles.hideOnSmallScreens}>
        {row.lecture.lectureNotesLink && (
          <a href={row.lecture.lectureNotesLink} target="_blank" rel="noreferrer">
            Download Lecture Notes
          </a>
        )}
      </TableCell>
      {props.intake?.courseType !== CourseType.AnatomyAndMovement && (
        <TableCell className={responsiveStyles.hideOnSmallScreens}>
          {row.recordingLink && (
            <a href={row.recordingLink} target="_blank" rel="noreferrer">
              Click here for recording
            </a>
          )}
          {!row.recordingLink && <p>Recording link TBA</p>}
        </TableCell>
      )}
    </TableRow>
  );
}
