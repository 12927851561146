import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  IDiplomaModuleModel,
  IDiplomaModuleSummaryModel,
} from '../../../domain/store/OnlineCoursesModel';
import { useStore } from '../../hooks';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import qs from 'qs';
import styles from './PublicSchedule.module.scss';
import { FormControl, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { TutorialSchedule } from '../../components/TutorialSchedule/TutorialSchedule';
import { CourseTypeSpecificPublicScheduleProps } from './PublicSchedule';
import _ from 'lodash';

interface IDiplomaLectureScheduleRow {
  fromDate?: Moment;
  toDate?: Moment;
  isBreak?: boolean;
  alternativeTime?: Moment;
}

interface IDiplomaLectureModuleGroup {
  moduleDisplayName?: string;
  lectures: IDiplomaLectureScheduleRow[];
  firstLectureDate: Moment | null;
  acuityCategory: string;
}

export const PublicDiplomaSchedule: React.FC<CourseTypeSpecificPublicScheduleProps> = observer(
  (props: CourseTypeSpecificPublicScheduleProps) => {
    const [enrolBeforeDate, setEnroleBeforeDate] = useState('');
    const [hasLectures, setHasLectures] = useState(false);
    const [groups, setGroups] = React.useState<IDiplomaLectureModuleGroup[]>([]);
    const [timezones, setTimezones] = useState<string[]>([]);
    const [userTimezone, setUserTimezone] = useState<string>('');
    const [showTimezone, setShowTimezone] = useState(false);
    const [timezonesLoaded, setTimezonesLoaded] = useState(false);
    const { onlineCoursesModel } = useStore();
    const [moduleSummaries, setModuleSummaries] = useState<IDiplomaModuleSummaryModel[]>([]);

    const getPosixCorrectedFormat = (timezoneName: string) => {
      // Due to moment's POSIX-style timezones, Etc/GMT[+/-]X have inverted offset values - https://github.com/moment/moment-timezone/issues/167
      if (timezoneName.indexOf('Etc/GMT+') > -1) {
        return timezoneName.replace('+', '-');
      } else if (timezoneName.indexOf('Etc/GMT-') > -1) {
        return timezoneName.replace('-', '+');
      }
      return timezoneName;
    };

    useEffect(() => {
      const names = moment.tz.names();
      setTimezones(names);
      setUserTimezone(moment.tz.guess(true));
      setTimezonesLoaded(true);
    }, [timezonesLoaded]);

    useEffect(() => {
      onlineCoursesModel.loadDiplomaModuleSummaries().then(() => {
        setModuleSummaries(onlineCoursesModel.diplomaModuleSummaries);
      });
    });

    useEffect(() => {
      if (
        (!props.intake?.lectures && !props.module?.lectures) ||
        !userTimezone ||
        !(moduleSummaries.length > 0)
      )
        return;
      let moduleGroups: IDiplomaLectureModuleGroup[] = [];

      const modulesToDisplay: IDiplomaModuleModel[] = props.intake
        ? props.intake.diplomaModules!
        : [props.module!];

      modulesToDisplay.forEach((m) => {
        let rows: IDiplomaLectureScheduleRow[] = [];
        let firstLectureDate: Moment | null = null;

        const sortedLectures = m.lectures.sort((a, b) => {
          return a.datetime > b.datetime ? 1 : a.datetime === b.datetime ? 0 : -1;
        });

        const groupedByWeek = _.groupBy(sortedLectures, (lecture) => {
          return lecture.title;
        });

        if (sortedLectures.length > 0) setHasLectures(true);

        var firstOfEachWeek = [];

        for (const wk in groupedByWeek) {
          firstOfEachWeek.push(groupedByWeek[wk][0]);
        }

        firstOfEachWeek.forEach((l) => {
          if (!firstLectureDate) firstLectureDate = moment(l.datetime);

          const alternativeTime =
            groupedByWeek[l.title].length > 1
              ? moment.tz(groupedByWeek[l.title][1].datetime, getPosixCorrectedFormat(userTimezone))
              : undefined;

          if (rows.length === 0) {
            rows.push({
              fromDate: moment.tz(l.datetime, getPosixCorrectedFormat(userTimezone)),
              alternativeTime: alternativeTime,
            });
          } else {
            const lastRow = rows[rows.length - 1];

            // get this lecture's time in the user's localtime
            const localTime = moment.tz(l.datetime, getPosixCorrectedFormat(userTimezone));
            // if last this lecture's localtime is exactly 7 days from max of ("toDate" - if exists, if not use "fromDate")
            if (
              moment(lastRow.toDate ?? lastRow.fromDate!)
                .add(7, 'days')
                .isSame(localTime)
            ) {
              // use this lecture's localtime as new toDate
              rows[rows.length - 1].toDate = localTime;
            } else {
              // if this lecture's time is ~ 1 week (but not exact) after the last lecture time
              let hoursDifference = localTime.diff(
                moment(lastRow.toDate ?? lastRow.fromDate!),
                'hours'
              );
              if (hoursDifference < 8 * 24 && hoursDifference > 6 * 24) {
                // ~7 days
                // create new row and use this lectures localtime as the fromDate
                rows.push({ fromDate: localTime, alternativeTime: alternativeTime });
              } else {
                // add break row
                rows.push({ isBreak: true });
                // add another row with this lecture's time as fromDate
                rows.push({ fromDate: localTime, alternativeTime: alternativeTime });
              }
            }
          }
        });

        moduleGroups.push({
          moduleDisplayName: m.displayName,
          lectures: rows,
          firstLectureDate: firstLectureDate ?? null,
          acuityCategory: m.acuityCategory,
        });
      });

      let sortedGroups = moduleGroups.sort((a, b) => {
        return a.firstLectureDate == null || b.firstLectureDate == null
          ? 0
          : a.firstLectureDate > b.firstLectureDate
          ? 1
          : a.firstLectureDate === b.firstLectureDate
          ? 0
          : -1;
      });

      setGroups(sortedGroups);
    }, [props.intake, props.module, userTimezone, setHasLectures, moduleSummaries]);

    useEffect(() => {
      const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (!qsParams.eb) {
        if (groups.length && groups[0].firstLectureDate !== null) {
          const enrolBefore = groups[0]
            .firstLectureDate!.clone()
            .subtract(2, 'months')
            .endOf('month')
            .format('ll');
          setEnroleBeforeDate(enrolBefore);
        } else {
          setEnroleBeforeDate('TBA');
        }
      } else setEnroleBeforeDate(moment.unix(qsParams.eb).format('ll'));
    }, [groups]);

    const handleTimezoneChange = (event: any, newValue: any) => {
      setUserTimezone(newValue);
    };

    const courseOrProgram = props.country === 'US' ? 'Program' : 'Course';

    return (
      <>
        <div className={styles.container}>
          <div>
            <Grid container>
              <Grid item xs={12} md={6}>
                <h2>{courseOrProgram} start date:</h2>
                <p>Start now - immediate access on enrol{props.country === 'US' ? 'l' : ''}ment</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2>Enrol{props.country === 'US' ? 'l' : ''} before:</h2>
                <p>{enrolBeforeDate}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2>Average time to complete:</h2>
                <p>{props.intake ? '12 months' : props.module ? '15 weeks' : ''}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2>Location:</h2>
                <p>100% online - study from anywhere</p>
                <p>No travel required</p>
              </Grid>
            </Grid>
            <div className={styles.scheduleContainer}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <h1>Key Dates & Times:</h1>
                  {!showTimezone && (
                    <>
                      <p>
                        Your timezone is: <strong>{userTimezone}</strong>
                        <button
                          className={styles.timezoneButton}
                          onClick={() => setShowTimezone(true)}>
                          Change Timezone
                        </button>
                      </p>
                    </>
                  )}
                  {showTimezone && (
                    <FormControl fullWidth className={styles.timezoneSelector}>
                      <Autocomplete
                        id="timezone-location-autocomplete"
                        onChange={handleTimezoneChange}
                        options={timezones}
                        value={userTimezone}
                        getOptionLabel={(option) => option.replace(/_/g, ' ')}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField {...params} label="Timezone" variant="outlined" />
                        )}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>Live Lectures (1 per week):</h2>
                  {hasLectures && (
                    <>
                      {groups.map((group, i) => (
                        <div key={group.moduleDisplayName}>
                          <h3>{group.moduleDisplayName}</h3>
                          {group.lectures.map((row, i) => (
                            <span key={i}>
                              {!(row.isBreak ?? false) && (
                                <>
                                  {!row.toDate && (
                                    <>
                                      {row.fromDate?.format('ll')} (
                                      {row.fromDate?.format('dddd h:mmA z')}
                                      {row.alternativeTime && (
                                        <>, {row.alternativeTime?.format('dddd h:mmA z')}</>
                                      )}
                                      )
                                    </>
                                  )}
                                  {row.toDate && (
                                    <>
                                      {row.fromDate?.format('ll')} to {row.toDate?.format('ll')} (
                                      {row.toDate?.format('dddd h:mmA z')}
                                      {row.alternativeTime && (
                                        <>, {row.alternativeTime?.format('dddd h:mmA z')}</>
                                      )}
                                      )
                                    </>
                                  )}
                                </>
                              )}
                              {(row.isBreak ?? false) && <>Break</>}
                              <br />
                            </span>
                          ))}
                        </div>
                      ))}
                    </>
                  )}
                  {((!props.intake && !props.module) || !hasLectures) && (
                    <p>
                      The lecture schedule is currently unavailable online. Please{' '}
                      <a
                        target="_parent"
                        href={
                          props.country === 'AU'
                            ? 'https://breathe.edu.au/contact-us/'
                            : props.country === 'US'
                            ? 'https://breathe-education.com/contact-us/'
                            : 'https://breathe.edu.au/contact-us/'
                        }>
                        contact us
                      </a>{' '}
                      for more information.
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} md={6} className={styles.tutorialScheduleContainer}>
                  <h2>Tutorials (1 per week):</h2>
                  <TutorialSchedule
                    acuityCategory={groups[0]?.acuityCategory}
                    mode={'public'}
                    userTimezone={getPosixCorrectedFormat(userTimezone)}
                    location={props.country}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>Masterclasses:</h2>
                  <p>Attend Live & On-Demand</p>
                  <i>*recommended 2 per week</i>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>Practice Teaching:</h2>
                  <p>Teach your classmates online, or practice your own way</p>
                  <i>*recommended 2 per week</i>
                </Grid>
                <Grid item xs={12} md={6}>
                  {props.intake && (
                    <>
                      <h2>{courseOrProgram} Deadline:</h2>
                      <>
                        {groups.length > 0 && groups[0].lectures.length > 0
                          ? groups[0].lectures[0].fromDate!.clone().add(24, 'months').format('ll')
                          : '24 months after first lecture'}
                      </>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
);
