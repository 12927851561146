import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useStore } from '../../hooks';
import { ILogbookEntryModel } from '../../../domain/store/LogbookModel';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/CheckCircle';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import DeleteIcon from '@material-ui/icons/Delete';
import WrittenReflectionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import TableBody from '@material-ui/core/TableBody';
import style from './LogbookManager.module.scss';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';

interface ILogbookManageProps {
  email: string | undefined;
  isComprehensive: boolean;
  courseType: string;
  enableWrittenReflection: boolean;
}

export const LogbookManage: FC<ILogbookManageProps> = observer((props: ILogbookManageProps) => {
  const { logbookModel } = useStore();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [entries, setEntries] = useState<ILogbookEntryModel[]>([]);
  const [isListMode, setIsListMode] = useState<boolean>(true);
  const [entryToEdit, setEntryToEdit] = useState<ILogbookEntryModel | null>(null);
  const [showAllFieldsRequiredValidation, setShowAllFieldsRequiredValidation] = useState<boolean>(
    false
  );
  const [
    showWrittenReflectionTooShortValidation,
    setShowWrittenReflectionTooShortValidation,
  ] = useState<boolean>(false);
  const [isComprehensive, setIsComprehensive] = useState<boolean>(false);
  const [isMay23Onwards, setIsMay23Onwards] = useState<boolean>(false); // May 23 onwards, requires 1.) entries have written reflections added, 2.) Only comprehensive requires entries (for apparatus, not for Mat/reformer)

  const enableOtherOption = false;

  useEffect(() => {
    setEmail(props.email);
    setIsComprehensive(props.isComprehensive);
    setIsMay23Onwards(props.enableWrittenReflection);

    if (!props.email) return;

    logbookModel
      .getLogbookList(props.courseType, props.email)
      .then((result: ILogbookEntryModel[]) => {
        result.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf());
        setEntries(result);
      });
  }, [
    props.courseType,
    logbookModel,
    props.email,
    props.isComprehensive,
    props.enableWrittenReflection,
  ]);

  const handleAddNew = () => {
    var newEntry = {
      courseType: props.courseType,
      entryType: '',
      entryTypeCategory: isMay23Onwards && !isComprehensive ? 'Practice Teaching' : '',
      entryTypeOtherDetails: '',
      studentEmail: email,
      durationMinutes: 0,
      writtenReflection: '',
    } as ILogbookEntryModel;

    setEntryToEdit(newEntry);
    setIsListMode(false);
    setShowAllFieldsRequiredValidation(false);
    setShowWrittenReflectionTooShortValidation(false);
  };

  const handleSave = () => {
    if (!entryToEdit) return;

    if (
      !entryToEdit.timestamp ||
      !entryToEdit.entryTypeCategory ||
      !entryToEdit.durationMinutes ||
      !entryToEdit.entryType ||
      (isMay23Onwards && !entryToEdit.writtenReflection)
    ) {
      setShowAllFieldsRequiredValidation(true);
      return;
    } else {
      if (entryToEdit.entryType === 'Other' && !entryToEdit.entryTypeOtherDetails) {
        setShowAllFieldsRequiredValidation(true);
        return;
      }
      setShowAllFieldsRequiredValidation(false);
    }

    if (isMay23Onwards && (entryToEdit.writtenReflection ?? '').trim().length < 100) {
      setShowWrittenReflectionTooShortValidation(true);
      return;
    } else {
      setShowWrittenReflectionTooShortValidation(false);
    }

    logbookModel.upsertLogbookEntry(entryToEdit).then((addedEntry) => {
      if (!entryToEdit.publicId) {
        const newEntries = [...entries, addedEntry];
        newEntries.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf());
        setEntries(newEntries);
      } else {
        entries.splice(
          entries.findIndex((e: ILogbookEntryModel) => e.publicId === addedEntry.publicId),
          1,
          addedEntry
        );
        entries.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf());
        setEntries(entries);
      }
      setIsListMode(true);
    });
  };

  const handleCancel = () => {
    setIsListMode(true);
    setEntryToEdit(null);
    setShowAllFieldsRequiredValidation(false);
    setShowWrittenReflectionTooShortValidation(false);
  };

  const handleEdit = (e: ILogbookEntryModel) => {
    setEntryToEdit(e);
    setIsListMode(false);
  };

  const handleDelete = (de: ILogbookEntryModel) => {
    logbookModel.deleteLogbookEntry(email!, de.publicId!).then((result) => {
      if (result) {
        let copy = entries.slice();
        copy.splice(
          copy.findIndex((e: ILogbookEntryModel) => e.publicId === de.publicId),
          1
        );
        setEntries(copy);
      }
    });
  };

  const handleOtherTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntryToEdit({ ...entryToEdit!, entryTypeOtherDetails: event.target.value as string });
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntryToEdit({ ...entryToEdit!, entryType: event.target.value as string });
  };

  const handleCategoryChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntryToEdit({ ...entryToEdit!, entryTypeCategory: event.target.value as string });
  };

  const handleDurationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntryToEdit({ ...entryToEdit!, durationMinutes: parseInt(event.target.value as string) });
  };

  const handleWrittenReflectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntryToEdit({ ...entryToEdit!, writtenReflection: event.target.value as string });
  };

  const formatDateString = (value: string) => {
    if (!value) return '';
    return moment(value).format('YYYY-MM-DD');
  };

  const handleDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntryToEdit({
      ...entryToEdit!,
      timestamp: moment(event.target.value as number).format('YYYY-MM-DD'),
    });
  };

  const personalPracticeOrObservationMatOrOther =
    (isComprehensive
      ? entries.filter(
          (e) =>
            e.entryTypeCategory === 'Personal Practice or Observation' &&
            e.entryType === 'Mat Pilates'
        )
      : entries.filter(
          (e) =>
            e.entryTypeCategory === 'Personal Practice or Observation' &&
            e.entryType !== 'Reformer Pilates'
        )
    ).reduce((prev, cur) => {
      return prev + cur.durationMinutes;
    }, 0) / 60;

  const personalPracticeOrObservationReformerTotal =
    entries
      .filter(
        (e) =>
          e.entryTypeCategory === 'Personal Practice or Observation' &&
          e.entryType === 'Reformer Pilates'
      )
      .reduce((prev, cur) => {
        return prev + cur.durationMinutes;
      }, 0) / 60;

  const practiceTeachingReformerAndMatTotal = isComprehensive
    ? entries
        .filter(
          (e) =>
            e.entryTypeCategory === 'Practice Teaching' &&
            (e.entryType === 'Mat Pilates' ||
              e.entryType === 'Reformer Pilates' ||
              e.entryType === 'Chair, Cadillac or Barrel')
        )
        .reduce((prev, cur) => {
          return prev + cur.durationMinutes;
        }, 0) / 60
    : entries
        .filter((e) => e.entryTypeCategory === 'Practice Teaching')
        .reduce((prev, cur) => {
          return prev + cur.durationMinutes;
        }, 0) / 60;

  const personalPracticeOrObservationChair =
    entries
      .filter(
        (e) =>
          e.entryTypeCategory === 'Personal Practice or Observation' &&
          e.entryType === 'Chair Pilates'
      )
      .reduce((prev, cur) => {
        return prev + cur.durationMinutes;
      }, 0) / 60;

  const personalPracticeOrObservationCadillac =
    entries
      .filter(
        (e) =>
          e.entryTypeCategory === 'Personal Practice or Observation' &&
          e.entryType === 'Cadillac Pilates'
      )
      .reduce((prev, cur) => {
        return prev + cur.durationMinutes;
      }, 0) / 60;

  const personalPracticeOrObservationBarrel =
    entries
      .filter(
        (e) =>
          e.entryTypeCategory === 'Personal Practice or Observation' &&
          e.entryType === 'Barrel Pilates'
      )
      .reduce((prev, cur) => {
        return prev + cur.durationMinutes;
      }, 0) / 60;

  const practiceTeachingChair =
    entries
      .filter((e) => e.entryTypeCategory === 'Practice Teaching' && e.entryType === 'Chair Pilates')
      .reduce((prev, cur) => {
        return prev + cur.durationMinutes;
      }, 0) / 60;

  const practiceTeachingCadillac =
    entries
      .filter(
        (e) => e.entryTypeCategory === 'Practice Teaching' && e.entryType === 'Cadillac Pilates'
      )
      .reduce((prev, cur) => {
        return prev + cur.durationMinutes;
      }, 0) / 60;

  const practiceTeachingBarrel =
    entries
      .filter(
        (e) => e.entryTypeCategory === 'Practice Teaching' && e.entryType === 'Barrel Pilates'
      )
      .reduce((prev, cur) => {
        return prev + cur.durationMinutes;
      }, 0) / 60;

  let totalHours =
    personalPracticeOrObservationMatOrOther +
    personalPracticeOrObservationReformerTotal +
    practiceTeachingReformerAndMatTotal;
  if (isComprehensive) {
    totalHours +=
      personalPracticeOrObservationChair +
      personalPracticeOrObservationCadillac +
      personalPracticeOrObservationBarrel +
      practiceTeachingChair +
      practiceTeachingCadillac +
      practiceTeachingBarrel;
  }

  const logbookComplete = isComprehensive
    ? //comprehensive
      isMay23Onwards
      ? // comprehensive, May 23 onwards
        personalPracticeOrObservationChair >= 30 &&
        personalPracticeOrObservationCadillac >= 25 &&
        personalPracticeOrObservationBarrel >= 12 &&
        practiceTeachingChair >= 18 &&
        practiceTeachingCadillac >= 21 &&
        practiceTeachingBarrel >= 11
      : // comprehensive, pre-May 23 and before
        personalPracticeOrObservationMatOrOther + personalPracticeOrObservationReformerTotal >=
          40 &&
        personalPracticeOrObservationReformerTotal >= 20 &&
        practiceTeachingReformerAndMatTotal >= 40 &&
        personalPracticeOrObservationChair >= 30 &&
        personalPracticeOrObservationCadillac >= 25 &&
        personalPracticeOrObservationBarrel >= 12 &&
        practiceTeachingChair >= 18 &&
        practiceTeachingCadillac >= 21 &&
        practiceTeachingBarrel >= 11
    : //non-comprehensive
    isMay23Onwards
    ? // non-comprehensive, May 23 onwards
      practiceTeachingReformerAndMatTotal >= 40
    : // non-comprehensive pre-May 23 and before
      personalPracticeOrObservationMatOrOther + personalPracticeOrObservationReformerTotal >= 40 &&
      personalPracticeOrObservationReformerTotal >= 20 &&
      practiceTeachingReformerAndMatTotal >= 40;

  let menuItems: string[];

  if (
    isComprehensive &&
    isMay23Onwards &&
    entryToEdit?.entryTypeCategory === 'Personal Practice or Observation'
  ) {
    menuItems = [];
  } else {
    menuItems = ['Mat Pilates', 'Reformer Pilates'];
  }

  if (!isComprehensive && !isMay23Onwards) menuItems.push('Chair, Cadillac or Barrel');
  else if (isComprehensive) {
    menuItems.push('Chair Pilates');
    menuItems.push('Cadillac Pilates');
    menuItems.push('Barrel Pilates');
  }
  if (enableOtherOption) menuItems.push('Other');

  return (
    <div className={style.container}>
      <Container maxWidth={'md'}>
        {isListMode && (
          <>
            <h2>Summary</h2>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{isComprehensive && 'Mat and Reformer '}Requirement</TableCell>
                  <TableCell>Logged (hours)</TableCell>
                  <TableCell>Required (hours)</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!isMay23Onwards && (
                  <>
                    <TableRow>
                      <TableCell>Personal Practice or Observation - Reformer Pilates</TableCell>
                      <TableCell>{personalPracticeOrObservationReformerTotal}</TableCell>
                      <TableCell rowSpan={2}>
                        20 Reformer / 40 Total
                        {personalPracticeOrObservationMatOrOther +
                          personalPracticeOrObservationReformerTotal >=
                          40 &&
                          personalPracticeOrObservationReformerTotal >= 20 && <CheckIcon />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Personal Practice or Observation - {isComprehensive && 'Mat Pilates'}
                        {!isComprehensive && 'Other'}
                      </TableCell>
                      <TableCell>{personalPracticeOrObservationMatOrOther}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </>
                )}

                <TableRow>
                  <TableCell>Practice Teaching</TableCell>
                  <TableCell>{practiceTeachingReformerAndMatTotal}</TableCell>
                  <TableCell>
                    40 {practiceTeachingReformerAndMatTotal >= 40 && <CheckIcon />}
                  </TableCell>
                </TableRow>

                {isComprehensive && (
                  <>
                    <TableRow className={style.thead}>
                      <TableCell>Apparatus Requirement</TableCell>
                      <TableCell>Logged (hours)</TableCell>
                      <TableCell>Required (hours)</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Personal Practice or Observation - Chair</TableCell>
                      <TableCell>{personalPracticeOrObservationChair}</TableCell>
                      <TableCell>
                        30 {personalPracticeOrObservationChair >= 30 && <CheckIcon />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Personal Practice or Observation - Cadillac</TableCell>
                      <TableCell>{personalPracticeOrObservationCadillac}</TableCell>
                      <TableCell>
                        25 {personalPracticeOrObservationCadillac >= 25 && <CheckIcon />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Personal Practice or Observation - Barrel</TableCell>
                      <TableCell>{personalPracticeOrObservationBarrel}</TableCell>
                      <TableCell>
                        12 {personalPracticeOrObservationBarrel >= 12 && <CheckIcon />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Practice Teaching - Chair</TableCell>
                      <TableCell>{practiceTeachingChair}</TableCell>
                      <TableCell>18 {practiceTeachingChair >= 18 && <CheckIcon />}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Practice Teaching - Cadillac</TableCell>
                      <TableCell>{practiceTeachingCadillac}</TableCell>
                      <TableCell>21 {practiceTeachingCadillac >= 21 && <CheckIcon />}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Practice Teaching - Barrel</TableCell>
                      <TableCell>{practiceTeachingBarrel}</TableCell>
                      <TableCell>11 {practiceTeachingBarrel >= 11 && <CheckIcon />}</TableCell>
                    </TableRow>
                    <TableRow className={style.theadNarrow}>
                      <TableCell colSpan={3}>&nbsp;</TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell>
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell>{totalHours}</TableCell>
                  <TableCell className={style.lastCell}>
                    {logbookComplete && (
                      <>
                        <TrophyIcon />
                        <p>
                          Logbook completed!
                          <br />
                          Well done!
                        </p>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <h2>
              Entries{' '}
              <Button className={style.primaryButton} onClick={handleAddNew}>
                Add entry
              </Button>
            </h2>

            <List>
              {entries.map((e) => (
                <ListItem key={e.publicId}>
                  <ListItemText>
                    <Grid container>
                      <Grid item sm={12} md={4}>
                        {moment(e.timestamp).toDate().toLocaleDateString()}
                      </Grid>
                      <Grid item sm={12} md={4} className={style.listColumn}>
                        {e.entryTypeCategory} - {e.entryType}{' '}
                        {e.entryType === 'Other' ? ' (' + e.entryTypeOtherDetails + ')' : ''} -{' '}
                        {e.durationMinutes}min{' '}
                        {e.writtenReflection && (
                          <>
                            <Tooltip title={'Written reflection: ' + e.writtenReflection} arrow>
                              <WrittenReflectionIcon />
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </ListItemText>
                  <ListItemIcon>
                    {' '}
                    <Button
                      onClick={() => {
                        handleEdit(e);
                      }}>
                      <EditIcon color={'primary'} />
                    </Button>
                  </ListItemIcon>

                  <ListItemIcon>
                    <Button
                      onClick={() => {
                        handleDelete(e);
                      }}>
                      <DeleteIcon color={'primary'} />
                    </Button>
                  </ListItemIcon>
                </ListItem>
              ))}
              {(!entries || entries.length === 0) && (
                <ListItem>
                  <ListItemText>
                    You have no entries. Please click "ADD ENTRY" to begin adding entries to your
                    logbook.
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </>
        )}

        {!isListMode && (
          <>
            <h2>{entryToEdit!.publicId ? 'Edit existing entry' : 'Add a new entry'}</h2>

            <FormControl className={style.inputField} fullWidth>
              <TextField
                type={'date'}
                id="date"
                name="date"
                label="Date"
                fullWidth
                value={formatDateString(entryToEdit!.timestamp)}
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>

            <FormControl className={style.inputField} fullWidth>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={entryToEdit!.entryTypeCategory}
                label="Category"
                onChange={handleCategoryChanged}>
                {((!isMay23Onwards && !isComprehensive) ||
                  (isComprehensive &&
                    entryToEdit!.entryType !== 'Mat Pilates' &&
                    entryToEdit!.entryType !== 'Reformer Pilates')) && (
                  <MenuItem value={'Personal Practice or Observation'}>
                    Personal Practice or Observation
                  </MenuItem>
                )}
                <MenuItem value={'Practice Teaching'} selected={true}>
                  Practice Teaching
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl className={style.inputField} fullWidth>
              <InputLabel id="type-select-label">What did you practice?</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={entryToEdit!.entryType}
                label="What did you practice?"
                onChange={handleTypeChange}>
                {menuItems.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {entryToEdit!.entryType === 'Other' && (
              <FormControl className={style.inputField} fullWidth>
                <TextField
                  title="Other"
                  id="Other"
                  value={entryToEdit!.entryTypeOtherDetails}
                  onChange={handleOtherTypeChange}
                  fullWidth
                  placeholder="Please specify"
                />
              </FormControl>
            )}

            <FormControl className={style.inputField} fullWidth>
              <InputLabel id="duration-select-label">Duration</InputLabel>
              <Select
                labelId="duration-select-label"
                id="duration-select"
                value={entryToEdit!.durationMinutes ? entryToEdit!.durationMinutes : ''}
                label="Duration"
                onChange={handleDurationChange}>
                <MenuItem value={'15'}>15 minutes</MenuItem>
                <MenuItem value={'30'}>30 minutes</MenuItem>
                <MenuItem value={'45'}>45 minutes</MenuItem>
                <MenuItem value={'60'}>1 hour</MenuItem>
                <MenuItem value={'75'}>1 hour 15 minutes</MenuItem>
                <MenuItem value={'90'}>1 hour 30 minutes</MenuItem>
                <MenuItem value={'105'}>1 hour 45 minutes</MenuItem>
                <MenuItem value={'120'}>2 hours</MenuItem>
              </Select>
            </FormControl>
            {(isMay23Onwards || entryToEdit!.writtenReflection) && (
              <FormControl className={style.multilinetextinputField} fullWidth>
                <InputLabel id="writtenreflection-label">Written Reflection</InputLabel>
                <TextField
                  style={{ textAlign: 'left' }}
                  title="Written Reflection"
                  id="writtenreflection"
                  multiline={true}
                  rows={4}
                  value={entryToEdit!.writtenReflection || ''}
                  onChange={handleWrittenReflectionChange}
                  fullWidth
                  placeholder="- What do you feel went well in this PT session?
- Based on your own reflection or feedback from your peers, what areas do you feel could have been better?
- What will you do differently next time you teach this sequence and/or in your teaching generally?"
                />
              </FormControl>
            )}

            {showAllFieldsRequiredValidation && (
              <Alert className={style.alert} severity="error">
                Please fill out all fields
              </Alert>
            )}

            {showWrittenReflectionTooShortValidation &&
              (entryToEdit!.writtenReflection ?? '').trim().length < 100 && (
                <Alert className={style.alert} severity="error">
                  Written Reflection is too short.
                </Alert>
              )}

            <div className={style.buttonGroup}>
              <Button className={style.primaryButton} onClick={handleSave}>
                Save
              </Button>
              <Button className={style.secondaryButton} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Container>
    </div>
  );
});
