import { useState, useEffect, useCallback } from 'react';

const useDebounce = (callback: any, delay: number) => {
  const [debouncing, setDebouncing] = useState(false);
  const debouncedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    let timeoutId: any;

    if (debouncing) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setDebouncing(false);
      debouncedCallback();
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [debouncing, delay, debouncedCallback]);

  return () => {
    setDebouncing(true);
  };
};

export default useDebounce;
