import { getAppInsights } from './TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { isDevelopment } from '../appSettings';

const info = (message: string) => {
  let obj = {
    message: message,
    severityLevel: SeverityLevel.Information,
  };

  if (isDevelopment) {
    console.log(JSON.stringify(obj));
    return;
  }

  let insights = getAppInsights();
  if (insights) {
    insights.trackTrace(obj);
  }
};

const warning = (message: string) => {
  let obj = {
    message: message,
    severityLevel: SeverityLevel.Warning,
  };

  if (isDevelopment) {
    console.log(JSON.stringify(obj));
    return;
  }

  let insights = getAppInsights();
  if (insights) {
    insights.trackTrace(obj);
  }
};

const error = (error: string) => {
  let obj = {
    message: error,
    severityLevel: SeverityLevel.Error,
  };

  if (isDevelopment) {
    console.log(JSON.stringify(obj));
    return;
  }

  let insights = getAppInsights();
  if (insights) {
    insights.trackTrace(obj);
  }
};

const event = (name: string, properties: {}) => {
  let obj = {
    name,
    properties,
  };

  if (isDevelopment) {
    console.log(obj);
    return;
  }

  let insights = getAppInsights();
  if (insights) {
    insights.trackEvent(obj);
  }
};

const LogService = {
  info,
  warning,
  error,
  event,
};

export default LogService;
