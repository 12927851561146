import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { STUDENT_SELF_MANAGEMENT_IFRAME_SCRIPT } from 'utils/consts';
import { Button } from '@material-ui/core';

export const TotaraEmbedCodeCopyToClipBoard: React.FC = function () {
  return (
    <Button
      color={'primary'}
      title="Copy Totara Embed code"
      onClick={() => {
        navigator.clipboard.writeText(STUDENT_SELF_MANAGEMENT_IFRAME_SCRIPT);
      }}>
      <FileCopyIcon />
    </Button>
  );
};
