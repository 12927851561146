import React from 'react';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import styles from './ReportsList.module.scss';
import { NavLink } from 'react-router-dom';

export const ReportsList: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>Reports</h1>

      <div className={styles.reportGroupContainer}>
        <h3>By Intake</h3>

        <Grid container>
          <Grid item md={4} sm={12}>
            <List>
              <ListItem button to="/admin/reports/intakeLogbookSummary" component={NavLink}>
                <ListItemText>Logbook Summary (Cert only)</ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
