import * as React from 'react';
import { useEffect, useState } from 'react';
import styles from './CertificateCommitmentStatement.module.scss';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../../../hooks';
import { IFormModel } from '../../../../../domain/store/FormStore';
import { Alert } from '@material-ui/lab';

export const CertificateCommitmentStatement = () => {
  const [form, setForm] = useState<any>({});
  const [formId, setFormId] = useState<number | null>(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const { formStore } = useStore();
  const queryStringParams = new URLSearchParams(useLocation().search);
  const email = queryStringParams.get('email') as string;
  const firstName = queryStringParams.get('firstname') as string;
  const lastName = queryStringParams.get('lastname') as string;

  const isSetupCorrectly = email && firstName && lastName;

  const certificateCommitmentStatementV1 = 'CertificateCommitmentStatement_v1';

  useEffect(() => {
    formStore.getForm(email, certificateCommitmentStatementV1).then((form: IFormModel) => {
      if (form && form.formData) {
        const parsedFormData = JSON.parse(form.formData);

        for (const [key, value] of Object.entries(parsedFormData)) {
          if (value === 'True') {
            parsedFormData[key] = true;
          }
          if (value === 'False') {
            parsedFormData[key] = false;
          }
        }

        setForm(parsedFormData);
        setFormId(form.id);
      }
    });
  }, [email, formStore]);

  const handleFormFieldChange = (event: any) => {
    const propertyName = event.target.name;

    if (event.target.type === 'checkbox') {
      const isChecked = event.target.checked;
      setForm({ ...form, [propertyName]: isChecked });
    }

    if (
      event.target.type === 'textarea' ||
      event.target.type === 'text' ||
      event.target.type === 'radio'
    ) {
      const value = event.target.value;
      setForm({ ...form, [propertyName]: value });
    }
  };

  const handleSubmit = () => {
    formStore.saveForm(email, form, formId, certificateCommitmentStatementV1).then((r: boolean) => {
      if (r) {
        setIsSaved(true);
      }
    });
  };

  const isFormValid = () => {
    return (
      form['Responding to Slack messages within 24 hours'] === true &&
      form['Completing all coursework each week (by Sunday 11:59pm AEST)'] === true &&
      form['Having my camera on and attending for the duration of all live sessions'] === true &&
      form[
        'Fully participating in all live sessions (in a space free from distractions with access to all needed equipment. Eg. not in a car or on public transport)'
      ] === true &&
      form['Completing weekly lecture & repertoire review BEFORE weekly tutorial'] === true &&
      form['Completing this course is important to me because it will help me to']?.length > 120 &&
      form['And it will impact others lives by']?.length >= 120 &&
      form['Do you solemnly swear on the grave of Joseph pilates that you will do these things'] ===
        'Yes, I am deeply committed to this and will do whatever is required to achieve it'
    );
  };

  if (!isSetupCorrectly) {
    return <p>This link has not been configured correctly.</p>;
  }

  return (
    <div className={styles.container}>
      <h2>
        {firstName} {lastName}
      </h2>

      <br />

      <p>I commit to:</p>
      <ul>
        <li>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFormFieldChange}
                  name={'Responding to Slack messages within 24 hours'}
                  checked={form['Responding to Slack messages within 24 hours'] ?? false}
                />
              }
              label={`Responding to Slack messages within 24 hours`}
            />
          </FormGroup>
        </li>

        <li>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFormFieldChange}
                  name={'Completing all coursework each week (by Sunday 11:59pm AEST)'}
                  checked={
                    form['Completing all coursework each week (by Sunday 11:59pm AEST)'] ?? false
                  }
                />
              }
              label={`Completing all coursework each week (by Sunday 11:59pm AEST)`}
            />
          </FormGroup>
        </li>

        <li>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleFormFieldChange}
                  name={'Having my camera on and attending for the duration of all live sessions'}
                  checked={
                    form[
                      'Having my camera on and attending for the duration of all live sessions'
                    ] ?? false
                  }
                />
              }
              label={`Having my camera on and attending for the duration of all live sessions`}
            />
          </FormGroup>
        </li>

        <li>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    form[
                      'Fully participating in all live sessions (in a space free from distractions with access to all needed equipment. Eg. not in a car or on public transport)'
                    ] ?? false
                  }
                  onChange={handleFormFieldChange}
                  name={
                    'Fully participating in all live sessions (in a space free from distractions with access to all needed equipment. Eg. not in a car or on public transport)'
                  }
                />
              }
              label={`Fully participating in all live sessions (in a space free from distractions with access to all needed equipment. Eg. not in a car or on public transport)`}
            />
          </FormGroup>
        </li>

        <li>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    form['Completing weekly lecture & repertoire review BEFORE weekly tutorial'] ??
                    false
                  }
                  onChange={handleFormFieldChange}
                  name={'Completing weekly lecture & repertoire review BEFORE weekly tutorial'}
                />
              }
              label={`Completing weekly lecture & repertoire review BEFORE weekly tutorial`}
            />
          </FormGroup>
        </li>
      </ul>

      <FormControl className={styles.multilinetextinputField} fullWidth>
        <TextField
          style={{ textAlign: 'left' }}
          name="Completing this course is important to me because it will help me to"
          multiline={true}
          label={
            `Completing this course is important to me because it will help me to ` +
            ((form['Completing this course is important to me because it will help me to']
              ?.length ?? 0) < 120
              ? '(' +
                Math.abs(
                  (form['Completing this course is important to me because it will help me to']
                    ?.length ?? 0) - 120
                ) +
                ' characters required)'
              : '')
          }
          rows={4}
          value={form['Completing this course is important to me because it will help me to'] ?? ''}
          onChange={handleFormFieldChange}
          fullWidth
        />
      </FormControl>

      <FormControl className={styles.multilinetextinputField} fullWidth>
        <TextField
          name={'And it will impact others lives by'}
          label={
            'And it will impact others lives by ' +
            ((form['And it will impact others lives by']?.length ?? 0) < 120
              ? '(' +
                Math.abs((form['And it will impact others lives by']?.length ?? 0) - 120) +
                ' characters required)'
              : '')
          }
          style={{ textAlign: 'left' }}
          id="impact-others-by"
          multiline={true}
          rows={4}
          value={form['And it will impact others lives by'] ?? ''}
          onChange={handleFormFieldChange}
          fullWidth
        />
      </FormControl>

      <br />
      <br />

      <p>Do you solemnly swear on the grave of Joseph pilates that you will do these things:</p>
      <FormControl component="fieldset">
        <FormLabel component="legend"></FormLabel>
        <RadioGroup
          aria-label="commitment"
          name="Do you solemnly swear on the grave of Joseph pilates that you will do these things"
          value={
            form[
              'Do you solemnly swear on the grave of Joseph pilates that you will do these things'
            ] ?? null
          }
          onChange={handleFormFieldChange}>
          <FormControlLabel
            value="Yes, I am deeply committed to this and will do whatever is required to achieve it"
            control={<Radio />}
            label="Yes, I am deeply committed to this and will do whatever is required to achieve it"
          />
          <FormControlLabel
            value="No, I don’t really want to be a Pilates instructor and I’ll give up as soon as it gets hard"
            control={<Radio />}
            label="No, I don’t really want to be a Pilates instructor and I’ll give up as soon as it gets hard"
          />
        </RadioGroup>
      </FormControl>

      <div>
        {!formId && !isSaved && (
          <Button className={styles.primaryButton} onClick={handleSubmit} disabled={!isFormValid()}>
            Submit
          </Button>
        )}

        {((formId && formId > 0) || isSaved) && (
          <>
            {isSaved && (
              <Alert severity="info">Your commitment statement has been submitted.</Alert>
            )}

            <Button className={styles.primaryButton} onClick={() => {}} disabled={true}>
              Already submitted
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
