import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import styles from './TermsOfServiceForm.module.scss';
import logo from '../../../../images/breathe-education-logo-red-retina.png';
import { useStore } from '../../../hooks';

export const TermsOfServiceForm = () => {
  const { formStore } = useStore();
  const [form, setForm] = useState<any>({});
  const [errors, setErrors] = useState({
    fullNameError: '',
    emailError: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const handleFormFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((form: any) => ({
      ...form,
      [name]: value,
    }));

    if (name === 'fullName') {
      if (value.trim().split(' ').length < 2) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fullNameError: 'Please enter your full name.',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fullNameError: '',
        }));
      }
    }

    if (name === 'email') {
      if (!/\S+@\S+\.\S+/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          emailError: 'Please enter a valid email address.',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          emailError: '',
        }));
      }
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((form: any) => ({
      ...form,
      [name]: checked,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!errors.fullNameError && !errors.emailError) {
      formStore.saveForm(form.email, form, null, 'TermsOfServiceAcceptance').then((success) => {
        if (success) {
          setSubmitted(true);
        } else {
          console.log('Error submitting form');
        }
      });
    }
  };

  const allChecked =
    Object.keys(form).filter((key) => key.startsWith('I confirm')).length >= 3 &&
    Object.keys(form).every((key) => (key.startsWith('I confirm') ? form[key] : true));
  const isFormValid =
    allChecked &&
    !errors.fullNameError &&
    !errors.emailError &&
    form.fullName?.trim().length > 0 &&
    form.email?.trim().length > 0;

  return (
    <div className={styles.wrapper}>
      <img src={logo} alt="Breathe Logo" className={styles.logo} />
      <div className={styles.container}>
        {submitted ? (
          <Typography variant="h6">
            Thank you. A copy of the accepted Terms of Service will be emailed to {form.email}. You
            can now close this page.
          </Typography>
        ) : (
          <form onSubmit={handleSubmit} className={styles.form}>
            <TextField
              label="Full Name"
              name="fullName"
              value={form.fullName ?? ''}
              onChange={handleFormFieldChange}
              fullWidth
              margin="normal"
              required
              error={!!errors.fullNameError}
              helperText={errors.fullNameError}
              className={styles.formField}
            />
            <TextField
              label="Email Address"
              type="email"
              name="email"
              value={form.email ?? ''}
              onChange={handleFormFieldChange}
              fullWidth
              margin="normal"
              required
              error={!!errors.emailError}
              helperText={errors.emailError}
              className={styles.formField}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={styles.checkbox}
                  checked={
                    form['I confirm I have read and agree to your Terms of Service'] ?? false
                  }
                  onChange={handleCheckboxChange}
                  name="I confirm I have read and agree to your Terms of Service"
                />
              }
              label={
                <span>
                  I confirm I have read and agree to your{' '}
                  <a
                    href="https://breathe-education.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Terms of Service
                  </a>
                  .
                </span>
              }
              className={`${styles.formField} ${styles.checkboxField}`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={styles.checkbox}
                  checked={
                    form['I confirm I understand and agree to the payment option I selected'] ??
                    false
                  }
                  onChange={handleCheckboxChange}
                  name="I confirm I understand and agree to the payment option I selected"
                />
              }
              label="I confirm I understand and agree to the payment option I selected."
              className={styles.formField}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={styles.checkbox}
                  checked={form['I confirm I understand the terms of refund'] ?? false}
                  onChange={handleCheckboxChange}
                  name="I confirm I understand the terms of refund"
                />
              }
              label="I confirm I understand the terms of refund."
              className={styles.formField}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isFormValid}
              className={styles.submitButton}>
              Submit
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};
