import React, { useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@material-ui/core';
import moment from 'moment-timezone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from './TimezoneSelector.module.scss';

interface ITimezoneSelectorProps {
  onChange?: (value: string) => void;
}

export const TimezoneSelector: React.FC<ITimezoneSelectorProps> = ({ onChange }) => {
  const [timezones, setTimezones] = useState<string[]>([]);
  const [userTimezone, setUserTimezone] = useState<string | null>(null);
  const [showTimezoneSelector, setShowTimezoneSelector] = useState(false);

  const setAndStoreUserTimezone = (newTimezone: string) => {
    setUserTimezone(newTimezone);
    localStorage.setItem('userTimezone', newTimezone);
    if (onChange) onChange(newTimezone);
  };

  useEffect(() => {
    const names = moment.tz.names();
    setTimezones(names);

    let x = localStorage.getItem('userTimezone');
    if (x) {
      setUserTimezone(x);
      if (onChange) onChange(x);
    }
  }, [onChange]);

  const handleTimezoneChange = (event: any, newValue: any) => {
    setAndStoreUserTimezone(newValue);
  };

  const confirmGuessedTimeZone = () => {
    setAndStoreUserTimezone(moment.tz.guess());
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.paperContainer}>
          <div>
            {!showTimezoneSelector && userTimezone && (
              <p>
                Your timezone: <strong>{userTimezone}</strong>{' '}
                <Button
                  className={styles.changeTimeZoneButton}
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => setShowTimezoneSelector(true)}>
                  Change Timezone
                </Button>
              </p>
            )}

            {!userTimezone && !showTimezoneSelector && (
              <>
                <p>
                  Please confirm your current timezone is <strong>{moment.tz.guess(true)}.</strong>
                </p>

                <Button
                  variant="contained"
                  size="small"
                  className={styles.buttonLink}
                  onClick={confirmGuessedTimeZone}>
                  Yes, that is my timezone
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className={styles.buttonLink}
                  onClick={() => setShowTimezoneSelector(true)}>
                  No, I want to change it
                </Button>
              </>
            )}

            {showTimezoneSelector && (
              <FormControl className={styles.timezoneSelector}>
                <Autocomplete
                  id="timezone-location-autocomplete"
                  onChange={handleTimezoneChange}
                  options={timezones}
                  value={userTimezone ? userTimezone : ''}
                  className={styles.timezones}
                  getOptionLabel={(option) => option.replace(/_/g, ' ')}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField {...params} label="Timezone" variant="outlined" />
                  )}
                />
              </FormControl>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
