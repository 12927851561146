import React, { useEffect, useState } from 'react';
import styles from './TutorialSchedule.module.scss';
import { useStore } from '../../hooks';
import { IIntakeTutorialModel } from 'domain/store/OnlineCoursesModel';
import moment, { Moment } from 'moment-timezone';

interface ITutorialScheduleProps {
  acuityCategory?: string;
  mode: 'public' | 'admin';
  userTimezone?: string;
  location: 'AU' | 'US' | 'NZ';
  onLoad?: () => void;
}

interface IClassForDisplay {
  date: Moment;
  day: string;
  time: string;
}

export const TutorialSchedule: React.FC<ITutorialScheduleProps> = function ({
  onLoad,
  userTimezone,
  location,
  acuityCategory,
  mode,
}) {
  const { onlineCoursesModel } = useStore();
  const [classes, setClasses] = useState<IIntakeTutorialModel[]>([]);
  const [tzAdjustedClasses, setTzAdjustedClasses] = useState<IClassForDisplay[]>([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(false);

    if (!acuityCategory) return;

    setClasses([]);

    onlineCoursesModel.loadIntakeTutorials(acuityCategory).then((c: IIntakeTutorialModel[]) => {
      const orderedList = c.sort((a: IIntakeTutorialModel, b: IIntakeTutorialModel) => {
        return a.time > b.time ? 1 : a.time === b.time ? 0 : -1;
      });
      setClasses(orderedList);
      setHasLoaded(true);
      if (onLoad) {
        onLoad();
      }
    });
  }, [acuityCategory, onLoad, setClasses, onlineCoursesModel]);

  useEffect(() => {
    const classesForDisplay = classes.map<IClassForDisplay>((c) => {
      const t = moment.tz(c.time, userTimezone!);
      return { date: t, day: t.format('dddd'), time: t.format('h:mmA z') };
    });

    setTzAdjustedClasses(classesForDisplay);
  }, [classes, userTimezone]);

  return (
    <div className={styles.container}>
      {!!tzAdjustedClasses.length && (
        <>
          <ul>
            {tzAdjustedClasses.map((item: IClassForDisplay, i: number) => {
              return (
                <li key={i}>
                  {item.day} {item.time}
                </li>
              );
            })}
          </ul>
        </>
      )}

      {hasLoaded &&
        mode === 'admin' &&
        (!tzAdjustedClasses || tzAdjustedClasses.length === 0) &&
        acuityCategory && <p>No tutorials found for Acuity Appointment Type: "{acuityCategory}"</p>}

      {mode === 'admin' &&
        (!tzAdjustedClasses || tzAdjustedClasses.length === 0) &&
        !acuityCategory && <p>Please set the Acuity Appointment Type</p>}

      {hasLoaded && mode === 'public' && (!tzAdjustedClasses || tzAdjustedClasses.length === 0) && (
        <p>
          The tutorial schedule is currently unavailable online. Please{' '}
          <a
            target="_parent"
            href={
              location === 'AU'
                ? 'https://breathe.edu.au/contact-us/'
                : location === 'US'
                ? 'https://breathe-education.com/contact-us/'
                : 'https://breathe.edu.au/contact-us/'
            }>
            contact us
          </a>{' '}
          for more information.
        </p>
      )}

      {!acuityCategory && mode === 'public' && (
        <p>
          The tutorial schedule is currently unavailable online. Please{' '}
          <a target="_parent" href="https://breathe.edu.au/contact-us/">
            contact us
          </a>{' '}
          for more information.
        </p>
      )}

      {acuityCategory && mode === 'public' && !hasLoaded && <p>Loading...</p>}
    </div>
  );
};
