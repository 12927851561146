export const STUDENT_SELF_MANAGEMENT_IFRAME_SCRIPT = `
<script>
var iframe = $("iframe#resourceobject");

if(iframe) {
    iframe.width("100%");
    iframe.css("width", "100%");
    iframe.height("900");
    iframe.attr("id","coreIframe");
    // Uncomment next two lines for links that require full screen access, e.g. Vimeo Library
    iframe.attr("allowfullscreen", true);
    iframe.attr("allow", "camera;microphone");
    iframe.attr("src", iframe.attr("src"));
}

var spanButton = $("span.instancename:contains('URL NAME')");
if(spanButton) {
    spanButton.css({"color":"#fff", "font-weight": "bold"});
    spanButton.closest('a').css({"background": "#de2154", "color": "#fff", "padding": "8px 12px", "border": "0px", "-webkit-box-shadow": "0 -2px 0 rgba(0,0,0,0.15) inset","-moz-box-shadow": "0 -2px 0 rgba(0,0,0,0.15) inset","box-shadow": "0 -2px 0 rgba(0,0,0,0.15) inset","border-radius": "4px", "text-decoration": "none", "display": "inline-block"});
    spanButton.parent().find("span.activityicon[title='URL']").hide();
}
</script>
`;
