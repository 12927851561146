export enum StudentClassType {
  Tutorial,
  Masterclass,
  PracticeTeaching,
  GenericClass,
  GenericRoom,
}

export const getClassTypeLabel = (classType: StudentClassType, plural: boolean) => {
  switch (classType) {
    case StudentClassType.Masterclass:
      return plural ? 'Masterclasses' : 'Masterclass';
    case StudentClassType.Tutorial:
      return plural ? 'Tutorials' : 'Tutorial';
    case StudentClassType.PracticeTeaching:
      return plural ? 'Practice Teaching sessions' : 'Practice Teaching session';
    case StudentClassType.GenericClass: // Study Group / MC / Tutorial etc
      return plural ? 'Sessions (class)' : 'Session (class)';
    case StudentClassType.GenericRoom: // Like PT / Study Group etc
      return plural ? 'Sessions (room))' : 'Session (room)';
  }
};
