import { flow, Instance, SnapshotIn, TypeOfValue, types } from 'mobx-state-tree';
import { getEnv } from './util';

export const IntakeWarning = types.model('IntakeWarning', {
  intakeId: types.number,
  warning: types.string,
  intakeTitle: types.string,
  impact: types.string,
});

export const DashboardWarnings = types.model('DashboardWarnings', {
  intakeWarnings: types.array(IntakeWarning),
});

export const IntakeGradeBookItemModel = types.model('IntakeGradeBookItemModel', {
  id: types.number,
  elearningCourseId: types.number,
  createdTimestamp: types.string,
  itemId: types.number,
  itemName: types.string,
  itemType: types.string,
  itemUrl: types.string,
  sectionName: types.string,
  lastUpdatedTimestamp: types.string,
  courseTitle: types.maybeNull(types.string),
});

export const CertModuleModel = types.model('CertModuleModel', {
  id: types.optional(types.number, 0),
  intakeId: types.optional(types.number, 0),
  title: types.string,
  description: types.string,
});

export const LectureModel = types.model('LectureModel', {
  id: types.optional(types.number, 0),
  duration: types.number,
  datetime: types.Date,
  details: types.string,
  title: types.string,
  lectureNotesLink: types.maybeNull(types.string),
  recordingLink: types.maybeNull(types.string),
  tutorialDescription: types.maybeNull(types.string),
  homeworkDescription: types.maybeNull(types.string),
  practicalTutorial: types.optional(types.boolean, false),
  certModule: types.maybeNull(CertModuleModel),
});

export const KeapProductModel = types.model('KeapProductModel', {
  id: types.optional(types.number, 0),
  keapProductId: types.number,
});

export enum CourseType {
  Certificate = '10721NAT Certificate IV in Pilates Matwork and Reformer',
  Diploma = '10771NAT Diploma of Clinical Pilates',
  AnatomyAndMovement = 'Anatomy & Movement',
}

export const CourseTypeModel = types.enumeration([
  CourseType.Certificate,
  CourseType.Diploma,
  CourseType.AnatomyAndMovement,
]);

export const DiplomaModuleModel = types.model('DiplomaModuleModel', {
  id: types.optional(types.number, 0),
  title: types.string,
  displayName: types.string,
  lectures: types.array(LectureModel),
  timezone: types.string,
  joinLiveLink: types.optional(types.string, ''),
  joinLiveMeetingId: types.optional(types.string, ''),
  joinLiveMeetingPassword: types.optional(types.string, ''),
  recordingLink: types.optional(types.string, ''),
  acuityCategory: types.optional(types.string, ''),
  elearningAudience: types.optional(types.string, ''),
  elearningCourseIds: types.optional(types.string, ''),
  gradeBookItemReferenceIds: types.array(types.number),
});

export const IntakeModel = types
  .model('IntakeModel', {
    id: types.optional(types.number, 0),
    title: types.string,
    lectures: types.array(LectureModel),
    diplomaModules: types.array(DiplomaModuleModel),
    certModules: types.array(CertModuleModel),
    timezone: types.string,
    joinLiveLink: types.optional(types.string, ''),
    joinLiveMeetingId: types.optional(types.string, ''),
    joinLiveMeetingPassword: types.optional(types.string, ''),
    recordingLink: types.optional(types.string, ''),
    acuityCategory: types.optional(types.string, ''),
    publicIdentifier: types.optional(types.string, ''),
    courseType: CourseTypeModel,
    elearningAudience: types.optional(types.string, ''),
    elearningAudienceSecondary: types.optional(types.string, ''),
    courseDeadline: types.maybeNull(types.Date),
    keapProducts: types.array(KeapProductModel),
    enrolmentCapacityTotal: types.maybeNull(types.number),
    enrolmentCapacityRemaining: types.maybeNull(types.number),
    gradeBookItemReferenceIds: types.array(types.number),
    elearningPrimaryCourseIds: types.optional(types.string, ''),
    elearningSecondaryCourseIds: types.optional(types.string, ''),
    isDeleted: types.boolean,
  })
  .views((self) => ({
    isCert: () =>
      self.courseType === CourseType.Certificate ||
      self.courseType === CourseType.AnatomyAndMovement,
    isDiploma: () => self.courseType === CourseType.Diploma,
  }));

export const IntakeTutorialModel = types.model('IntakeTutorialModel', {
  id: types.number,
  name: types.string,
  calendar: types.string,
  duration: types.number,
  slots: types.number,
  slotsAvailable: types.number,
  time: types.Date,
  calendarTimezone: types.string,
});

export const DiplomaModuleSummaryModel = types.model('DiplomaModuleSummaryModel', {
  id: types.optional(types.number, 0),
  title: types.string,
  displayName: types.string,
  acuityCategory: types.optional(types.string, ''),
});

export const IntakeSummaryModel = types.model('IntakeSummaryModel', {
  id: types.optional(types.number, 0),
  title: types.string,
  publicIdentifier: types.string,
  acuityCategory: types.optional(types.string, ''),
  modules: types.array(DiplomaModuleSummaryModel),
  courseType: types.string,
});

export interface ElearningUser {
  username: string;
}

export interface Audience {
  id: string;
  name: string;
  Members?: Array<ElearningUser>;
}

export interface ElearningUserDetails {
  lmsUserName: string;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  countryName: string;
  phone1: string;
  phone2: string;
  alternateEmail: string;
  SalesOwnerCrmId: string;
  CrmLastRefreshDateTime: string;
}

export interface IGradeBookItemModel extends Instance<typeof IntakeGradeBookItemModel> {}

export interface IIntakeModel extends Instance<typeof IntakeModel> {}

export interface ILectureModel extends Instance<typeof LectureModel> {}

export interface IIntakeSummaryModel extends Instance<typeof IntakeSummaryModel> {}

export interface IDiplomaModuleSummaryModel extends Instance<typeof DiplomaModuleSummaryModel> {}

export interface IIntakeTutorialModel extends Instance<typeof IntakeTutorialModel> {}

export interface IDiplomaModuleModel extends Instance<typeof DiplomaModuleModel> {}

export interface ICertModuleModel extends Instance<typeof CertModuleModel> {}

export interface IKeapProductModel extends Instance<typeof KeapProductModel> {}

export type CourseTypeModelType = TypeOfValue<typeof CourseTypeModel>;

export interface IDashboardWarnings extends Instance<typeof DashboardWarnings> {}

export interface IIntakeWarning extends Instance<typeof IntakeWarning> {}

export const OnlineCoursesModel = types
  .model('OnlineCoursesModel', {
    intakes: types.optional(types.array(IntakeSummaryModel), []),
    diplomaModuleSummaries: types.optional(types.array(DiplomaModuleSummaryModel), []),
    dashboardWarnings: types.optional(DashboardWarnings, {}),
  })
  .actions((self) => ({
    loadIntakesSummaries: flow(function* () {
      const { ajax } = getEnv(self);

      try {
        const result = yield ajax
          .get('Intake/')
          .json<IIntakeSummaryModel[]>()
          .then((values) => values.map((item) => IntakeSummaryModel.create(item)));
        self.intakes.clear();
        result.forEach((i: IIntakeSummaryModel) => self.intakes.push(i));
      } catch (error) {
        console.log('error loadIntakesSummaries : ' + error);
      }
    }),
    loadDiplomaModuleSummaries: flow(function* () {
      const { ajax } = getEnv(self);
      try {
        const result = yield ajax
          .get('PublicModule/')
          .json<IDiplomaModuleSummaryModel[]>()
          .then((values) => values.map((item) => DiplomaModuleSummaryModel.create(item)));
        self.diplomaModuleSummaries.clear();
        result.forEach((i: IDiplomaModuleSummaryModel) => self.diplomaModuleSummaries.push(i));
      } catch (error) {
        console.log('error loadDiplomaModuleSummaries : ' + error);
      }
    }),
    saveIntake: flow(function* (intake: SnapshotIn<IIntakeModel>) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax.post('Intake/', { json: intake });
      } catch (error) {
        console.log('error saveIntake : ' + error);
      }
    }),
    updateIsDeleteFlag: flow(function* (intakeId: number, isDeleted: boolean) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax.post('Intake/toggle-delete', { json: { intakeId, isDeleted } });
      } catch (error) {
        console.log('error Intake/toggle-delete : ' + error);
      }
    }),
    saveDiplomaModule: flow(function* (module: SnapshotIn<IDiplomaModuleModel>) {
      const { ajax } = getEnv(self);
      try {
        return yield ajax.post('ProtectedModule/', { json: module });
      } catch (error) {
        console.log('error saveDiplomaModule : ' + error);
      }
    }),
    loadIntakeById: flow(function* (id: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get('Intake/' + id)
          .json<IIntakeModel>()
          .then((value) => value);
      } catch (error) {
        console.log('error loadIntakeById : ' + id + ' ' + error);
      }
    }),
    loadModuleById: flow(function* (id: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get('ProtectedModule/' + id)
          .json<IDiplomaModuleModel>()
          .then((value) => value);
      } catch (error) {
        console.log('error loadDiplomaModuleById : ' + id + ' ' + error);
      }
    }),
    loadModuleByIdForProspect: flow(function* (id: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get('PublicModule/' + id)
          .json<IDiplomaModuleModel>()
          .then((value) => value);
      } catch (error) {
        console.log('error loadDiplomaModuleByIdForProspect : ' + id + ' ' + error);
      }
    }),
    loadIntakeByPublicIdentifier: flow(function* (id: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get('PublicIntake/' + id)
          .json<IIntakeModel>()
          .then((value) => {
            return value;
          });
      } catch (error) {
        console.log('error loadIntakeByPublicIdentifier : ' + error);
      }
    }),
    loadIntakeByIdForProspect: flow(function* (id: number) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get('PublicIntake/prospect/' + id)
          .json<IIntakeModel>()
          .then((value) => value);
      } catch (error) {
        console.log('error loadIntakeByIdForProspect: ' + error);
      }
    }),
    loadElearningAudiences: flow(function* (includeCurrentIntakesOnly: boolean) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`Intake/elearning-audiences?includeCurrentIntakesOnly=${includeCurrentIntakesOnly}`)
          .json<Audience[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error Intake/elearning-audiences error: ${error}`);
      }
    }),
    getElearningAudienceUsers: flow(function* (id: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`Intake/elearning-audiences/${id}/users`)
          .json<ElearningUserDetails[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error Intake/elearning-audiences/${id}/users error: ${error}`);
      }
    }),
    getElearningAudienceUser: flow(function* (userName: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`Intake/elearning-audiences/user/${userName}`)
          .json<ElearningUserDetails>()
          .then((value) => value);
      } catch (error) {
        console.log(`error Intake/elearning-audiences/user/${userName} error: ${error}`);
      }
    }),
    getUserElearningAudiences: flow(function* (userName: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`Intake/elearning-audiences/user-audiences/${userName}`, { timeout: 60000 })
          .json<Audience[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error Intake/elearning-audiences/user-audiences/${userName} error: ${error}`);
      }
    }),
    getAllElearningUsers: flow(function* () {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get(`Intake/elearning-audiences/users`, { timeout: 60000 })
          .json<ElearningUser[]>()
          .then((value) => value);
      } catch (error) {
        console.log(`error Intake/elearning-audiences/users error: ${error}`);
      }
    }),
    loadIntakeTutorials: flow(function* (acuityCategory: string) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .get('PublicIntake/intake/tutorial-schedule/' + acuityCategory)
          .json<IIntakeTutorialModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(
          'error /PublicIntake/intake/tutorial-schedule/' + acuityCategory + ' error: ' + error
        );
      }
    }),
    loadReferenceGradeBookItems: flow(function* (courseIds: number[]) {
      const { ajax } = getEnv(self);

      try {
        return yield ajax
          .post('Intake/reference-gradebook-items', { json: courseIds })
          .json<IGradeBookItemModel[]>()
          .then((value) => value);
      } catch (error) {
        console.log(
          'error GET /Intake/reference-gradebook-items for ' +
            JSON.stringify(courseIds) +
            ' error: ' +
            error
        );
      }
    }),
    loadDashboardWarnings: flow(function* () {
      const { ajax } = getEnv(self);

      try {
        const result = yield ajax
          .get('ProtectedDashboard/warnings')
          .json<IDashboardWarnings>()
          .then((value) => {
            return DashboardWarnings.create(value);
          });
        self.dashboardWarnings = result;
      } catch (error) {
        console.log('error GET /ProtectedDashboard/warnings error: ' + error);
      }
    }),
  }))
  .views((self) => ({}));
