import { Account } from 'msal';

export class AuthenticationAccount extends Account {}

export type AuthenticationProviderState =
  | 'signing-in'
  | 'signed-in'
  | 'redirecting'
  | 'signed-out'
  | 'loading';

export interface IAuthenticationProvider {
  readonly initialState: AuthenticationProviderState;
  readonly account: AuthenticationAccount | null;

  signIn(): Promise<AuthenticationProviderState>;

  signOut(): void;

  getIsSignedIn(): Promise<boolean>;

  getApiAccessToken(): Promise<string>;

  // getGraphAccessToken(scopes?: string[]): Promise<string>;
}

export class AuthenticationError extends Error {
  constructor() {
    super('Not Authenticated');
  }
}
