import React, { useEffect, useState } from 'react';
import { useStore } from '../../hooks';
import { Gradebook } from './Gradebook';
import { IStudentEnrolmentGradeBookItemModel } from '../../../domain/store/StudentStore';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import styles from './StudentGradebook.module.scss';

export const StudentGradebook: React.FC = () => {
  const { studentStore } = useStore();
  const [items, setItems] = useState<IStudentEnrolmentGradeBookItemModel[]>([]);
  const { courseTypeParam } = useParams<{ courseTypeParam: string }>();
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const qsParams: any = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const email = qsParams.email;
    setEmail(email);

    if (courseTypeParam && email) {
      studentStore.loadPublicStudentGradebook(courseTypeParam, email).then((result) => {
        setItems(result);
      });
    }
  }, [setItems, studentStore, courseTypeParam]);

  return (
    <div className={styles.container}>
      {email && <Gradebook gradebookItems={items} />}

      {!email && <p>Incorrectly configured.</p>}
    </div>
  );
};
