import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useStore } from '../../../hooks';
import IconButton from '@material-ui/core/IconButton';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import styles from '../Student.module.scss';
import { Clear } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import CriticalIcon from '@material-ui/icons/Warning';
import { IStudentAddNote, IStudentNoteModel } from '../../../../domain/store/StudentStore';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import TableContainer from '@material-ui/core/TableContainer';
import moment from 'moment/moment';

export const StudentNotes: React.FC = observer(function () {
  const blankNote = {
    id: 0,
    studentId: 0,
    type: '',
    shortContent: '',
    detailedContent: '',
    source: '',
    createdBy: '',
    createdTimestamp: '',
    isCritical: false,
    intakeId: null,
    deletedTimestamp: null,
    deletedBy: null,
  };

  var { studentStore } = useStore();
  const [addNotesModalOpen, setAddNotesModalOpen] = React.useState(false);
  const [newNote, setNewNote] = React.useState<IStudentAddNote>(blankNote);
  const [viewNote, setViewNote] = React.useState<IStudentNoteModel | null>(null);
  const [error, setError] = useState<string | null>(null);
  const student = studentStore.student;

  const handleSaveNewNote = () => {
    newNote.studentId = student!.id;
    newNote.type = 'Note';
    newNote.source = 'BE Core user';

    studentStore
      .addStudentNote(newNote)
      .then((id) => {
        setNewNote(blankNote);
        setAddNotesModalOpen(false);
        setError(null);
      })
      .catch(() => {
        setError('Note failed to save. Try again.');
        // don't clear the note, or close the modal
      });
  };

  const handleNoteClick = (note: IStudentNoteModel) => {
    setViewNote(note);
  };

  if (!student?.id)
    return (
      <div>
        <h3>Notes</h3>
      </div>
    );

  return (
    <div className={styles.notes}>
      {error && <Alert severity="error">{error}</Alert>}

      <h3>
        Notes
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => {
            setAddNotesModalOpen(true);
          }}>
          <Tooltip title={'Add Note'}>
            <AddIcon />
          </Tooltip>
        </IconButton>
      </h3>

      <TableContainer className={styles.tableContainer}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Summary</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {student?.notes.map((note) => (
              <TableRow
                className={styles.noteRow}
                key={note.id}
                onClick={() => {
                  handleNoteClick(note);
                }}>
                <TableCell>
                  {note.type}{' '}
                  {note.isCritical && (
                    <Tooltip title={'Critical'}>
                      <CriticalIcon className={styles.criticalIcon} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{note.shortContent}</TableCell>
                <TableCell>
                  <Tooltip title={moment(note.createdTimestamp).toISOString()}>
                    <span>
                      {moment(note.createdTimestamp).fromNow()} ({note.createdBy})
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {student?.notes.length === 0 && <>No notes recorded for this student.</>}
      </TableContainer>

      <Modal
        open={addNotesModalOpen}
        onClose={() => setAddNotesModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={styles.modal}>
        <Fade in={addNotesModalOpen}>
          <div className={styles.paper}>
            <Box width="5%" ml="auto">
              <IconButton aria-label="close" onClick={() => setAddNotesModalOpen(false)}>
                <Clear />
              </IconButton>
            </Box>
            <h3>Add Note</h3>

            <Box width="100%" ml={'1rem'}>
              <FormControl fullWidth>
                <Grid container justify={'center'}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      id="txtShorContent"
                      label="Title (concise summary or topic of note)"
                      value={newNote.shortContent}
                      onChange={(e: React.ChangeEvent) => {
                        setNewNote({
                          ...newNote,
                          shortContent: (e.target as HTMLInputElement).value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      id="txtDetailedContent"
                      label="Detail"
                      multiline
                      rows={5}
                      value={newNote.detailedContent}
                      onChange={(e: React.ChangeEvent) => {
                        setNewNote({
                          ...newNote,
                          detailedContent: (e.target as HTMLInputElement).value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newNote.isCritical}
                            onChange={() => {
                              setNewNote({ ...newNote, isCritical: !newNote.isCritical });
                            }}
                            name={'chkIsCritical'}
                          />
                        }
                        label={`Critical (increases visibility)`}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
            <Box width={'15%'} ml={'auto'}>
              <Button aria-label="close" color={'primary'} onClick={handleSaveNewNote}>
                <SaveIcon /> Save
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

      <Modal
        open={!!viewNote}
        onClose={() => setViewNote(null)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={styles.modal}>
        <Fade in={!!viewNote}>
          <div className={styles.paper}>
            <Box width="5%" ml="auto">
              <IconButton aria-label="close" onClick={() => setViewNote(null)}>
                <Clear />
              </IconButton>
            </Box>
            <Box width="100%" ml={'1rem'}>
              <FormControl fullWidth>
                <Grid container justify={'center'}>
                  <Grid item xs={12}>
                    <div className={styles.noteTitle}>{viewNote?.shortContent}</div>
                    <div className={styles.noteContent}>{viewNote?.detailedContent}</div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            checked={viewNote?.isCritical}
                            name={'chkIsCritical'}
                          />
                        }
                        label={`Critical (increases visibility)`}
                      />
                    </FormGroup>
                    <br />
                    Type: {viewNote?.type} <br />
                    Source: {viewNote?.source}
                    <br />
                    Created by: {viewNote?.createdBy}
                    <br />
                    {moment(viewNote?.createdTimestamp).toISOString()} -{' '}
                    {moment(viewNote?.createdTimestamp).fromNow()}
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
});
