import React, { FC, useEffect, useState } from 'react';
import styles from './StoryTimeList.module.scss';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { useStore } from '../../hooks';
import {
  CourseType,
  IDiplomaModuleModel,
  IIntakeModel,
  ILectureModel,
} from '../../../domain/store/OnlineCoursesModel';
import { IStudentStoryTimeVideoModel } from '../../../domain/store/StoryTimeModel';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import ViewIcon from '@material-ui/icons/OndemandVideo';
import { Check, Clear } from '@material-ui/icons';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment-timezone';
import { Alert } from '@material-ui/lab';
import produce from 'immer';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

type WeekType = { title: string; id: number };

export const StoryTimeList: FC = () => {
  const { onlineCoursesModel, storyTimeModel } = useStore();
  const [courseType, setCourseType] = useState<'Certificate' | 'Diploma' | undefined>(undefined);
  const [, setHasLoadedAvailableModules] = useState(false);
  const [selectedDiplomaModuleId, setSelectedDiplomaModuleId] = useState<number | undefined>(
    undefined
  );
  const [selectedWeekId, setSelectedWeekId] = useState<number>(-1);
  const [submissions, setSubmissions] = useState<IStudentStoryTimeVideoModel[]>([]);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<number>(-1);
  const [feedback, setFeedback] = useState<string>('');
  const [weeks, setWeeks] = useState<WeekType[]>();
  const [showFeedbackSentSuccess, setShowFeedbackSentSuccess] = useState<boolean>(false);
  const [showUnsubmitted, setShowUnsubmitted] = useState<boolean>(false);
  const [showNeedFeedback, setShowNeedFeedback] = useState<boolean>(false);
  const [tab, setTab] = React.useState(1);
  const [outcome, setOutcome] = React.useState<string>();
  const [, setHasLoadedCertificateIntakes] = useState<boolean>(false);
  const [selectedCertIntakeId, setSelectedCertIntakeId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (courseType === 'Diploma') {
      onlineCoursesModel
        .loadDiplomaModuleSummaries()
        .then(() => setHasLoadedAvailableModules(true));
    }

    if (courseType === 'Certificate') {
      onlineCoursesModel.loadIntakesSummaries().then(() => setHasLoadedCertificateIntakes(true));
    }
  }, [courseType, onlineCoursesModel, setHasLoadedAvailableModules]);

  useEffect(() => {
    setWeeks([]);

    if (courseType === 'Diploma' && selectedDiplomaModuleId) {
      onlineCoursesModel.loadModuleById(selectedDiplomaModuleId).then((m: IDiplomaModuleModel) => {
        setWeeks(m.lectures.map((l: ILectureModel) => ({ title: l.title, id: l.id })));
      });
    }

    if (courseType === 'Certificate' && selectedCertIntakeId) {
      onlineCoursesModel.loadIntakeById(selectedCertIntakeId).then((i: IIntakeModel) => {
        setWeeks(i.lectures.map((l) => ({ title: l.title, id: l.id })));
      });
    }
  }, [selectedDiplomaModuleId, setWeeks, onlineCoursesModel, selectedCertIntakeId, courseType]);

  useEffect(() => {
    if (selectedWeekId === -1) {
      setSubmissions([]);
      return;
    }
    storyTimeModel
      .getStoryTimeSubmissionsForWeek(selectedDiplomaModuleId ?? null, selectedWeekId)
      .then((response: IStudentStoryTimeVideoModel[]) => {
        setSubmissions(response);
        setShowNeedFeedback(false);
        setShowUnsubmitted(false);
      });
  }, [selectedWeekId, setWeeks, onlineCoursesModel, storyTimeModel, selectedDiplomaModuleId]);

  const handleDiplomaModuleChange = (value: number) => {
    setSelectedDiplomaModuleId(value);
    setSelectedWeekId(-1);
  };

  const handleWeekChange = (value: number) => {
    setSelectedWeekId(value);
  };

  const handleFeedbackChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFeedback(event.target.value as string);
  };

  const handleFeedbackSend = () => {
    storyTimeModel.sendFeedback(feedback, selectedVideoId, outcome!).then((result: boolean) => {
      if (result) {
        setShowFeedbackSentSuccess(true);
        setShowVideoModal(false);

        setSubmissions(
          produce(submissions, (draft) => {
            const toUpdate = draft.find((d) => d.storyTimeVideo?.id === selectedVideoId);
            toUpdate!.storyTimeVideo!.messages.push({
              createdBy: 'You',
              createdOn: moment().toISOString(),
              message: feedback,
            });
            toUpdate!.storyTimeVideo!.markedCompleteByTrainerTimestamp =
              outcome === 'complete' ? moment().toISOString() : null;
          })
        );
      } else {
        alert('An error occured while emailing or saving feedback.');
      }
    });
  };

  let filteredSubmissions = submissions!;

  if (showNeedFeedback) {
    filteredSubmissions = filteredSubmissions.filter(
      (s) =>
        !showNeedFeedback ||
        (s.storyTimeVideo?.lastVideoUploadSubmittedOn &&
          (s.storyTimeVideo.messages.length === 0 || //if filter on feedback, then show if has been submitted and no messages exist
            moment(s.storyTimeVideo?.lastVideoUploadSubmittedOn).isAfter(
              moment(s.storyTimeVideo.messages[s.storyTimeVideo.messages.length - 1].createdOn)
            ))) //or if submitted date is after the last message was sent
    );
  } else if (showUnsubmitted) {
    filteredSubmissions = filteredSubmissions.filter(
      (s) =>
        !s.storyTimeVideo?.markedCompleteByTrainerTimestamp &&
        (!s.storyTimeVideo?.lastVideoUploadSubmittedOn ||
          (s.storyTimeVideo.messages.length !== 0 &&
            moment(
              s.storyTimeVideo.messages[s.storyTimeVideo.messages.length - 1].createdOn
            ).isAfter(moment(s.storyTimeVideo?.lastVideoUploadSubmittedOn))))
    );
  }

  return (
    <div className={styles.container}>
      <Snackbar
        open={showFeedbackSentSuccess}
        autoHideDuration={6000}
        onClose={() => {
          setShowFeedbackSentSuccess(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert
          onClose={() => {
            setShowFeedbackSentSuccess(false);
          }}
          severity="success">
          Feedback has been sent!
        </Alert>
      </Snackbar>
      <h1>Story Time</h1>
      <Container maxWidth={'lg'}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="product-select-label">Product</InputLabel>
              <Select
                labelId="product-select-label"
                id="product-select"
                value={courseType}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setCourseType(event.target.value as 'Certificate' | 'Diploma');
                  setSelectedCertIntakeId(undefined);
                  setSelectedDiplomaModuleId(undefined);
                  setWeeks([]);
                  setSubmissions([]);
                  setSelectedWeekId(-1);
                  setSelectedVideoId(-1);
                }}>
                <MenuItem value={0}>Select</MenuItem>
                <MenuItem value={'Certificate'}>Certificate</MenuItem>
                <MenuItem value={'Diploma'}>Diploma</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {courseType === 'Diploma' && (
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="module-select-label">Module</InputLabel>
                <Select
                  labelId="module-select-label"
                  id="module-select"
                  value={selectedDiplomaModuleId}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    handleDiplomaModuleChange(event.target.value as number);
                  }}>
                  <MenuItem value={0}>Select</MenuItem>
                  {onlineCoursesModel.diplomaModuleSummaries.map((module, index) => (
                    <MenuItem key={index} value={module.id}>
                      {module.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {courseType === 'Certificate' && (
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="cert-intake-select-label">Intake</InputLabel>
                <Select
                  labelId="cert-intake-select-label"
                  id="module-select"
                  value={selectedCertIntakeId}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setSelectedCertIntakeId(event.target.value as number);
                    setWeeks([]);
                    setSubmissions([]);
                    setSelectedWeekId(-1);
                    setSelectedVideoId(-1);
                  }}>
                  <MenuItem value={0}>Select</MenuItem>
                  {onlineCoursesModel.intakes
                    .filter((i) => i.courseType === CourseType.Certificate)
                    .map((intake, index) => (
                      <MenuItem key={index} value={intake.id}>
                        {intake.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {(selectedDiplomaModuleId || selectedCertIntakeId) && (
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="week-label">Week</InputLabel>
                <Select
                  labelId="week-select-label"
                  id="module-select"
                  value={selectedWeekId}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    handleWeekChange(event.target.value as number);
                  }}>
                  <MenuItem value={-1}>Select</MenuItem>
                  {weeks &&
                    weeks.map((week, index) => (
                      <MenuItem key={index} value={week.id}>
                        {week.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {selectedWeekId > -1 && (
            <>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showUnsubmitted}
                        onChange={(e) => {
                          setShowUnsubmitted(e.target.checked);
                          if (e.target.checked) setShowNeedFeedback(false);
                        }}
                      />
                    }
                    label="Requires (Re)Submission"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showNeedFeedback}
                        onChange={(e) => {
                          setShowNeedFeedback(e.target.checked);
                          if (e.target.checked) setShowUnsubmitted(false);
                        }}
                      />
                    }
                    label="Requires Review"
                  />
                </FormGroup>
              </Grid>
            </>
          )}
        </Grid>

        {submissions.length > 0 && (
          <div className={styles.resultArea}>
            <span className={styles.summary}>
              Submitted:{' '}
              {
                submissions.filter(
                  (s) => s.storyTimeVideo && s.storyTimeVideo.uploadCompletionDateTime
                ).length
              }{' '}
              | Reviewed:{' '}
              {
                submissions.filter((s) => s.storyTimeVideo && s.storyTimeVideo.messages.length > 0)
                  .length
              }
              &nbsp;| Enrolled: {submissions.length}
            </span>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Last Uploaded</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredSubmissions.map((s, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Link to={`/admin/student/${s.email}`}>
                        {s.firstName} {s.lastName}
                      </Link>
                      &nbsp;
                      <Link
                        className={styles.openInNewIcon}
                        target={'_blank'}
                        to={`/admin/student/${s.email}`}>
                        <OpenInNewIcon />
                      </Link>
                    </TableCell>
                    <TableCell>{s.email}</TableCell>
                    <TableCell>
                      {s.storyTimeVideo?.uploadCompletionDateTime && (
                        <Tooltip
                          title={`Video uploaded ${moment(
                            s.storyTimeVideo.uploadCompletionDateTime
                          ).toISOString()}`}>
                          <span>
                            {moment(s.storyTimeVideo.lastVideoUploadSubmittedOn).fromNow()}
                          </span>
                        </Tooltip>
                      )}
                      {s.storyTimeVideo && !s.storyTimeVideo?.uploadCompletionDateTime && (
                        <Tooltip
                          title={
                            'Attempted: ' + moment(s.storyTimeVideo.createdDateTime).toISOString()
                          }>
                          <span>Incomplete</span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {s.storyTimeVideo && s.storyTimeVideo.uploadCompletionDateTime && (
                        <Tooltip title={'Watch and review'}>
                          <Button
                            onClick={() => {
                              setSelectedVideoId(s.storyTimeVideo!.id);
                              setFeedback('');
                              setShowVideoModal(true);
                              setTab(s.storyTimeVideo!.messages.length > 0 ? 0 : 1);
                              setOutcome(undefined);
                            }}>
                            <ViewIcon color={'primary'} />
                          </Button>
                        </Tooltip>
                      )}
                      {s.storyTimeVideo && s.storyTimeVideo.messages[0]?.createdOn && (
                        <Tooltip
                          title={
                            moment(s.storyTimeVideo?.lastVideoUploadSubmittedOn).isAfter(
                              moment(
                                s.storyTimeVideo.messages[s.storyTimeVideo.messages.length - 1]
                                  .createdOn
                              )
                            )
                              ? 'Requires re-review'
                              : 'Has been reviewed'
                          }>
                          <RateReviewIcon
                            color={
                              moment(s.storyTimeVideo?.lastVideoUploadSubmittedOn).isAfter(
                                moment(
                                  s.storyTimeVideo.messages[s.storyTimeVideo.messages.length - 1]
                                    .createdOn
                                )
                              )
                                ? 'secondary'
                                : 'primary'
                            }
                            className={styles.feedbackIcon}
                          />
                        </Tooltip>
                      )}
                      {s.storyTimeVideo && s.storyTimeVideo.markedCompleteByTrainerTimestamp && (
                        <Tooltip
                          title={`Trainer marked as complete on: ${s.storyTimeVideo.markedCompleteByTrainerTimestamp}`}>
                          <Check color={'primary'} className={styles.completeIcon} />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </Container>
      <Modal
        open={showVideoModal}
        onClose={() => setShowVideoModal(false)}
        className={styles.modal}>
        <div
          style={{
            width: '640px',
            left: 'calc(50% - 320px)',
            height: '480px',
            position: 'relative',
          }}>
          <Box width="5%" ml="auto">
            <IconButton aria-label="close" onClick={() => setShowVideoModal(false)}>
              <Clear style={{ color: 'white', fontSize: '3rem' }} />
            </IconButton>
          </Box>
          {selectedVideoId >= 0 &&
            !!submissions.find((s) => s.storyTimeVideo?.id === selectedVideoId) && (
              <iframe
                style={{ width: '100%', height: '100%' }}
                title="videoFrame"
                id="videoFrame"
                src={
                  submissions.find((s) => s.storyTimeVideo?.id === selectedVideoId)!.storyTimeVideo
                    ?.playerEmbedUrl + '&autoplay=1'
                }
                allowFullScreen={true}
                allow="autoplay"
              />
            )}
          <Paper>
            <Tabs
              centered
              value={tab}
              onChange={(event, newValue) => {
                setTab(newValue);
              }}
              indicatorColor="primary"
              textColor="primary">
              <Tab label={'Previous feedback'} {...a11yProps(1)}>
                Previous feedback
              </Tab>
              {!submissions.find((s) => s.storyTimeVideo?.id === selectedVideoId)?.storyTimeVideo!
                .markedCompleteByTrainerTimestamp && (
                <Tab label={'Send feedback'} {...a11yProps(0)}></Tab>
              )}
            </Tabs>
            <TabPanel value={tab} index={0}>
              <div className={styles.bottom}>
                {submissions
                  .find((s) => s.storyTimeVideo?.id === selectedVideoId)
                  ?.storyTimeVideo!.messages.map((m, i) => (
                    <Card key={i} variant={'outlined'}>
                      <CardContent>
                        <h2>
                          {m.createdBy} - {moment(m.createdOn).fromNow()}
                        </h2>
                        <p>{m.message}</p>
                      </CardContent>
                    </Card>
                  ))}

                {submissions.find((s) => s.storyTimeVideo?.id === selectedVideoId)?.storyTimeVideo!
                  .messages.length === 0 && (
                  <Card variant={'outlined'}>
                    <CardContent>
                      <p>No previous feedback sent.</p>
                    </CardContent>
                  </Card>
                )}
              </div>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <div>
                <TextField
                  title="Feedback"
                  id="Feedback"
                  value={feedback}
                  onChange={handleFeedbackChange}
                  disabled={
                    !!submissions.find((s) => s.storyTimeVideo?.id === selectedVideoId)
                      ?.storyTimeVideo?.markedCompleteByTrainerTimestamp
                  }
                  multiline
                  fullWidth
                  rows={5}
                  placeholder="Type feedback here"
                />

                <RadioGroup
                  row
                  name="outcome"
                  className={styles.radio}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setOutcome(event.target.value);
                  }}>
                  &nbsp;
                  <FormControlLabel
                    value="complete"
                    checked={outcome === 'complete'}
                    control={<Radio />}
                    label="Mark as Complete"
                  />
                  <FormControlLabel
                    value="resubmit"
                    checked={outcome === 'resubmit'}
                    control={<Radio />}
                    label="Mark as Requires Resubmission"
                  />
                  <Button
                    disabled={!outcome}
                    variant="contained"
                    color={'primary'}
                    onClick={handleFeedbackSend}>
                    Email Feedback
                  </Button>
                </RadioGroup>
              </div>
            </TabPanel>
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = observer((props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
