import React from 'react';
import { IStudentEnrolmentGradeBookItemModel } from '../../../domain/store/StudentStore';
import _ from 'lodash';
import styles from './Gradebook.module.scss';
import Done from '@material-ui/icons/Check';
import NotDone from '@material-ui/icons/Clear';
import moment from 'moment/moment';

export interface GradebookProps {
  gradebookItems: IStudentEnrolmentGradeBookItemModel[];
}

export const Gradebook: React.FC<GradebookProps> = ({ gradebookItems }) => {
  return (
    <div>
      {gradebookItems && gradebookItems.length > 0 && (
        <>
          <h3>
            Grade book - total:{' '}
            {Math.round(
              (_.sum(gradebookItems.map((x: any) => x.completionPercentage)) /
                (gradebookItems.length * 100)) *
                100
            )}
            %
          </h3>
          <span className={styles.smaller}>
            Last checked: {moment(gradebookItems[0].created).fromNow()}
          </span>

          {gradebookItems &&
            Object.entries(_.groupBy(gradebookItems, 'itemSection')).map(
              (section: any, i: number) => (
                <div className={styles.gradebookSections} key={i}>
                  <ul>
                    <li>
                      {section[0]} -{' '}
                      {Math.round(
                        (_.sum(section[1].map((x: any) => x.completionPercentage)) /
                          (section[1].length * 100)) *
                          100
                      )}
                      %
                    </li>
                    <li>
                      <ul>
                        {section[1].map((item: any, j: number) => (
                          <li key={j}>
                            {item.itemType === 'WrittenAssessment'
                              ? 'Written Assessment'
                              : item.itemType}{' '}
                            - {item.itemName}
                            {item.completionPercentage === 100 && (
                              <Done className={styles.checkBox} />
                            )}
                            {item.completionPercentage === 0 && (
                              <NotDone className={styles.unCheckBox} />
                            )}
                            {item.completionPercentage > 0 && item.completionPercentage < 100 && (
                              <NotDone className={styles.partialCheckBox} />
                            )}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </div>
              )
            )}
        </>
      )}
    </div>
  );
};
