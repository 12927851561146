import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import styles from './ModuleAddEdit.module.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Checkbox,
  Container,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from '@material-ui/core/Select';
import { TutorialSchedule } from '../../components/TutorialSchedule/TutorialSchedule';
import moment from 'moment-timezone';
import { SnapshotIn } from 'mobx-state-tree';
import {
  Audience,
  IDiplomaModuleModel,
  IGradeBookItemModel,
  ILectureModel,
} from '../../../domain/store/OnlineCoursesModel';
import { observer } from 'mobx-react-lite';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStore } from '../../hooks';
import { TotaraEmbedCodeCopyToClipBoard } from '../../components/TotaraEmbedCodeCopyToClipBoard';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import useDebounce from '../../../hooks/useDebounce';

export const ModuleAddEdit: React.FC = function () {
  const [module, setModule] = useState<SnapshotIn<IDiplomaModuleModel>>({
    id: 0,
    title: '',
    displayName: '',
    timezone: '',
    recordingLink: '',
    joinLiveLink: '',
    joinLiveMeetingId: '',
    joinLiveMeetingPassword: '',
    lectures: [],
    acuityCategory: '',
    elearningAudience: '',
    elearningCourseIds: '',
  });
  const { onlineCoursesModel } = useStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [referenceGradeBookItems, setReferenceGradeBookItems] = useState<IGradeBookItemModel[]>([]);
  const [courseIdsLoadedRefItems, setCourseIdsLoadedRefItems] = useState<string>('');

  const [elearningAudienceList, setElearningAudienceList] = useState<Audience[]>([
    { name: '', id: '' },
  ]);

  useEffect(() => {
    if (!id) return;

    onlineCoursesModel
      .loadModuleById(parseInt(id))
      .then((i: IDiplomaModuleModel) => {
        const tzConvertedModule = {
          ...i,
          lectures: i.lectures?.map((l) => {
            const dtFormatted = moment(l.datetime).utcOffset(0, true).format('YYYY-MM-DDTHH:mm');
            const offset = moment.tz(dtFormatted, i.timezone).utcOffset();
            const adjusted = moment(dtFormatted)
              .utcOffset(offset, false)
              .format('YYYY-MM-DDTHH:mm');

            return {
              ...l,
              datetime: new Date(adjusted),
            };
          }),
        };
        setModule(tzConvertedModule);
      })
      .catch((e) => {
        console.error('Unable to load intake with ID of ' + id, e);
      });
  }, [onlineCoursesModel, id]);

  useEffect(() => {
    onlineCoursesModel
      .loadElearningAudiences(false)
      .then((a: Array<Audience>) => {
        if (a) {
          setElearningAudienceList([{ name: '', id: '' }, ...a]);
        }
      })
      .catch((e) => {
        console.error('Unable to load elearning audience list', e);
      });
  }, [onlineCoursesModel]);

  function stringToNumberArray(input: string): number[] {
    if (!input) {
      return [];
    }
    return input.split(',').reduce((acc: number[], str) => {
      let num: number = Number(str);
      if (!isNaN(num)) {
        acc.push(num);
      }
      return acc;
    }, []);
  }

  useDebounce(() => {
    const moduleElearningCourseIds = module.elearningCourseIds ?? '';

    if (moduleElearningCourseIds === courseIdsLoadedRefItems) {
      // unchanged & already loaded
      return;
    }

    const courseIds = stringToNumberArray(moduleElearningCourseIds);

    if (courseIds.length === 0) {
      setReferenceGradeBookItems([]);
      return;
    }

    onlineCoursesModel.loadReferenceGradeBookItems(courseIds).then((v: IGradeBookItemModel[]) => {
      setCourseIdsLoadedRefItems(moduleElearningCourseIds);

      const primaryGbItems = _.filter(v, (i) => _.includes(courseIds, i.elearningCourseId));

      setReferenceGradeBookItems(primaryGbItems);

      // if it's a new module, default all reference grade book items as applying to this intake.
      if (module.id === 0) {
        setModule((i) => {
          return { ...i, gradeBookItemReferenceIds: v.map((i) => i.id) };
        });
      }
    });
  }, 3000);

  const isValid = () => {
    return module.timezone && module.title;
  };

  const handleSave = () => {
    if (isValid()) {
      const getUtcDateAdjustedToTimezone = (date: Date | number) => {
        // treat it as UTC, even though it's originally in local time
        const dtFormatted = moment(date).utcOffset(0, true).format('YYYY-MM-DDTHH:mm');
        // get the appropriate offset, adjusted for daylight savings
        const offset = moment.tz(dtFormatted, module.timezone).utcOffset();
        // override the offset while keeping the time numbers intact
        return moment(date).utcOffset(offset, true).toDate();
      };

      const tzConvertedModule = {
        ...module,
        lectures: module.lectures?.map((l) => {
          return { ...l, datetime: getUtcDateAdjustedToTimezone(l.datetime) };
        }),
      };

      onlineCoursesModel
        .saveDiplomaModule(tzConvertedModule)
        .then(() => {
          history.push('/admin/diploma/module');
        })
        .catch((e) => {
          alert('An error occured while saving the module: ' + JSON.stringify(e));
        });
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, title: event.target.value as string });
  };

  const handleDisplayNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, displayName: event.target.value as string });
  };

  const onAddLecture = () => {
    const lectures = module.lectures!;

    const lastLecture = lectures.length ? lectures[lectures.length - 1] : null;
    const nextDate: Date | null =
      lastLecture && lastLecture.datetime !== -1
        ? moment(lastLecture.datetime).add(7, 'days').toDate()
        : null;

    lectures.push({
      title: 'Week ' + (lectures.length + 1),
      datetime: nextDate ? nextDate : -1,
      details: '',
      duration: lastLecture ? lastLecture.duration : 90,
      lectureNotesLink: '',
    });
    setModule({ ...module, lectures: lectures });
  };
  const handleTimezoneChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, timezone: event.target.value as string });
  };
  const handleLiveLinkChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, joinLiveLink: event.target.value as string });
  };
  const handleLiveMeetingIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, joinLiveMeetingId: event.target.value as string });
  };
  const handleLiveMeetingPasswordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, joinLiveMeetingPassword: event.target.value as string });
  };
  const handleLectureChange = (index: number, value: SnapshotIn<ILectureModel>) => {
    module.lectures!.splice(index, 1, value);
    setModule({ ...module, lectures: module.lectures });
  };
  const onRemoveLecture = (index: number) => {
    module.lectures!.splice(index, 1);
    setModule({ ...module, lectures: module.lectures });
  };
  const handleAcuityCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, acuityCategory: event.target.value as string });
  };

  const handleElearningCourseIdsChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    setModule({ ...module, elearningCourseIds: event.target.value as string });
  };

  const handleGradeBookItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let checked = event.target.checked;
    let item = event.target.name;

    if (checked && module.gradeBookItemReferenceIds!.indexOf(parseInt(item)) === -1) {
      module.gradeBookItemReferenceIds!.push(parseInt(item));
    } else if (!checked) {
      while (module.gradeBookItemReferenceIds!.indexOf(parseInt(item)) > -1) {
        let index = module.gradeBookItemReferenceIds!.indexOf(parseInt(item));
        if (index > -1) {
          module.gradeBookItemReferenceIds!.splice(index, 1);
        }
      }
    }
    setModule({ ...module, gradeBookItemReferenceIds: module.gradeBookItemReferenceIds });
  };

  return (
    <div className={styles.container}>
      <h1>{module.id ? 'Edit' : 'Create'} Diploma Module</h1>
      <Fab
        disabled={!isValid()}
        color="primary"
        aria-label="save"
        className={styles.floatingButton}
        onClick={handleSave}>
        <SaveIcon />
      </Fab>
      <Link to="/admin/diploma/module" className={styles.floatingButtonTwo}>
        <Fab color="secondary" aria-label="cancel">
          <CancelIcon />
        </Fab>
      </Link>

      <form>
        <h2>Module</h2>
        <Container maxWidth={'md'} className={styles.section}>
          <Grid container justify={'center'} spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="title"
                  name="title"
                  label="Module Name"
                  value={module.title}
                  onChange={handleTitleChange}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="displayName"
                  name="displayName"
                  label="Display Name"
                  value={module.displayName}
                  onChange={handleDisplayNameChange}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="timezone-location-select-label">Timezone</InputLabel>
                <Select
                  labelId="timezone-location-select-label"
                  id="timezone-select"
                  value={module.timezone}
                  onChange={handleTimezoneChange}>
                  <MenuItem value={'Australia/Brisbane'}>Brisbane (AEST)</MenuItem>
                  <MenuItem value={'Europe/London'}>London (GMT/BST)</MenuItem>
                  <MenuItem value={'America/Los_Angeles'}>Los Angeles (PST/PDT)</MenuItem>
                  <MenuItem value={'Australia/Melbourne'}>Melbourne (AEST/AEDT)</MenuItem>
                  <MenuItem value={'Etc/UTC'}>UTC (GMT)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="acuityCategory"
                name="acuityCategory"
                label="Acuity Tutorial Appointment Type Category"
                value={module.acuityCategory}
                onChange={handleAcuityCategoryChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              &nbsp;
            </Grid>
          </Grid>
        </Container>

        <h2>eLearning</h2>
        <Container maxWidth={'md'} className={styles.section}>
          <Grid container justify={'center'} spacing={2}>
            <Grid item xs={12} md={6}>
              <Tooltip title="Set this to the individual Diploma Module audience.">
                <FormControl fullWidth>
                  <Autocomplete
                    value={
                      elearningAudienceList.find((a) => a.id === module.elearningAudience) ??
                      elearningAudienceList[0]
                    }
                    onChange={(event, newValue) => {
                      setModule({ ...module, elearningAudience: newValue?.id as string });
                    }}
                    getOptionLabel={(option) => option.name}
                    options={elearningAudienceList}
                    renderInput={(params) => (
                      <TextField {...params} label="Module Elearning Audience" fullWidth />
                    )}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <Tooltip title="The ID of the course in the LMS. In Totara, Menu -> BE Staff -> Course Admin -> All Courses, find the course and open it. The URL will look like 'https://elearning.breathe.edu.au/course/view.php?id=154/'. In this case the Id is 154. Supports comma separated Ids for multiple courses contributing to the enrolment, i.e. a program in the LMS. Though as this is for a Dip Module, it's likely to be a single ID.">
                <TextField
                  id="primaryCourseId"
                  name="primaryCourseId"
                  label="Course Ids"
                  value={module.elearningCourseIds}
                  onChange={handleElearningCourseIdsChanged}
                  fullWidth
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Container>

        <h2>Lectures (Zoom)</h2>
        <Container maxWidth={'md'} className={styles.section}>
          <Grid container justify={'center'} spacing={2}>
            <Grid item xs={12} md={6}>
              <Tooltip title="Log in to Zoom using the be.courses@breathe.edu.au account and retrieve the relevant Zoom meeting information for this Lecture">
                <TextField
                  id="joinLiveLink"
                  name="joinLiveLink"
                  label="Join Live Link"
                  value={module.joinLiveLink}
                  onChange={handleLiveLinkChange}
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="joinLiveMeetingId"
                name="joinLiveMeetingId"
                label="Meeting ID"
                value={module.joinLiveMeetingId}
                onChange={handleLiveMeetingIdChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="joinLiveMeetingPassword"
                name="joinLiveMeetingPassword"
                label="Meeting Password"
                value={module.joinLiveMeetingPassword}
                onChange={handleLiveMeetingPasswordChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              &nbsp;
            </Grid>
          </Grid>
        </Container>
        <div className={styles.section}>
          <h2>
            Lectures
            <div className={styles.buttonLike} onClick={onAddLecture}>
              <AddBoxIcon color={'primary'} fontSize="large" />
            </div>
          </h2>
          <Container maxWidth={'md'} className={styles.section}>
            {module.lectures
              ? module.lectures.map((lecture, index) => (
                  <Card key={index} className={styles.lectureCard} variant={'outlined'}>
                    <Grid container justify={'center'}>
                      <Lecture
                        onChange={(i, v) => handleLectureChange(i, v)}
                        index={index}
                        value={lecture}
                        onRemove={onRemoveLecture}
                        timezoneLocation={module.timezone}
                        moduleId={module.id}
                      />
                    </Grid>
                  </Card>
                ))
              : ''}
          </Container>
        </div>

        <div className={styles.section}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
              Grade Book (Module)
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.section}>
                <Tooltip title="Select the Grade Book items relevant to this intake for correct %-Complete calculation.">
                  <h2>Grade Book</h2>
                </Tooltip>
                <Container maxWidth={'md'} className={styles.section}>
                  <FormGroup>
                    {Object.entries(_.groupBy(referenceGradeBookItems, 'courseTitle')).map(
                      (course, index: number) => (
                        <div key={index}>
                          <h3>{course[0] ?? 'Title Not Found'}</h3>
                          {course[1].map((gradeBookItem: IGradeBookItemModel, i) => (
                            <FormGroup key={i}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      module.gradeBookItemReferenceIds &&
                                      module.gradeBookItemReferenceIds.indexOf(gradeBookItem.id) >
                                        -1
                                    }
                                    onChange={handleGradeBookItemChange}
                                    name={gradeBookItem.id.toString()}
                                  />
                                }
                                label={`${gradeBookItem.sectionName} ${gradeBookItem.itemName}`}
                              />
                            </FormGroup>
                          ))}
                        </div>
                      )
                    )}

                    {!module.elearningCourseIds && (
                      <p>
                        eLearning Course IDs is not set above. Unable to set gradebook items until
                        it is.
                      </p>
                    )}

                    {module.elearningCourseIds && referenceGradeBookItems.length === 0 && (
                      <p>
                        eLearning Course IDs is set. However no reference items exist for course Id{' '}
                        {module.elearningCourseIds}. Double check course ID and contact DevOps.
                      </p>
                    )}
                  </FormGroup>
                </Container>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className={styles.section}>
          <h2>Tutorials</h2>
          <Container maxWidth={'md'} className={styles.section}>
            <TutorialSchedule
              acuityCategory={module.acuityCategory}
              mode={'admin'}
              userTimezone={moment.tz.guess()}
              location={'AU'}
            />
          </Container>
        </div>
      </form>
    </div>
  );
};

interface LectureProps {
  onChange: (index: number, lecture: SnapshotIn<ILectureModel>) => void;
  value: SnapshotIn<ILectureModel>;
  index: number;
  onRemove: (index: number) => void;
  timezoneLocation: string;
  moduleId: number | undefined;
}

const Lecture: React.FC<LectureProps> = observer((props: LectureProps) => {
  const [showStoryTime, setShowStoryTime] = useState<boolean>(false);

  const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, title: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handleDetailsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, details: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handleDurationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, duration: event.target.value as number };
    props.onChange(props.index, lecture);
  };

  const handleDateTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, datetime: event.target.value as number };
    props.onChange(props.index, lecture);
  };

  const handleLectureNotesLinkChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, lectureNotesLink: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const handleLectureRecordingLinkChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lecture = { ...props.value!, recordingLink: event.target.value as string };
    props.onChange(props.index, lecture);
  };

  const formatDateString = (value: Date | number) => {
    if (value <= 0) return '';
    return moment(value).format('YYYY-MM-DDTHH:mm');
  };

  return (
    <>
      <Grid item container xs={11} md={11} spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            id="week"
            name="week"
            label="Week #"
            onChange={handleTitleChange}
            value={props.value?.title}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type={'datetime-local'}
            id="datetime"
            name="datetime"
            label="Date and time"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={formatDateString(props.value?.datetime)}
            onChange={handleDateTimeChange}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            id="timezone"
            name="timezone"
            label="Timezone"
            fullWidth
            value={moment.tz(props.value?.datetime, props.timezoneLocation).format('z')}
            disabled
          />
        </Grid>
        <Grid item xs={6} md={1}>
          <TextField
            id="duration"
            name="duration"
            label="Duration (min.)"
            type={'number'}
            value={props.value?.duration}
            fullWidth
            onChange={handleDurationChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="details"
            name="details"
            label="Details"
            multiline={true}
            fullWidth
            variant={'outlined'}
            value={props.value?.details}
            onChange={handleDetailsChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="lectureNotesLink"
            name="lectureNotesLink"
            label="Lecture Notes Link"
            fullWidth
            value={props.value?.lectureNotesLink}
            onChange={handleLectureNotesLinkChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="recordingLink"
            name="recordingLink"
            label="Recording Link"
            value={props.value?.recordingLink}
            onChange={handleLectureRecordingLinkChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputLabel id="story-time-label">
            Story Time{' '}
            {!showStoryTime && (
              <Button
                onClick={() => {
                  setShowStoryTime(true);
                }}>
                Show
              </Button>
            )}
          </InputLabel>

          {showStoryTime && !!props.moduleId && !!props.value.id && (
            <>
              {props.moduleId && (
                <ol>
                  <li>
                    Unique link:
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${window.location.origin}/public/student/story-time/${props.moduleId}/${props.value.id}`}>{`${window.location.origin}/public/student/story-time/${props.moduleId}/${props.value.id}`}</a>
                  </li>
                  <li>
                    <span>
                      Totara embed code: <TotaraEmbedCodeCopyToClipBoard /> (update URL NAME
                      placeholder to match Link Name)
                    </span>
                  </li>
                  <li>Add "email" URL variable</li>
                  <li>Appearance - Display - New Window</li>
                </ol>
              )}
            </>
          )}

          {showStoryTime && (props.moduleId === 0 || !props.value.id) && (
            <span>Module must be saved before Story Time link is available.</span>
          )}
        </Grid>
      </Grid>

      <Grid item xs={1} md={1}>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => props.onRemove(props.index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  );
});
