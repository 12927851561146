export const defaultCertModules = [
  { id: 0, intakeLectureId: 0, title: 'O Week', description: 'Orientation Week' },
  {
    id: 0,
    intakeId: 0,
    title: 'Module 1',
    description:
      'Learning goals \nHistory of Pilates \nHow to learn effectively \nMatwork exercises 1-10 \nReformer exercises 1-6 \nCueing',
  },
  {
    id: 0,
    intakeId: 0,
    title: 'Module 2',
    description:
      'Learning goals \nMatwork exercises 11-21 \nReformer exercises 7-18 \nCreative programming \nModifications',
  },
  {
    id: 0,
    intakeId: 0,
    title: 'Module 3',
    description:
      'Learning goals \nMatwork exercises 22-29 \nReformer exercises 19-28 \nPrenatal Pilates \nTeaching beginners',
  },
  {
    id: 0,
    intakeId: 0,
    title: 'Module 4',
    description:
      'Learning goals \nMatwork exercises 30-37 \nReformer exercises 29-38 \nInjuries and pain \n Fearless movement',
  },
  {
    id: 0,
    intakeId: 0,
    title: 'Module 5',
    description: 'Learning goals \nReformer exercises 39-45 \nFind your authentic voice',
  },
];

export const defaultTutorialDescription = [
  'Setup & etiquette',
  'Learning to Learn & Practice Mat 1- 5',
  'Muscle activation & Practice 5 steps to cueing for Mat 1 - 5',
  'Practice Mat 6 - 10 & cueing',
  'Practice Ref 1 - 6',
  'Practice Mat 11-21 & Practical Assessment: Modify Exercises for Clients',
  'Write and teach Practical Assessment: 10-minute Mat Flow',
  'Practice Ref Long Box Series 1 + layers and mods',
  'Practical Assessment: 10-minute Reformer flow',
  'Practice Mat 22-29 & Practical Assessment: Demo Mat 1-21',
  'Practice prenatal mods for repertoire to date',
  'Practice Reformer 19-30 & prenatal & beginner mods',
  'Practical Assessment: Demo Ref 1-18',
  'Practical Assessment: Teach 10 mins Beginner Mat class',
  'Practice Mat 30-37 & Practical Assessment: Teach Clients with Injuries',
  'Practical Assessment: Teach Beginner Reformer class',
  'Practice Reformer 31-45',
  'Practical Assessment: Teach open level Matwork class',
  'Repertoire consolidation & Practical Assessment: Demo Matwork 22-37',
  'Ask the expert & Practical Assessments: Demo Reformer 19-45',
  'Practical Assessment: Teach open level Reformer class',
];

export const defaultLectureDetails = [
  'Orientation',
  'Pilates Through the Ages & Matwork 1 - 5',
  'Cueing for motor learning + Five Steps to Cueing',
  'Shapes of spine & Matwork 6 -10',
  'Reformer 1-6',
  'Matwork 11-21 & How to modify',
  'Creative Programming',
  'Reformer Long Box Series 1',
  'Reformer Long Stretch Series & Layers & Mods',
  'Matwork 22-29 & How to orient & program for beginners',
  'Prenatal & Postpartum',
  'Reformer 19-30',
  'Reformer Repertoire review 1-18',
  'Injuries & Pain',
  'Fearless Movement & Matwork 30-37',
  'Reformer 31-37',
  'Reformer 38-45',
  'Having difficult conversations, Spotlight on - course recap + Programming on the fly and the Good for Everyone Class (Reformer optional)',
  'Reformer Bonus Repertoire',
  'Consolidation (Reformer optional)',
  'Where to from here - How to Build You Brand - How to set up online classes/rates - Coach and retain clients',
];
